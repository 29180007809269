import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService, OrganizationService } from 'src/app/core/services';
import { CustomDateParserFormatter } from 'src/app/core/services/ngb-datepicker-custom.service';
import { BusinessClassification, BusinessIdentity } from 'src/app/models/prove-verification.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-review-business-information',
  templateUrl: './review-business-information.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class ReviewBusinessInformationComponent implements OnInit, OnDestroy {
  @Output() onContinue = new EventEmitter();
  @Output() onEdit = new EventEmitter();

  @Output() onManualEntry = new EventEmitter();

  submitted = false;

  @Input() businessIdentities: BusinessIdentity[];
  @Input() form: FormGroup;

  @Input() selectedBusiness: BusinessIdentity;

  @Input() businessClassifications: BusinessClassification[];

  constructor(private readonly fb: FormBuilder, private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onVerify() {
    this.submitted = true;
    if (this.f.businessClassification.errors || this.f.licenseNumber.errors) {
      return;
    }
    this.onContinue.emit();
  }

  get businessFullAddress() {
    if (this.selectedBusiness) {
      const { address, city, region, postalCode } = this.selectedBusiness.businessAddress;
      return [address, city, `${region} ${postalCode}`].filter(Boolean).join(', ');
    }
    return '-';
  }

  get registrationFullAddress() {
    if (this.selectedBusiness?.registrationFiling?.registrationAddress) {
      const { address, city, region, postalCode } = this.selectedBusiness.registrationFiling.registrationAddress;
      return [address, city, `${region} ${postalCode}`].filter(Boolean).join(', ');
    }
    return '-';
  }

  get f() {
    return this.form.controls;
  }

  onChangeSelectedBusiness(option) {
    const isOther = option === '';
    if (isOther) {
      this.form.patchValue(
        {
          businessName: '',
          taxId: '',
          filingDate: '',
          registrationType: '',
        },
        { emitEvent: true }
      );
      this.onManualEntry.emit();
    }
  }

  getBusinessOptions() {
    return [
      ...this.businessIdentities.map((e) => ({ label: e.businessName, value: e.id })),
      { value: '', label: '+ Other', isOther: true },
    ];
  }

  getClassificationOptions() {
    return this.businessClassifications.map((e) => ({ label: e.name, value: e.id }));
  }
}
