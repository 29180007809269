<app-modal #modal [modalConfig]="modalConfig">
  <h1 class="fw-bold">Are you sure?</h1>
  <div class="mb-2">
    This will move this conversation to your
    {{ channelStatus === ChannelStatus.active || channelStatus === ChannelStatus.completed ? 'archived' : 'active' }}
    chats.
  </div>
  <button class="btn-primary w-100 mt-2" (click)="changeChatStatus()">
    Yes,
    {{ channelStatus === ChannelStatus.active || channelStatus === ChannelStatus.completed ? 'remove' : 'activate' }}
    conversation
  </button>
</app-modal>
