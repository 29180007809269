import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ISellerListing } from 'src/app/models';
import { formatListing } from 'src/app/shared/utils-listing';

export interface GarageListing extends ISellerListing {
  deals: { _id: string }[];
  dealNowId: string;
}

@Injectable({
  providedIn: 'root',
})
export class GarageService {
  private baseUrl = '';

  private readonly listingsSubject = new BehaviorSubject<GarageListing[]>(null);
  get listings() {
    return this.listingsSubject.asObservable().pipe(filter(Boolean)) as Observable<GarageListing[]>;
  }

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.baseUrl = `${this.config.apiUrl}/garage`;
  }

  getListings() {
    return this.http.get<{ data: GarageListing[]; message: string }>(`${this.baseUrl}`).pipe(
      map(({ data }) => data.map((l) => formatListing(l) as GarageListing)),
      tap((data) => {
        return this.listingsSubject.next(data);
      }),
      catchError(() => {
        this.listingsSubject.next(null);
        return of(null);
      }),
    );
  }

  removeListing(listingId) {
    return this.http.put<{ message: string }>(`${this.baseUrl}/remove`, { listingId });
  }
}
