import { Component, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import _ from 'lodash';

export interface ToastButton {
  text: string;
  class?: string;
  handler: () => any;
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast implements OnInit {
  titleButton: ToastButton | null;
  buttons: ToastButton[] | [];

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    this.buttons = _.get(this.toastPackage, 'config.buttons', []);
    this.titleButton = _.get(this.toastPackage, 'config.titleButton', null);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
