<div class="email-input-container" [class.is-invalid]="isInvalid || currentInputInvalid">
  <div class="email-input-wrapper">
    @for (email of emails; track email) {
      <div class="email-pill">
        {{ email }}
        <button type="button" class="remove-email" (click)="removeEmail(email)" [disabled]="disabled">&times;</button>
      </div>
    }
    <input
      type="email"
      class="form-control"
      [(ngModel)]="currentEmail"
      (input)="onEmailInput($event)"
      (keydown)="onEmailKeydown($event)"
      (blur)="onEmailBlur()"
      [placeholder]="placeholder"
      [disabled]="disabled"
    />
  </div>

  @if (isInvalid) {
    <div class="invalid-feedback d-block">
      {{ invalidFeedback }}
    </div>
  }
  @if (currentInputInvalid) {
    <div class="invalid-feedback d-block">
      {{ currentInputError }}
    </div>
  }
</div>
