<app-modal #modal [modalConfig]="modalConfig" (onShown)="onModalShown()">
  <div #camContainer class="body">
    @if (isOpen) {
      <div class="d-flex justify-content-center">
        <webcam
          [switchCamera]="nextCameraObservable"
          [trigger]="triggerObservable"
          [imageQuality]="1"
          [width]="width"
          (initError)="onInitError($event)"
          (imageCapture)="onImageCapture($event)"
        ></webcam>
      </div>
    }
    <div class="mt-3">
      @if (multipleWebcamsAvailable) {
        <button class="btn-primary w-100" (click)="onSwitchCamera()">Switch Camera</button>
      }
      <button class="btn-primary w-100 mt-2" (click)="onTrigger()">Capture</button>
      <button class="btn btn-link w-100 mt-2" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</app-modal>
