<app-modal #modal [modalConfig]="modalConfig">
  <div class="body verification-welcome-modal">
    <div class="flex flex-column">
      <h2 style="font-weight: bold; font-size: 32px">Welcome to DealNow</h2>
      <p>Here’s how to make the most of DealNow</p>
    </div>

    <div class="stepper">
      @for (step of verificationSteps; track step; let i = $index) {
        <div
          class="step completed"
          [ngClass]="{ completed: isStepCompleted(step.id), current: currentStepId === step.id }"
        >
          <img class="step-icon" [src]="step.icon" />
          <div class="step-circle current"></div>
          <div class="step-content">
            <div class="current-step">
              <div class="description">
                <span> {{ step.title }} </span>
                @if (isStepCompleted(step.id)) {
                  <img class="ms-1" src="/assets/verification/welcome/verified.svg" />
                }
              </div>
              @if (!isStepCompleted(step.id)) {
                <div class="subtitle" style="margin-top: 8px">{{ step.description }}</div>
                <button
                  (click)="onClickCta(step.id)"
                  style="margin-top: 12px"
                  class="btn w-100"
                  [ngClass]="{
                    'btn-primary': step.id === currentStepId,
                    'btn-outline-primary': step.id !== currentStepId,
                  }"
                >
                  {{ step.cta }}
                </button>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</app-modal>
