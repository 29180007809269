import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CounterOfferRequest,
  CreateOfferRequest,
  DeclineOfferRequest,
  EditOfferRequest,
  OfferStatuses,
} from 'src/app/marketing-inbox/event-offer/event-offer.model';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { DealsOfferItem, DealsStatuses } from '../../deals/deals.models';
import { IChatListing } from 'src/app/marketing-inbox/marketing-inbox.model';
import { pluck } from 'rxjs/operators';

interface GetOfferListingDetailsResponse {
  listing: IChatListing;
}

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {}

  getOffer(id: string) {
    return this.http.get(`${this.config.apiUrl}/offers/${id}`);
  }

  getOffers() {
    return this.http.get(`${this.config.apiUrl}/offers`);
  }

  getLatestAcceptedOffer(chatId: number) {
    return this.http.get(`${this.config.apiUrl}/offers/latest-accepted/${chatId}`);
  }

  editOffer(data: EditOfferRequest) {
    return this.http.put(`${this.config.apiUrl}/offers/${data.id}`, data);
  }

  createOffer(data: CreateOfferRequest) {
    return this.http.post(`${this.config.apiUrl}/offers/create`, data);
  }

  counterOffer(data: CounterOfferRequest) {
    return this.http.post(`${this.config.apiUrl}/offers/counter`, data);
  }

  declineOffer(data: DeclineOfferRequest) {
    return this.http.post(`${this.config.apiUrl}/offers/decline`, data);
  }

  deleteOffer(data: { offerId: string; userId: string }) {
    return this.http.post(`${this.config.apiUrl}/offers/delete`, { data });
  }

  filterOffers(
    offers: DealsOfferItem[],
    offerStatus: OfferStatuses[],
    dealStatus: DealsStatuses[] = [undefined],
  ): any[] {
    return offers.filter(
      (offer) => offerStatus.some((o) => offer.status === o) && dealStatus.some((d) => offer.deal?.status === d),
    );
  }

  getOfferListingDetails(listingId: string) {
    return this.http
      .get<GetOfferListingDetailsResponse>(`${this.config.apiUrl}/offers/listing/${listingId}`)
      .pipe(pluck('listing'));
  }
}
