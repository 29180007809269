<div>
  <div class="subtitle left mb-2">Review your information</div>
  <div class="description text-start mb-3">Make sure everything is correct and click submit</div>
  <div class="info-read-only" style="margin-top: 12px">
    <div class="d-flex">
      <div class="d-flex flex-column" style="flex: 1">
        <div class="key">First name</div>
        <div class="value">{{ form.value.firstName }}</div>
      </div>
      <div class="d-flex flex-column" style="flex: 1">
        <div class="key">Last name</div>
        <div class="value">{{ form.value.lastName }}</div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Home address</div>
      <div class="value">{{ form.value.address }}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Phone number</div>
      <div class="value">{{ form.value.phoneNumber | mask: '(000) 000-0000' }}&#160;</div>
    </div>
    <!-- @if(form.value.ssn) {
      <div class="d-flex flex-column">
        <div class="key">SSN</div>
        <div class="value">{{form.value.ssn}}</div>
      </div>
    } -->
    <div class="d-flex flex-column">
      <div class="key">Date of birth</div>
      <div class="value">
        {{ form.value.birthDate.month?.toString().padStart(2, '0') }}/{{
          form.value.birthDate.day?.toString().padStart(2, '0')
        }}/{{ form.value.birthDate.year }}
      </div>
    </div>
    <div (click)="onEdit.emit()" class="edit-information" role="button">Edit information</div>
  </div>
  <div class="w-full d-flex flex-column align-items-center mt-2">
    <button (click)="onVerify()" class="btn btn-primary w-100">Continue</button>
  </div>
</div>
