<div
  #parentEl
  class="options-slide d-inline-flex position-relative"
  [ngClass]="{ 'option-slide-light': optionItemLight }"
>
  @for (option of options; track option) {
    <div
      #item
      class="option-item d-flex align-items-center justify-content-center"
      [ngClass]="{ 'option-item-light': optionItemLight }"
      (click)="onOptionClick(option)"
    >
      {{ option.text }}
    </div>
  }
  <div
    #selectedOptionEl
    [ngStyle]="overlayStyle"
    [ngClass]="{ 'option-selected-light': optionItemLight }"
    class="option-selected-item d-flex align-items-center justify-content-center"
  >
    {{ selected.text }}
  </div>
</div>
