import { Component, Input, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

interface DropdownOption {
  label: string;
  value: any;
  isOther?: boolean;
}

@Component({
  selector: 'app-form-dropdown-select',
  standalone: true,
  imports: [NgbDropdownModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDropdownSelectComponent),
      multi: true,
    },
  ],
  template: `
    <div ngbDropdown class="btn-dropdown w-100" (openChange)="onDropdownOpen($event)">
      <button
        type="button"
        [id]="dropdownId"
        [class.is-invalid]="isInvalid"
        class="form-control d-flex align-items-center justify-content-between text-start"
        ngbDropdownToggle
      >
        <span class="ellipsis">{{ selectedLabel }}</span>
        <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
      </button>
      <div ngbDropdownMenu class="dropdown-container w-100" [attr.aria-labelledby]="dropdownId">
        @for (option of options; track option.value) {
        <button
          type="button"
          ngbDropdownItem
          [id]="dropdownId + '-' + option.value"
          class="dropdown px-3"
          (click)="onSelect(option)"
        >
          <div class="ellipsis" [class.other-option]="option.isOther">{{ option.label }}</div>
        </button>
        }
      </div>
    </div>
  `,
  styles: [
    `
      .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .other-option {
        color: var(--primary-blue);
        cursor: pointer;
      }

      .dropdown-icon {
        width: 20px;
        height: 20px;
      }

      :host ::ng-deep .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class FormDropdownSelectComponent implements ControlValueAccessor, OnInit {
  @Input() options: DropdownOption[] = [];
  @Input() placeholder?: string;
  @Input() isInvalid = false;
  @Input() formControlName?: string;
  @Output() selectionChange = new EventEmitter<DropdownOption>();

  selectedOption?: DropdownOption;
  private currentValue: any;
  dropdownId: string = '';
  private isTouched = false;

  ngOnInit() {
    this.dropdownId = this.formControlName
      ? `dropdown-${this.formControlName}`
      : `dropdown-${Math.random().toString(36).substring(2, 9)}`;
  }

  onDropdownOpen(isOpen: boolean) {
    if (isOpen && !this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }

  get selectedLabel(): string {
    return this.selectedOption?.label || this.placeholder || 'Select an option';
  }

  private updateSelectedOption(value: any) {
    this.selectedOption = this.options.find((option) => option.value === value);
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(value: any): void {
    this.currentValue = value;
    this.updateSelectedOption(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if needed
  }

  onSelect(option: DropdownOption): void {
    this.selectedOption = option;
    this.currentValue = option.value;
    this.onChange(option.value);
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
    this.selectionChange.emit(option.value);
  }
}
