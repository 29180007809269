import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChatKittyService, LoadingService, NotificationService, UsersService } from 'src/app/core/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IUser } from 'src/app/models';
import { ConfigMessage } from 'src/app/listing/listing-details/listing-details.component';
import { ChatMessageTypes, IChatBody, unverifiedMessage } from 'src/app/marketing-inbox/marketing-inbox.model';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Component({
  selector: 'app-modal-chat-message',
  templateUrl: './modal-chat-message.component.html',
  styleUrls: ['./modal-chat-message.component.scss'],
})
export class ModalChatMessageComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen: boolean;
  @Input() fromListingDetails = false;
  @Input() verificationDenied = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() config: ConfigMessage;

  @Output() onClose = new EventEmitter<any>();

  modalConfig: ModalConfig;
  messageTypes = ChatMessageTypes;
  user: IUser;
  form: UntypedFormGroup;
  loading = false;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: IAppConfig,
    private readonly usersService: UsersService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly chatKittyService: ChatKittyService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {
    this.form = this.formBuilder.group({
      msg: [null, [Validators.required]],
    });
  }

  get isFormDisabled() {
    return this.loading || !this.form.dirty || !this.form.valid;
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Message Modal',
      trackevent: true,
      hideLogo: false,
      beforeClose: async () => {
        this.config = null;
        this.form.reset();
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    this.usersService.user.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  ngOnDestroy() {
    // AutoUnsubscribe
  }

  async sendMessage() {
    this.loading = true;
    this.loadingService.setLoading(true);
    const { sellerId, listingId, buyerId } = this.config;
    from(this.chatKittyService.channelIdAndPropertiesByName(buyerId, listingId))
      .pipe(
        switchMap(({ id: chatId, properties }) => {
          return from(this.usersService.getUserNotifications(sellerId)).pipe(
            map((notifications) => ({ chatId, notifications, properties })),
          );
        }),
        switchMap(({ chatId, notifications, properties }) => {
          const message: IChatBody = {
            msgType: this.messageTypes.TEXT,
            message: this.form.controls.msg.value,
            notifications,
            useDealNow: this.appConfig.isDealNow,
          };
          if (chatId) {
            this.config.chatId = chatId;
            message.chatId = this.config.chatId;
            if (this.verificationDenied && !properties.messagesApproved) {
              this.notificationService.defaultErrorNotification(
                'Unable to send this message due to verification status.',
              );
              return new Promise<void>((resolve) => {
                resolve();
              });
            }
            return this.chatKittyService.sendMessage(message, this.config.chatId);
          }
          return this.chatKittyService.createPrivateChannel(sellerId, buyerId, listingId).pipe(
            switchMap((chat) => {
              this.config.chatId = chat?.id;
              message.chatId = this.config.chatId;
              return this.chatKittyService.sendMessage(message, this.config.chatId);
            }),
            switchMap(() => {
              if (this.verificationDenied) {
                return this.chatKittyService.sendSystemMessage(unverifiedMessage, this.config.chatId);
              }
              return new Promise<void>((resolve) => {
                resolve();
              });
            }),
          );
        }),
      )
      .subscribe(() => {
        this.router.navigate([`/inbox`, this.config.chatId]);
        this.modalComponent.close();
      });
  }
}
