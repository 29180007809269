<div
  class="img-container"
  [ngStyle]="{
    'width.px': size,
    'height.px': size,
    'background-color': bgColor,
  }"
>
  @if (imgValid) {
    <img [src]="img" alt="listing" (error)="imgValid = false" />
  } @else {
    <div class="shortname" [ngStyle]="{ color: fontColor, 'font-size': fontSize }">{{ shortName | uppercase }}</div>
  }
</div>
