import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChatKittyService, NotificationService } from 'src/app/core/services';
import { ChannelStatus } from 'src/app/marketing-inbox/marketing-inbox.model';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

@Component({
  selector: 'app-modal-archive-chat',
  templateUrl: './modal-archive-chat.component.html',
  styleUrls: ['./modal-archive-chat.component.scss'],
})
export class ModalArchiveChatComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() chatId: number;
  @Input() channelStatus: ChannelStatus;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();

  modalConfig: ModalConfig = {};
  ChannelStatus = ChannelStatus;

  constructor(
    private readonly chatKittyService: ChatKittyService,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Archive Chat Modal',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;
    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  changeChatStatus() {
    const newStatus =
      this.channelStatus === ChannelStatus.active || this.channelStatus === ChannelStatus.completed
        ? ChannelStatus.archived
        : ChannelStatus.active;
    this.chatKittyService.setChannelStatus(this.chatId, newStatus).subscribe((succeeded) => {
      if (succeeded) {
        this.notificationService.notification(
          'success',
          `Successfully ${
            newStatus === ChannelStatus.active || this.channelStatus === ChannelStatus.completed
              ? 'activated'
              : 'archived'
          } chat`,
        );
        window.location.reload();
      } else {
        this.notificationService.defaultErrorNotification(
          `An error has occured ${
            newStatus === ChannelStatus.active || this.channelStatus === ChannelStatus.completed
              ? 'archiving'
              : 'activating'
          } this channel, please try agian.`,
        );
      }
    });
  }
}
