import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { TitleTransferStatuses } from 'src/app/models/title-transfer.model';

@Component({
  selector: 'app-modal-view-title-transfer-details',
  templateUrl: './modal-view-title-transfer-details.component.html',
  styleUrl: './modal-view-title-transfer-details.component.scss',
})
export class ModalViewTitleTransferDetailsComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() titleTransfer;
  @Input() loanInfo;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};
  currentStepIndex: number;
  appName: string = this.config.appName;

  loanPayoffStatusInfo = [
    {
      step: 'loanPayoff',
      pendingStepText: 'Pending loan payoff',
      pendingSubText:
        'Title transfer will begin once the bank has released the lien (Check loan payoff service for updates)',
      finishedStepText: 'Bank received loan payoff',
      complete: false,
    },
    {
      step: 'title',
      pendingStepText: 'Processing title and lien release',
      pendingSubText: `Title and lien release sent to ${
        this.appName
      } for verification. Once verified you will be notified and title will be mailed to you. Depending on state title transfer can take up to 30 days. For questions contact support@${this.appName.toLowerCase()}.com`,
      complete: false,
    },
  ];

  constructor(@Inject(APP_CONFIG) private readonly config: IAppConfig) {
    this.appName = this.config.appName;
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Video viewer modal',
      hideLogo: true,
      headerText: 'Title transfer service',
      headerBg: '#EAF8FF',

      beforeClose: async (isClosedByUser = true) => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit(isClosedByUser);
        return true;
      },
    };

    this.currentStepIndex = this.loanPayoffStatusInfo.findIndex((step) => !step.complete);
    if (this.currentStepIndex === -1) {
      this.currentStepIndex = this.loanPayoffStatusInfo.length;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
      this.determineStatus();
    }
  }

  determineStatus() {
    const loanStatus = this.loanInfo?.checkIssuingInfo?.status;
    const titleTransferStatus = this.titleTransfer?.status;
    switch (loanStatus) {
      case 3:
      case 4:
      case 1:
        this.loanPayoffStatusInfo[0].complete = false;
        break;
      case 5:
        if (
          titleTransferStatus === TitleTransferStatuses.Pending ||
          titleTransferStatus === TitleTransferStatuses.SignatureRequested
        ) {
          this.loanPayoffStatusInfo[0].complete = true;
          this.loanPayoffStatusInfo[1].complete = false;
        } else {
          this.loanPayoffStatusInfo[0].complete = true;
          this.loanPayoffStatusInfo[1].complete = true;
        }
        break;
      default:
        break;
    }
  }
}
