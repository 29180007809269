<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">Add a new card</div>
  <div class="body add-payment-method">
    <form action="" [formGroup]="form">
      <div class="form-group">
        <label class="form-label" for="">Name on card</label>
        <input
          type="text"
          class="form-control"
          formControlName="name"
          [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
        />
        @if (submitted && f.name.errors) {
          <div class="invalid-feedback">
            @if (f.name.errors.required) {
              <div>Name is required</div>
            }
          </div>
        }
      </div>
      <div class="form-group">
        <label class="form-label" for="cardNumber">Card number</label>
        <div id="cardNumber" class="form-control"></div>
        @if (submitted && errors.cardNumber) {
          <div class="invalid-feedback">{{ errors.cardNumber }}</div>
        }
      </div>
      <div class="form-group d-flex" style="gap: 5px">
        <div class="w-100">
          <label for="cardExpiry">Exp date</label>
          <div id="cardExpiry" class="form-control"></div>
          @if (submitted && errors.cardExpiry) {
            <div class="invalid-feedback">{{ errors.cardExpiry }}</div>
          }
        </div>
        <div class="w-100">
          <label class="form-label" for="cardCvc">CVC</label>
          <div id="cardCvc" class="form-control"></div>
          @if (submitted && errors.cardCvc) {
            <div class="invalid-feedback">{{ errors.cardCvc }}</div>
          }
        </div>
      </div>
      <div class="form-group">
        <label for="postal">Zip code</label>
        <input
          id="postal"
          type="text"
          class="form-control"
          formControlName="postalCode"
          [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }"
        />
        @if (submitted && f.postalCode.errors) {
          <div class="invalid-feedback">
            @if (f.postalCode.errors.required) {
              <div>Zip code is required</div>
            }
          </div>
        }
      </div>

      <div class="payment-terms form-group">By clicking save you authorize {{ appName }} to store your card.</div>

      @if (!loading) {
        <button class="btn-primary w-100 mb-3" (click)="onAddCard()">
          <span> Save and use card </span>
        </button>
      }
      @if (loading) {
        <div class="w-100 text-center">
          <div class="spinner-border me-2 text-center" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      }

      <button (click)="closeModal()" [disabled]="loading" class="btn-gray w-100">Cancel</button>
    </form>
  </div>
</app-modal>
