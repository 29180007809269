<app-modal #modal [modalConfig]="modalConfig">
  <div class="bg-blue"></div>
  <div class="header text-center mb-0">Pricing</div>
  <div class="header-sub">The simple way to buy/sell your car privately</div>
  <div class="body mt-3">
    <div class="price-box mt-2">
      <div class="price-header">Listing fee</div>
      <div class="price">$25</div>
      <div class="description mt-3">Per listing, access the best tools to sell your car privately.</div>

      <hr />

      <div class="includes">Includes:</div>
      <ul class="list-box mt-2">
        @for (item of sellerBenefits; track item) {
          <li class="list-item ms-3">{{ item }}</li>
        }
      </ul>
      <button class="btn-primary w-100 mb-2" (click)="onSellNowClick()">Sell a vehicle</button>
    </div>

    <div class="price-box position-relative mt-3">
      <div #closingHeader class="price-banner align-items-center d-flex justify-content-center position-absolute w-100">
        Only pay if your car sells
      </div>
      <div class="price-header mt-3">Closing fee</div>
      <div class="price">$150</div>
      <div class="description mt-3">The safe and simple way to close the sale on your own.</div>

      <hr />

      <div class="includes">Includes:</div>
      <ul class="list-box mt-2">
        @for (item of buyerBenefits; track item) {
          <li class="list-item ms-3">{{ item }}</li>
        }
      </ul>

      <div class="skip-text">
        <span>Skip the listing and do the </span>
        <a class="btn-link blue" (click)="onDealNowClick()">DealNow</a>
        <span class="blue">?</span>
      </div>
    </div>

    <div class="disclaimer d-flex justify-content-center mt-4 text-center">
      *Disclaimer: Account services are provided by US Alliance Financial, a licensed and insured banking institution.
      {{ appName }} does not facilitate or handle any funds throughout the transaction. All funds in custody of US
      Alliance. Buyer financing may require additional fees.
    </div>
  </div>

  <div class="footer position-relative">
    <div class="question-box mt-3">
      <div class="question-text">Already have a buyer or seller? Skip the listing and do a DealNow.</div>

      <div class="d-flex justify-content-center">
        <button class="btn-checkout btn-outline-primary mt-4" (click)="onDealNowClick()">Check out DealNow</button>
      </div>
    </div>
  </div>
</app-modal>
