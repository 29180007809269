import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

@Component({
  selector: 'app-widgets-phone-edit',
  templateUrl: './modal-edit.component.html',
  styleUrls: ['./modal-edit.component.scss'],
})
export class ModalEditComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() skipButton = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() title: string;
  @Input() saveBtnText = 'Update';

  @Output() onClose = new EventEmitter<any>();
  @Output() onSkip = new EventEmitter<boolean>();
  @Output() onVerify = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};
  phone = new UntypedFormControl('', [Validators.required]);
  user: any;
  user$: Subscription;
  loading = false;
  showPhoneVerifyModal = false;
  update$: Subscription;

  get isBtnDisabled() {
    return !this.phone.dirty || !this.phone.valid || this.loading;
  }

  constructor(
    private readonly usersService: UsersService,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Edit Phone Modal',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    this.user$ = this.usersService.user.subscribe((user) => {
      this.user = user;
      this.phone.setValue(user.userDetails.phoneNumber);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.phone.setValue(this.user?.userDetails?.phoneNumber);
      this.modalComponent.open();
    }
  }

  skip() {
    this.onSkip.emit(true);
    this.modalComponent.close();
  }

  resetModal() {
    this.phone.reset();
    this.loading = false;
  }

  update() {
    this.loading = true;
    const params = { phone: this.phone.value };
    this.update$ = this.usersService
      .updatePhone(params)
      .pipe(
        switchMap(() => this.usersService.getCurrentProfile()),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.modalComponent.close();
          this.onSuccess.emit(true);
          this.notificationService.notification('success', 'Please check your phone for the verification code.');
          this.showPhoneVerifyModal = true;
          this.resetModal();
        },
      });
  }

  onVerified() {
    this.onVerify.emit(true);
  }
}
