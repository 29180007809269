<div class="payment-method-selector">
  <div class="d-flex flex-column">
    @for (method of paymentMethods; track method; let i = $index) {
      <div
        class="radio-button form-check d-flex align-items-center py-2 payment-method-option"
        [ngClass]="{ first: i === 0 }"
      >
        <input
          [name]="name"
          class="form-check-input"
          type="radio"
          [value]="method.id"
          (change)="onChange(method.id)"
          [id]="method.id"
          [checked]="value === method.id"
        />
        <label class="form-check-label form-label mb-0 w-100" [for]="method.id">
          <div class="d-flex">
            <div class="me-3">
              <img alt="" src="assets/payment-methods/{{ method.brand }}.svg" />
            </div>
            <div class="flex-fill">
              <div>
                <span class="card-brand"> {{ getBrandDisplayName(method.brand) }} </span>
                <span> **** {{ method.last4 }}</span>
              </div>
              <div>{{ formatNameDisplay(method) }}</div>
            </div>
          </div>
        </label>
      </div>
    }
    <div (click)="onAddNewCard()" class="payment-method-option d-flex py-2 align-items-center">
      <button class="btn-icon me-3">
        <img alt="" src="/assets/icons/add.svg" />
      </button>
      <div class="fw-bold w-100">Add another card</div>
    </div>
  </div>
</div>

<app-account-add-payment-method
  (onSuccess)="onAddPaymentMethodSuccess($event)"
  [(isOpen)]="isAddNewCardVisible"
></app-account-add-payment-method>
