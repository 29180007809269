<div class="support-page">
  <a class="d-flex header align-items-center" routerLink="/help-center">
    <img src="assets/right-arrow.svg" alt="back" />
    <div class="mt-1">Back</div>
  </a>
  <div class="title d-flex mt-3">
    <img src="assets/phone-icon-black.svg" alt="phone" />
    <div class="ms-2">Call support</div>
  </div>
  <div class="d-flex align-items-center section mt-4">
    <div class="section-head">Phone number:&nbsp;</div>
    <a href="tel:+8012516046" target="_blank">801-251-6046</a>
  </div>
  <div>
    <div class="section-head mt-4">Phone Support hours:</div>
    <div>Monday thru Friday: 9AM—6PM (Time zone EST)</div>
    <div>Saturday & Sunday: Closed</div>
  </div>
  <div class="section mt-4">
    <div class="section-head">Email us anytime:</div>
    @if (config.isDealNow) {
      <a class="terms" href="mailto: support@dealnow.com">support&#64;dealnow.com</a>
    } @else {
      <a class="terms" href="mailto: support@privateauto.com">support&#64;privateauto.com</a>
    }
  </div>
</div>
