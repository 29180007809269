import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { switchMap } from 'rxjs';
import { OnboardingService, UsersService } from 'src/app/core/services';
import { urlValidator } from 'src/app/core/validators/urls';
import { IUser } from 'src/app/models';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-business-information-modal',
  templateUrl: './business-information-modal.component.html',
  styleUrls: ['./business-information-modal.component.scss'],
})
export class BusinessInformationModal implements OnInit, OnDestroy {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  modalConfig: ModalConfig = {};

  @Input() user: IUser;
  submitted = false;
  form: FormGroup;

  chipOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly fb: FormBuilder,
    private readonly userService: UsersService,
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      website: [null, [Validators.required, urlValidator()]],
      jobTitle: [null, [Validators.required]],
      isOwner: null,
    });
  }

  ngOnInit(): void {
    this.modalConfig = {
      modalDialogClass: 'business-information-modal',
      name: 'Business Information Modal',
      size: 'xl',
      hideHeader: true,
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onboardingService.setBusinessInformationModalIsVisible(false);
        return true;
      },
    };
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
      this.form.patchValue({
        isOwner: false,
      });
    }
  }

  closeModal() {
    this.modalComponent.close();
  }

  get f() {
    return this.form.controls;
  }

  onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.onboardingService
      .initializeBusinessInformation(this.form.value)
      .pipe(switchMap(() => this.userService.getCurrentProfile()))
      .subscribe(() => {
        this.closeModal();
        this.onboardingService.setWelcomeBusinessVerificationModalIsVisible(true);
      });
  }
}
