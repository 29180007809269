import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UsersService } from 'src/app/core/services/users.service';
import { IUser } from 'src/app/models/user.model';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

const DEFAULT_TITLE = 'Verify your phone';

@Component({
  selector: 'app-widgets-phone-verify',
  templateUrl: './modal-verify.component.html',
  styleUrls: ['./modal-verify.component.scss'],
})
export class ModalVerifyComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;

  @ViewChild('char1') char1;
  @ViewChild('char2') char2;
  @ViewChild('char3') char3;
  @ViewChild('char4') char4;

  @Input() title: string;
  @Input() enableEditPhone = true;

  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();
  @Output() onVerify = new EventEmitter<any>();
  @Output() onChangePhone = new EventEmitter<any>();

  modalConfig: ModalConfig = {};
  loading = false;
  showMobileEdit: boolean;
  phone = '';
  form: UntypedFormGroup;
  user: IUser;
  isUserVerified = false;
  user$: Subscription;
  update$: Subscription;
  resend$: Subscription;

  constructor(
    private readonly usersService: UsersService,
    private readonly notificationService: NotificationService,
    private readonly formBuilder: UntypedFormBuilder,

    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.form = this.formBuilder.group({
      char1: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      char2: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      char3: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      char4: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
    });
  }

  ngOnInit(): void {
    this.title = this.title || DEFAULT_TITLE;

    this.modalConfig = {
      name: 'Verify Phone Modal',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    this.usersService.user.subscribe((user) => {
      this.user = user;
      this.phone = user.userDetails.phoneNumber;
      this.isUserVerified = user.verification.phone;
      this.isOpen = this.isUserVerified;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, title } = changes;

    if (isOpen?.currentValue) {
      this.loading = false;
      this.phone = this.user?.userDetails?.phoneNumber;
      this.form.reset();
      this.modalComponent.open();
      this.focusField('char1');
    }

    if (title?.currentValue) {
      this.title = title.currentValue || DEFAULT_TITLE;
    }
  }

  resend() {
    this.loading = true;
    this.resend$ = this.usersService.resendPhoneCode().subscribe({
      next: (result) => {
        this.notificationService.notification('success', result.message);
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
      },
    });
  }

  verify() {
    this.loading = true;
    const params = {
      code: Object.values(this.form.controls)
        .map((c) => c.value)
        .join(''),
    };

    this.update$ = this.usersService
      .verifyPhone(params)
      .pipe(
        switchMap((result) => this.usersService.getCurrentProfile().pipe(map(() => result))),
        finalize(() => {
          this.loading = false;
          this.form.reset();
        }),
      )
      .subscribe({
        next: (result) => {
          this.onVerify.emit(result);
          this.modalComponent.close();
        },
      });
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const code = pastedText.split('');
    if (code?.length >= 4) {
      const [char1, char2, char3, char4] = pastedText.split('');
      this.form.patchValue({
        char1,
        char2,
        char3,
        char4,
      });
      this.focusField('char4', 200);
    }
  }

  async inputText(event, currentSelector, nextSelector = null, previousSelector = null) {
    if (event.target.value.length === 4) {
      const text = event.target.value;
      const code = text.split('');
      if (code?.length >= 4) {
        const [char1, char2, char3, char4] = text.split('');
        this.form.patchValue({
          char1,
          char2,
          char3,
          char4,
        });
        this.focusField('char4', 200);
      }
    }

    if (event.target.value.length > 1) {
      this.form.get('char1').setValue(event.target.value[0]);
    }

    const isCurrentFieldValid = this.form.get(currentSelector).valid;
    if (isCurrentFieldValid && nextSelector) {
      this.focusField(nextSelector);
    } else if (!this.form.controls?.[currentSelector]?.value && previousSelector) {
      // if empty
      this.focusField(previousSelector);
    }
  }

  changePhone() {
    this.onChangePhone.emit();
  }

  focusField(el: string, delay = 0) {
    setTimeout(() => {
      const input = this[el]?.nativeElement;
      const end = input.value?.length || 0;
      input.setSelectionRange(end, end);
      input.focus();
    }, delay);
  }
}
