<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">Edit vehicle address</div>

  <div>Your vehicle address</div>
  @if (inspectionAddress?.formattedAddress) {
    <form [formGroup]="form">
      <app-places-autocomplete-input
        placeholder="Enter in the location"
        [(inputLocation)]="inspectionAddress"
        elementId="inspectionAddress"
        formControlName="inspectionAddress"
      ></app-places-autocomplete-input>
    </form>
  }

  <button class="btn btn-primary w-100 mt-4" (click)="updateAddress()">Save</button>
</app-modal>
