import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { PosthogService } from '../services/posthog.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  constructor(
    private router: Router,
    private readonly posthogService: PosthogService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const requiredFeature = route.data['requiredFeature'] as string;

    if (!requiredFeature) {
      return true;
    }

    if (this.posthogService.posthog.isFeatureEnabled(requiredFeature)) {
      return true;
    }

    this.router.navigate(['/account']);
    return false;
  }
}
