import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { Hub } from 'aws-amplify';
import { NotificationService } from '../services';

/**
 * This is used to logout the user, when the server responds with an unathorized status code.
 * Especially when the session token expires.
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly http: HttpClient,
  ) {}

  /**
   * Intercepter intercepts the responses, and then process based on the recieved status code
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof ErrorInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipError = request.headers.has('X-Ignore-Error');
    if (skipError) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      // If an endpoint returns a response of 429 we will retry request upto 10 times.
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((error, index) => {
            if (error instanceof HttpErrorResponse && error.status === 429) {
              if (index === 2) {
                this.notificationService.notification(
                  'info',
                  'Please wait while we are still processing your request.',
                );
              }

              if (index < 20) {
                return timer(5000); // Retry after request after 5 seconds
              }

              this.notificationService.notification(
                'error',
                'We cannot proceed with your request, please try again later or contact support.',
              );
            }
            return throwError(error);
          }),
        ),
      ),

      catchError((err, caught) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          Hub.dispatch('auth', { event: 'signOut' });
        }

        const { code, message } = err.error;
        const msg = message || err.statusText;

        if (code === 'INTEGRATION_API' || code === 'SERVER_ERROR') {
          const msgOpt = {
            title: msg,
            buttons: [
              {
                text: 'Try again',
                handler: () => {
                  this.http.request(request).subscribe();
                },
              },
            ],
          };
          this.notificationService.notification('error', msgOpt);
        } else if (
          code === 'CUSTOM_MESSAGE_WARNING' ||
          code === 'CUSTOM_MESSAGE' ||
          code === 'InternalServerError' ||
          code === 'BadRequest'
        ) {
          this.notificationService.notification('warning', msg);
        } else {
          this.notificationService.notification('error', 'An error has occured, please contact support.');
        }
        throw new Error(`${msg}: ${request.url}`);
      }),
    );
  }
}
