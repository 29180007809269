/* eslint-disable no-underscore-dangle */
import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable, Subscribable } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ISellerListing } from 'src/app/models';

export interface CreateSellerListingPayload extends Partial<ISellerListing> {
  disableUserId?: boolean;
}

export interface LightListingValues
  extends Pick<
    ISellerListing,
    '_id' | 'RegistrationYear' | 'CarMake' | 'CarModel' | 'Trim' | 'uploadImages' | 'vinNumber' | 'slug'
  > {}

interface GetListingLightResponse {
  listing: LightListingValues;
  success: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class BuyerService {
  @Output() onViewTask: EventEmitter<null> = new EventEmitter();

  constructor(
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {}

  public offerStatus(data) {
    return this.http.put(`${this.config.apiUrl}/offers/status`, data);
  }

  // update vehicles listings in seller profile
  public updateSellerListing(data: ISellerListing) {
    return this.http.put(`${this.config.apiUrl}/seller-listings/${data._id}`, data) as Observable<{
      data: ISellerListing;
      message: string;
    }>;
  }

  public createSellerListing(data: CreateSellerListingPayload) {
    return this.http.post(`${this.config.apiUrl}/seller-listings/`, data) as Observable<{
      data: ISellerListing;
      message: string;
    }>;
  }

  public createOrUpdateSellerListing(data) {
    return data._id ? this.updateSellerListing(data) : this.createSellerListing(data);
  }

  public confirmPayment(id, paymentId): Subscribable<{ data: any; message: any }> {
    return this.http.put(`${this.config.apiUrl}/seller-listings/${id}/confirm`, { paymentId });
  }

  public deactivateListing(id) {
    return this.http.put(`${this.config.apiUrl}/seller-listings/${id}/deactivate`, {});
  }

  public renew(id) {
    return this.http.put(`${this.config.apiUrl}/seller-listings/${id}/renew`, {});
  }

  // counterOffer
  public counterOffer(data) {
    return this.http.post(`${this.config.apiUrl}/seller-listings/counter-offer`, data);
  }

  // This method not quite ready yet
  public reportUser(data) {
    return this.http.post(`${this.config.apiUrl}/marketing/report-user`, data);
  }

  public listAutomobileMakes() {
    return this.http.get(`${this.config.apiUrl}/seller-listings/automobile-makes`);
  }

  public carDetailsFilter() {
    return this.http.get(`${this.config.apiUrl}/seller-listings/car-details-filter`);
  }

  public retrieveSellerListing(querystring) {
    return this.http.get(`${this.config.apiUrl}/seller-listings?${querystring}`);
  }

  public retrieveMarketInsightsListings(querystring) {
    return this.http.get(`${this.config.apiUrl}/seller-listings/market-insights?${querystring}`);
  }

  public getListingLight(listingId) {
    return this.http.get<GetListingLightResponse>(`${this.config.apiUrl}/seller-listings/${listingId}/light`);
  }

  public getMakeDetails(make) {
    return this.http.get(`${this.config.apiUrl}/vehicles/make/${encodeURI(make)}`);
  }
}
