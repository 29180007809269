<app-modal #modal [modalConfig]="modalConfig">
  <div class="header mb-4">{{ title }}</div>
  <div class="body">
    <div class="form-group">
      <input
        ngbAutofocus
        prefix="+1"
        mask=" (000) 000-0000"
        class="form-control"
        type="text"
        placeholder="mobile number"
        [formControl]="phone"
        [ngClass]="{ 'is-invalid': phone.dirty && phone.errors }"
      />
    </div>
    <button class="btn btn-primary my-2" [disabled]="isBtnDisabled" (click)="update()">{{ saveBtnText }}</button>
    @if (skipButton) {
      <button class="btn btn-light my-2" (click)="skip()">I'll do this later</button>
    }
  </div>
</app-modal>
