import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import type { PostHog } from 'posthog-js';
import { AppModule } from './app/app.module';
import { APP_CONFIG, IAppConfig } from './app/config/config';
import { Utils } from './app/shared/utils';
import { getTheme, injectThemeIntoRoot } from './app/shared/theme';

declare const posthog: PostHog;
fetch(`/api/appconfig`, {
  headers: {
    'X-PA': Utils.getParamterHash('/api/appconfig', {}),
    'x-client': window.navigator.userAgent,
    'x-pa-hostname': window.location.hostname.replace('app.', ''),
  },
})
  .then((res) => res.json())
  .then(async (appConfig: IAppConfig) => {
    // const appDomain = window.location.hostname === 'localhost' ? 'localhost' : window.location.hostname.slice(4);
    Amplify.configure({
      aws_project_region: appConfig.amplifyRegion,
      aws_cognito_identity_pool_id: appConfig.amplifyIdentityPoolId,
      aws_cognito_region: appConfig.amplifyRegion,
      aws_user_pools_id: appConfig.amplifyUserPoolId,
      aws_user_pools_web_client_id: appConfig.amplifyWebClientId,
      Auth: {
        identityPoolId: appConfig.amplifyIdentityPoolId,
        region: appConfig.amplifyRegion,
        identityPoolRegion: appConfig.amplifyRegion,
        userPoolId: appConfig.amplifyUserPoolId,
        userPoolWebClientId: appConfig.amplifyWebClientId,
      },
    });
    if (appConfig.production) {
      enableProdMode();
    }
    document.title = appConfig.appName;
    posthog.init(appConfig.posthogKey, {
      api_host: '/ingest',
      ui_host: 'https://us.posthog.com',
    });

    // wait posthog feature flags to load before bootstraping the app
    await new Promise((resolve) => {
      posthog.onFeatureFlags(resolve);
    });
    const theme = await getTheme();
    injectThemeIntoRoot(theme);
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: appConfig }]).bootstrapModule(AppModule);
  })
  .catch((err) => {
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: { failed: true } }]).bootstrapModule(AppModule);
  });
