import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ISellerListing, TitleCheckOrderModel, TitleCheckQuoteModel } from 'src/app/models';

const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({
  providedIn: 'root',
})
export class TitleCheckService {
  selectedVehicle = new BehaviorSubject<ISellerListing>(undefined);
  modalCurrentStep = new BehaviorSubject<number>(0);
  selectVehicleOnly = new BehaviorSubject<boolean>(false);
  orderModalIsVisible = new BehaviorSubject<boolean>(false);
  quote = new BehaviorSubject<TitleCheckQuoteModel>(undefined);
  orderResult = new BehaviorSubject<TitleCheckOrderModel>(undefined);
  private ordersSubject = new BehaviorSubject<TitleCheckOrderModel[]>([]);
  public orders$ = this.ordersSubject.asObservable();

  setOrderModalVisiblity(val: boolean) {
    this.orderModalIsVisible.next(val);
  }

  setOrderResult(val: TitleCheckOrderModel) {
    this.orderResult.next(val);
  }

  setSelectVehicleOnly(val: boolean) {
    this.selectVehicleOnly.next(val);
  }

  setSelectedVehicle(val: ISellerListing) {
    this.selectedVehicle.next(val);
  }

  setModalCurrentStep(val: number) {
    this.modalCurrentStep.next(val);
  }

  setTitleCheckQuote(quote: TitleCheckQuoteModel) {
    this.quote.next(quote);
  }

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {}

  getQuoteForListing(listingId: string, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();

    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.get<TitleCheckQuoteModel>(`${this.config.apiUrl}/addons/title-check/quote`, {
      params: { listingId },
      headers,
    });
  }

  getTitleCheckOrderById(id: string) {
    return this.http.get(`${this.config.apiUrl}/addons/shipping/orders/${id}`);
  }

  createOrder(data) {
    return this.http.post(`${this.config.apiUrl}/addons/shipping/orders`, data);
  }

  getTitleCheckOrder(params: { listingId?: string; dealId?: string }) {
    return this.http.get<{ order: TitleCheckOrderModel }>(`${this.config.apiUrl}/addons/title-check/retrieve`, {
      params,
      headers: {
        'X-No-Loader': '1',
      },
    });
  }

  getTitleCheckOrders(vehicleType?: string) {
    return this.http
      .get<{ data: TitleCheckOrderModel[] }>(`${this.config.apiUrl}/addons/title-check/orders`, {
        params: { vehicleType },
      })
      .subscribe((response) => {
        this.ordersSubject.next(response.data);
      });
  }

  getDealOptions(vehicleType?: string) {
    return this.http.get(`${this.config.apiUrl}/addons/title-check/options/deals`, {
      params: { vehicleType },
    });
  }
  getFavoriteOptions(vehicleType?: string) {
    return this.http.get(`${this.config.apiUrl}/addons/title-check/options/favorites`, {
      params: { vehicleType },
    });
  }

  getUserListingsOptions(vehicleType?: string) {
    return this.http.get(`${this.config.apiUrl}/addons/title-check/options/listings`, {
      params: { vehicleType },
    });
  }
}
