/* eslint-disable no-underscore-dangle */
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ITestDrive, TestDriveCreateRequest, TestDriveUpdateRequest } from '../../models/test-drive.model'; // eslint-disable-line
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestDrivesService {
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {}

  public retriveTestDriversByClient(userId): Observable<ITestDrive[]> {
    return this.http
      .get<{ data: ITestDrive[] }>(`${this.config.apiUrl}/test-drives/user/${userId}`)
      .pipe(map(({ data }) => data));
  }

  public getTestDriveById(testDriveId) {
    return this.http.get(`${this.config.apiUrl}/test-drives/${testDriveId}`);
  }

  public update(testDrive: TestDriveUpdateRequest) {
    return this.http.put(`${this.config.apiUrl}/test-drives/${testDrive._id}`, testDrive);
  }

  public create(testDrive: TestDriveCreateRequest) {
    return this.http.post(`${this.config.apiUrl}/test-drives`, testDrive);
  }

  public getTestDrivesByChatId(chatId: number) {
    return this.http
      .get<{ data: ITestDrive[] }>(`${this.config.apiUrl}/test-drives/chat/${chatId}`)
      .pipe(map(({ data }) => data));
  }
}
