<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">{{ headerText }}</div>
  @if (listing) {
    <div class="body mt-3">
      @if (shareStyle !== shareStyles.standard) {
        <div class="py-2">
          <div class="copy-past-seller pb-2">
            Copy/Paste to send to {{ shareStyle === shareStyles.dealNowSeller ? 'buyer' : 'seller' }}
          </div>
          <div class="d-flex flex-column justify-content-center external-listing-message text-left">
            <div class="m-2">{{ externalMessageText }}</div>
            <div class="btn btn-primary text-center m-3" (click)="copyExternalText()">Copy message template</div>
          </div>
        </div>
      }
      <div class="input-group mt-4 position-relative">
        <input
          type="text"
          class="form-control font-weight-bold overflow-auto custom-input"
          [value]="dealNowUrl"
          readonly
          id="copyInput"
        />
        @if (!isCopied) {
          <div class="white-spacing position-absolute inset-button me-1">
            <button class="btn-primary d-flex justify-content-center align-items-center" (click)="onCopyLinkClick()">
              Copy
            </button>
          </div>
        }
        @if (isCopied) {
          <div class="white-spacing position-absolute inset-button ps-1">
            <button class="btn-gray d-flex justify-content-center align-items-center" (click)="onCopyLinkClick()">
              Link Copied
            </button>
          </div>
        }
      </div>

      @if (isMobileView) {
        <div class="d-flex mt-3">
          <button class="btn-gray d-flex justify-content-center align-items-center w-50" (click)="onMessagesClick()">
            <img src="assets/message.svg" alt="" />
            <span class="ms-2">Messages</span>
          </button>
          <button class="btn-gray d-flex justify-content-center align-items-center w-50 ms-3" (click)="onEmailClick()">
            <img src="assets/email-2.svg" alt="" />
            <span class="ms-2">Email</span>
          </button>
        </div>
      }
      @if (!isMobileView) {
        <div class="d-flex mt-3">
          <button class="btn-gray d-flex justify-content-center align-items-center w-100" (click)="onEmailClick()">
            <img src="assets/email-2.svg" alt="" />
            <span class="ms-2">Email</span>
          </button>
        </div>
      }
    </div>
  }
</app-modal>
