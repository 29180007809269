<div class="stepper-circle w-100 d-flex justify-content-between align-items-center">
  @for (item of config?.items; track item; let i = $index) {
    <!-- line -->
    @if (i > 0) {
      <div
        class="line w-100"
        [ngStyle]="{
          'background-color': item.isCompleted ? config.color : '#DDE4E9',
          'border-color': item.isCompleted ? config.color : '#DDE4E9',
        }"
      ></div>
    }

    <div class="item position-relative">
      <div
        class="circle flex-shrink-0 flex-grow-0"
        [ngStyle]="{
          width: config.size,
          'background-color': item.isCompleted ? config.color : '#DDE4E9',
          'border-color': item.isCompleted ? config.color : '#DDE4E9',
        }"
      ></div>

      <!-- label -->
      @if (item.label) {
        <div class="circle-label" [ngStyle]="{ bottom: config.labelMarginTop }">{{ item.label }}</div>
      }
    </div>
  }
</div>
