import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

@Component({
  selector: 'app-verification-denied-modal',
  templateUrl: './verification-denied-modal.component.html',
  styleUrls: ['./verification-denied-modal.component.scss'],
})
export class VerificationDeniedModalComponent implements OnInit {
  @Input() verificationsDenied = false;
  @Input() fundsDenied = false;
  modalConfig: ModalConfig = {};
  appName: string;

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.appName = this.config.appName;
  }

  ngOnInit(): void {}
}
