import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { MapLocationModel } from '../models';
import { Observable, throwError } from 'rxjs';
import { DOCUMENT_FILE_SIZE, MultipleFileUploadResult, UploadService } from '../core/services';

const secret = 'whQ5V2C2SEyzhtWYM4j6dA';

export enum DocumentViewerFileTypes {
  Image = 'image',
  Pdf = 'pdf',
  PlatoformsInvite = 'platoformsInvite',
  PlatoformsPdf = 'platoformsPdf',
}

/* eslint-disable no-underscore-dangle */
export class Utils {
  static addressConvert(addressObj) {
    if (!Object.keys(addressObj).length) {
      return '';
    }
    const a = {
      location: addressObj.location ? `${addressObj.location},` : '',
      address: addressObj.street ? `${addressObj.street},` : '',
      city: addressObj.city.length ? `${addressObj.city[0]},` : '',
      state: addressObj.state.length ? `${addressObj.state[0]},` : '',
      zipcode: addressObj.zipcode ? `${addressObj.zipcode},` : '',
    };
    return `${a.location} ${a.address} ${a.city} ${a.state} ${a.zipcode}`.trim();
  }

  static capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static getParamterHash(url: string, body = {}) {
    const payload = {
      body,
      userAgent: window.navigator.userAgent,
      url,
    };
    return Base64.stringify(hmacSHA256(JSON.stringify(payload), secret));
  }

  static parsePlacesData(place) {
    const temporaryMapLocation = new MapLocationModel();
    place?.address_components.forEach((item) => {
      const addressComponentType = item.types[0];
      switch (addressComponentType) {
        case 'street_number': {
          temporaryMapLocation.address = `${item.long_name} ${temporaryMapLocation.address}`;
          break;
        }
        case 'route': {
          if (item.short_name) {
            temporaryMapLocation.address += ` ${item.short_name}`;
          }
          break;
        }
        case 'postal_code': {
          temporaryMapLocation.zipcode = `${item.long_name}${temporaryMapLocation.zipcode}`;
          break;
        }
        case 'postal_code_suffix': {
          temporaryMapLocation.zipcode = `${temporaryMapLocation.zipcode}-${item.long_name}`;
          break;
        }
        case 'locality':
          temporaryMapLocation.city = item.long_name;
          break;
        case 'administrative_area_level_1': {
          temporaryMapLocation.stateShortname = item.short_name;
          temporaryMapLocation.state = item.long_name;
          break;
        }
        default:
          break;
      }
    });
    temporaryMapLocation.geometry = {
      latitude: place?.geometry.location.lat(),
      longitude: place?.geometry.location.lng(),
    };
    return temporaryMapLocation;
  }

  static getFileType(filename: string): DocumentViewerFileTypes {
    const extensions = {
      [DocumentViewerFileTypes.Image]: [
        'apng',
        'avif',
        'gif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
        'heic',
      ],
      [DocumentViewerFileTypes.Pdf]: ['pdf'],
    };
    const fileExt = this.getFileExtension(filename);

    const extension = Object.entries(extensions).find(([type, exts]) => exts.some((e) => e === fileExt));
    return extension?.[0] as DocumentViewerFileTypes;
  }

  static getFileExtension(filename: string) {
    const fileParts = filename.split('.');
    return fileParts[fileParts.length - 1];
  }

  /**
   * Check if document is viewable on document viewer component
   * @param filename - document filename including extension name
   */
  static isDocumentViewable(filename: string): boolean {
    const fileType = Utils.getFileType(filename);
    const isFileTypeSupported = [DocumentViewerFileTypes.Image, DocumentViewerFileTypes.Pdf].includes(fileType);
    return !!fileType && isFileTypeSupported;
  }

  /**
   * Return document-viewer-supported files
   * @param files
   * @returns
   */
  static async filterAllowedFiles(files: File[], notificationService, uploadService) {
    let allowed = [];
    const blocked = [];

    Array.from(files).forEach((file) => {
      const isAllowed = Utils.isDocumentViewable(file.name);
      if (isAllowed) {
        allowed.push(file);
      } else {
        blocked.push(file);
      }
    });

    if (blocked.length) {
      const blockedFilenames = blocked.map((f) => f.name).join(', and ');
      notificationService.notification('error', `The following files are not supported: \n${blockedFilenames}`);
    }

    if (allowed.length) {
      const formatAllowedFiles = allowed.map(async (file) => {
        if (Utils.getFileType(file.name) === DocumentViewerFileTypes.Image) {
          return uploadService.validateImage(file);
        }

        return file;
      });

      allowed = await Promise.all(formatAllowedFiles);
    }

    return allowed;
  }

  static onFilesUpload(files, dir, uploadService: UploadService): Observable<MultipleFileUploadResult> {
    if (!files.length) {
      return throwError('Please select a file.');
    }

    const fileList: File[] = Array.from(files);

    if (fileList.some((file) => file.size > DOCUMENT_FILE_SIZE)) {
      return throwError('Maximum file size is 20mb only.');
    }

    const formData: FormData = new FormData();
    fileList.forEach((file) => formData.append('uploads', file, file.name));

    return uploadService.uploadMultipleFiles(formData, dir);
  }

  /**
   * Return custom messages for known error messages
   * @param msg
   * @returns
   */
  static getMessagesForKnownIssues(msg: string = ''): string {
    const knownIssues = [
      {
        error: 'ReadableStream uploading is not supported',
        message: `Oops! It looks like your device's operating system is out of date. To continue using our app and enjoy all the latest features, please upgrade your device's operating system to the latest version available. Thank you!`,
      },
    ];
    const isKnownIssue = knownIssues.find((knownIssue) => msg.toLowerCase().includes(knownIssue.error.toLowerCase()));

    return isKnownIssue ? isKnownIssue.message : msg;
  }
}

export function isMobileWeb() {
  const devices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return devices.test(navigator.userAgent);
}

export function findClosestValue(arr: number[], target: number) {
  if (arr.length === 0) return null;

  let closest = arr[0];
  let closestDiff = Math.abs(target - closest);

  for (let i = 1; i < arr.length; i++) {
    const currentDiff = Math.abs(target - arr[i]);
    if (currentDiff < closestDiff) {
      closest = arr[i];
      closestDiff = currentDiff;
    }
  }

  return closest;
}

export function formatTaxId(value: string): string {
  // Remove all non-digits
  const cleaned = value?.replace(/\D/g, '') ?? '';

  // Format as XX-XXXXXXX
  if (cleaned.length >= 2) {
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2, 9)}`;
  }

  return cleaned;
}
