import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentViewerFileTypes } from 'src/app/shared/utils';
import { DocumentService } from 'src/app/core/services/document.service';
import { timer } from 'rxjs';
import { GonativeService } from 'src/app/core/services';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import qs from 'qs';
import { PlatoformsService } from 'src/app/core/services/platoforms.service';
import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.getBrowserName() === "Safari";
const safariVersion = isSafari ? browser.getBrowserVersion() : null;

export interface DocumentViewerConfig {
  name: string;
  // S3 key
  key?: string | null;
  // Complete url
  url?: string;
  fileType: DocumentViewerFileTypes;
  platoformsDownloadUrl?: string;
}

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('headerSlotRef') headerSlot: ElementRef;
  hasHeaderSlotContent = false;

  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @ViewChild('iframePrintContainer') private readonly iframePrintContainer: ElementRef;

  @Input() config: DocumentViewerConfig;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();

  isMobileView = false;
  isNative = this.gonativeService.detectIfNativeApp();
  blobUrls: string[] = [];
  url: string | SafeResourceUrl;
  documentViewerFileTypes = DocumentViewerFileTypes;
  modalConfig: ModalConfig;
  useObjectForPdf = signal(true);

  constructor(
    private readonly globalDocsService: DocumentService,
    private readonly gonativeService: GonativeService,
    private readonly sanitizer: DomSanitizer,
    private readonly http: HttpClient,
    private readonly platoformsService: PlatoformsService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Bootstrap's md devices below
    this.isMobileView = event.target.innerWidth <= 768;
    this.useObjectForPdf.set(+safariVersion >= 18.2 || this.gonativeService.detectIfNativeApp() || this.isMobileView);
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Document Viewer Modal',
      hideHeader: true,
      modalDialogClass: 'document-viewer-modal',
      beforeClose: async () => {
        this.revokeBlobs(this.blobUrls);
        this.isOpen = false;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, config } = changes;

    if (config?.currentValue) {
      this.configureViewer(config.currentValue);
    }

    if (isOpen?.currentValue !== undefined) {
      if (isOpen?.currentValue) {
        this.modalComponent?.open();
      } else {
        this.modalComponent?.close();
      }
    }

    timer(500).subscribe(() => {
      this.hasHeaderSlotContent = this.headerSlot?.nativeElement?.childNodes.length > 1;
    });
  }

  ngOnDestroy(): void {
    this.iframeCleanup();
  }

  configureViewer(config: DocumentViewerConfig) {
    const { key, url, fileType } = config;

    this.url = null;

    if (fileType === DocumentViewerFileTypes.PlatoformsInvite) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    if (fileType === DocumentViewerFileTypes.PlatoformsPdf) {
      const pdfQueryParams = qs.stringify({
        toolbar: 0,
        navpanes: 0,
        scrollbar: 0,
        zoom: 'page-width',
        view: 'FitH',
      });
      const pdfUrl = `${url}#${pdfQueryParams}`;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);
    }

    if ([DocumentViewerFileTypes.Pdf, DocumentViewerFileTypes.Image].includes(fileType)) {
      this.url = `/static/${key}`;
    }
  }

  onCloseBtnClick() {
    this.modalComponent.close();
  }

  onPdfInit() {
    window.dispatchEvent(new Event('resize'));
  }

  onDownloadClick() {
    if (
      [DocumentViewerFileTypes.PlatoformsInvite, DocumentViewerFileTypes.PlatoformsPdf].includes(this.config.fileType)
    ) {
      const { platoformsDownloadUrl } = this.config;
      this.platoformsService.downloadPdf(platoformsDownloadUrl);
      return;
    }

    const { key, name } = this.config;
    this.globalDocsService.download(key, name).subscribe();
  }

  onPrintClick() {
    if (
      [DocumentViewerFileTypes.PlatoformsInvite, DocumentViewerFileTypes.PlatoformsPdf].includes(this.config.fileType)
    ) {
      const { platoformsDownloadUrl } = this.config;
      this.platoformsService.downloadPdf(platoformsDownloadUrl);
      return;
    }

    this.globalDocsService
      .print(this.config.key, this.iframePrintContainer.nativeElement, this.isMobileView)
      .subscribe((blobUrl) => {
        this.blobUrls.push(blobUrl);
      });
  }

  iframeCleanup() {
    this.removeIframePrintContainer();
    this.revokeBlobs(this.blobUrls);
  }

  removeIframePrintContainer() {
    const iframeEl = this.iframePrintContainer?.nativeElement;
    if (iframeEl && iframeEl.parentNode === document.body) {
      this.iframePrintContainer.nativeElement.onload = null;
      document.body.removeChild(iframeEl);
    }
  }

  revokeBlobs(blobUrls: string[]) {
    if (!blobUrls.length) {
      return;
    }

    this.blobUrls.forEach((url) => URL.revokeObjectURL(url));
    this.blobUrls = [];
  }
}
