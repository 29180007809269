import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class ActiveCampaignService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {}

  public createContactByEmail(email: string) {
    return this.http.post(`${this.config.apiUrl}/internal/active-campaign/contact`, { email });
  }

  public trackEvent(event) {
    return this.http.post(
      `${this.config.apiUrl}/internal/active-campaign/event`,
      { event },
      {
        headers: {
          'X-No-Loader': '1',
        },
      },
    );
  }

  trackUrlEvent(url) {
    return this.trackEvent({ type: 'navigation', data: url });
  }

  trackModalOpenEvent(title) {
    return this.trackEvent({ type: 'modalOpen', data: title });
  }

  trackModalCloseEvent(title) {
    return this.trackEvent({ type: 'modalClose', data: title });
  }
}
