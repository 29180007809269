<div class="checkbox btn-group btn-group-toggle {{ class }}" [ngClass]="{ disabled: disabled }">
  @if (formGroup) {
    <form [formGroup]="formGroup">
      <label
        class="form-label d-flex p-0 align-items-center m-0"
        [ngClass]="{ active: formGroup.controls[name]?.value }"
      >
        <input [formControlName]="name" class="d-none" type="checkbox" />
        @if (label) {
          <span [ngClass]="textClass"> {{ label }}</span>
        }
        <ng-content></ng-content>
      </label>
    </form>
  }
</div>
