import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

export interface UsallianceDocsManualReview {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  key: string;
  mimetype: string;
  filename: string;
  size: number;
  userId: string;
  sentDate: Date;
}

interface GetDocsResponse {
  docs: UsallianceDocsManualReview[];
}

type AddDocsRequest = Pick<UsallianceDocsManualReview, 'name' | 'key' | 'mimetype' | 'filename' | 'size'>[];
interface AddDocsResponse {
  docs: UsallianceDocsManualReview[];
  message: string;
}

interface SubmitResponse {
  success: boolean;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class UsallianceDocsManualReviewService {
  private readonly baseUrl: string;
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.baseUrl = `${this.config.apiUrl}/docs/usa/manual-review`;
  }

  getDocs() {
    return this.http.get<GetDocsResponse>(`${this.baseUrl}`);
  }

  addDocs(data: AddDocsRequest) {
    return this.http.post<AddDocsResponse>(`${this.baseUrl}`, data);
  }

  updateDocName(id: string, name: string) {
    return this.http.put<{ message: string }>(`${this.baseUrl}/${id}`, { name });
  }

  deleteDoc(id: string) {
    return this.http.delete<{ message: string }>(`${this.baseUrl}/${id}`);
  }

  submit() {
    return this.http.post<SubmitResponse>(`${this.baseUrl}/submit`, {});
  }
}
