import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { BuyerService, NotificationService, UsersService } from 'src/app/core/services';
import { User } from 'src/app/models';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

@Component({
  selector: 'app-modal-report-user',
  templateUrl: './modal-report-user.component.html',
  styleUrls: ['./modal-report-user.component.scss'],
})
export class ModalReportUserComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() reporterId: string;
  @Input() offenderId: string;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();

  modalConfig: ModalConfig = {};
  buttons = [
    { reason: 'No longer interested', active: false },
    { reason: 'Solicitation', active: false },
    { reason: 'Misleading information', active: false },
    { reason: 'Harassment', active: false },
    { reason: 'Potential Scam', active: false },
  ];

  submitDisabled: boolean;

  constructor(
    private readonly buyerService: BuyerService,
    private readonly usersService: UsersService,
    private readonly notificationService: NotificationService,
  ) {
    // Empty constructor
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Report Modal',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.buttons.forEach((element) => (element.active = false));
        this.submitDisabled = true;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  toggleData(data) {
    data.active = !data.active;

    let anyActive = false;
    this.buttons.forEach((element) => {
      if (element.active) {
        anyActive = true;
      }
    });
    this.submitDisabled = !anyActive;
  }

  sendData() {
    const reason = this.formatReason();
    const data = {
      offenderId: this.offenderId,
      reason,
    };
    this.buyerService.reportUser(data).subscribe((response) => {
      this.notificationService.notification(
        'success',
        'Successfully reported user, please check your email for follow up.',
      );
      this.modalComponent.close();
    });
  }

  formatReason(): string {
    const activeButtons = this.buttons.filter((item) => item.active);
    const reasons = activeButtons.map((btn) => btn.reason).join(', ');
    return `Reason for reporting: ${reasons}.`;
  }
}
