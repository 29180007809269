import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService, SellerService } from 'src/app/core/services';
import { ISellerListing, ListingCategoryTypes } from 'src/app/models';
import STATES from 'src/app/statics/states-hash';
import { startCase } from 'lodash';
import { ModalVintageVehicleOutput } from '../modal-vintage-vehicle/modal-vintage-vehicle.component';
import { OptionsSlideItem } from '../options-slide/options-slide.component';

export interface VehicleInputModernDetails {
  vin: string;
  mpg: { hwy: string; city: string };
  year: number;
  make: string;
  model: string;
  trim: string;
  trimOptions?: string[];
  DriveType?: string;
  Cylinders?: string;
  Doors?: string;
  Transmission?: string;
  FuelType?: string;
  BodyStyle?: string;
  EngineSize?: string;
  state: string;
  vehicleType: ListingCategoryTypes;
  licencePlate?: string;
}

const MANUAL_OPTION: OptionsSlideItem = { text: 'Make/Model', value: 'manualEntry' };
const VIN_OPTION: OptionsSlideItem = { text: 'VIN', value: 'vin' };

export interface VehicleInputVintageDetails
  extends Pick<
    ISellerListing,
    | 'RegistrationYear'
    | 'CarMake'
    | 'CarModel'
    | 'vinNumber'
    | 'isVintageVehicle'
    | 'stateRegistered'
    | 'vehicleType'
    | 'licencePlate'
  > {}

@Component({
  selector: 'app-vehicle-input-start-deal-now',
  templateUrl: './vehicle-input-start-deal-now.component.html',
  styleUrls: ['./vehicle-input-start-deal-now.component.scss'],
})
export class VehicleInputStartDealNowComponent implements OnInit, OnDestroy {
  @Output() formSubmittedEvent = new EventEmitter<VehicleInputModernDetails>();
  @Output() creatingVintageListing = new EventEmitter<VehicleInputVintageDetails>();
  @Output() vehicleType = new EventEmitter<string>();
  @Output() selectedChange = new EventEmitter<OptionsSlideItem>();
  @Input() listingInfo: any = {};

  form: UntypedFormGroup;
  details: VehicleInputModernDetails;
  trimOptions: string[] = [];
  sortStates = STATES.map((state) => state.name);
  sortOptions = ['Auto', 'ATV/OHV', 'Boat', 'Motorcycle', 'RV'];
  stateFeildFilledOut = true;
  lookupOptions = ['License Plate', 'VIN'];
  activeTab = '';
  checkTyposUi = false;
  isListingLive = false;
  vinIsShort = false;
  searchPressed = false;
  isVintageVehicle = false;
  showModalVintageVehicle = false;
  listing: ISellerListing;
  $listingSubscription: Subscription;

  inputOptions: OptionsSlideItem[] = [VIN_OPTION, MANUAL_OPTION];
  selectedOption = VIN_OPTION;

  get f() {
    return this.form.controls;
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly sellerService: SellerService,
    private readonly notificationService: NotificationService,
  ) {
    this.form = this.formBuilder.group(
      {
        vehicleType: ['Auto', [Validators.required]],
        vin: [null, [Validators.required]],
      },
      { updateOn: 'blur' },
    );
  }

  ngOnInit(): void {
    this.activeTab = 'VIN';

    if (this.listingInfo.vin) {
      const { vin, stateRegistered, vehicleType, licencePlate } = this.listingInfo;
      this.form.patchValue({ state: stateRegistered, vehicleType, licencePlate, vin });
    }

    this.details = {
      mpg: null,
      vin: null,
      year: null,
      make: null,
      model: null,
      trim: null,
      DriveType: null,
      Cylinders: null,
      Doors: null,
      Transmission: null,
      FuelType: null,
      BodyStyle: null,
      EngineSize: null,
      state: null,
      vehicleType: null,
    };
  }

  lookUpListingInfo() {
    this.searchPressed = true;
    this.getInfoByVin();
  }

  ngOnDestroy(): void {}

  getInfoByVin() {
    const vin = this.form.controls.vin.value;
    if (vin) {
      this.sellerService
        .getInfoByVIN(vin, this.form.controls.vehicleType.value)
        .pipe(
          catchError((error) => {
            this.form.controls.vin.setErrors({});
            this.searchPressed = true;
            this.showModalVintageVehicle = true;
            throw error;
          }),
        )
        .subscribe((res: { data: any; message: string; infoData: any; trimOptions }) => {
          this.processListingInfo(res, vin);
        });
    }
  }

  processListingInfo(res, vin) {
    const { data, infoData, trimOptions = [] } = res;
    this.trimOptions = [...trimOptions, 'Other'];
    let calculatedTrim = '';
    if (this.trimOptions.length > 0) {
      calculatedTrim = this.trimOptions[0] === 'Other' ? '' : this.trimOptions[0];
    }

    const form = this.form.getRawValue();
    const { year: attrYear, make: attrMake, model: attrModel } = data.attributes;
    const { state, licencePlate, vehicleType } = form;
    this.details = {
      vin,
      year: attrYear,
      make: attrMake,
      model: attrModel,
      mpg: infoData?.mpg ?? null,
      trimOptions: this.trimOptions,
      trim: calculatedTrim,
      DriveType: infoData?.DriveType || null,
      Cylinders: infoData?.Cylinders || null,
      Doors: infoData?.Doors || null,
      Transmission: infoData?.Transmission || null,
      FuelType: infoData?.FuelType || null,
      BodyStyle: infoData?.BodyStyle || null,
      EngineSize: infoData?.EngineSize || null,
      state,
      vehicleType,
      licencePlate: licencePlate ?? null,
    };

    this.formSubmittedEvent.emit(this.details);
  }

  onSelectedOptionChange(option) {
    this.selectedOption = option;
    this.selectedChange.emit(option);
  }

  parseVehicleType(type: string): ListingCategoryTypes {
    let vehicleType = startCase(type);
    if (
      ![
        ListingCategoryTypes.ATVOHV,
        ListingCategoryTypes.Auto,
        ListingCategoryTypes.Boat,
        ListingCategoryTypes.Motorcycle,
        ListingCategoryTypes.RV,
      ].some((cat) => type.toLowerCase().includes(cat.toLowerCase()))
    ) {
      vehicleType = ListingCategoryTypes.Auto;
    }

    return vehicleType;
  }

  changeVehicleType(option) {
    this.form.get('vehicleType').setValue(option);
    this.vehicleType.emit(option);
  }

  checkTypos() {
    this.checkTyposUi = true;
  }

  onVintageVehicleSave(vintageVehicle: ModalVintageVehicleOutput) {
    this.isVintageVehicle = true;
    this.stateFeildFilledOut = true;

    const { vinNumber } = vintageVehicle;
    this.form.patchValue({ vin: vinNumber });
    const form = this.form.getRawValue();

    const { state: stateRegistered, vehicleType, licencePlate } = form;
    const { RegistrationYear, CarMake, CarModel } = vintageVehicle;

    const vehicleDetails = {
      stateRegistered,
      vehicleType,
      vinNumber,
      RegistrationYear,
      CarMake,
      CarModel,
      licencePlate: licencePlate ?? null,
      isVintageVehicle: this.isVintageVehicle,
    };

    this.creatingVintageListing.emit(vehicleDetails);
  }
}
