import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { take } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { BankService, GoogleAnalyticsService, NotificationService } from 'src/app/core/services';
import { PaymentMethod } from 'src/app/models/payment-method.model';

export enum IPaymentType {
  TITLE_CHECK = 'title-check',
  TITLE_TRANSFER = 'title-transfer',
  LOAN_PAYOFF = 'loan-payoff',
}

export interface PaymentIntentConfig {
  listingId?: string;
  type: IPaymentType;
  promoCode?: string;
  meta?: {
    provider: string;
  };
  metadata?: {
    [key: string]: any;
  };
}

export interface PaymentElementConfig {
  productTitle: string;
  buttonText?: string;
}

export interface GoogleAnalyticsEventConfig {
  event: string;
  Login?: string;
}

@Component({
  selector: 'app-payment-element',
  templateUrl: './payment-element.component.html',
  styleUrl: './payment-element.component.scss',
})
export class PaymentElementComponent {
  @Input() paymentIntentConfig: PaymentIntentConfig;
  @Input() googleAnalyticsEvent: GoogleAnalyticsEventConfig;
  @Input() paymentElementConfig: PaymentElementConfig;
  @Input() showDiscountField: boolean = true;
  @Input() customErrorMessage: string;
  @Input() isImmediatePayment: boolean;
  @Output() onNextEvent: EventEmitter<void> = new EventEmitter();
  private stripe: Stripe;
  private elements: StripeElements;
  paymentIntentSecret: string;
  paymentMethodSelected: boolean;
  stripeFormComplete: boolean = false;
  paymentMethods: PaymentMethod[] = [];
  form: UntypedFormGroup;
  loading = false;
  price: number;
  hasDiscount = false;
  applyPromoCodeLoading = false;
  discount: number;
  setPromoCode: string;

  get f() {
    return this.form.controls;
  }

  payLoading = false;

  get hasExistingPaymentMethod() {
    return this.paymentMethods.length !== 0;
  }

  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly bankService: BankService,
    private readonly notificationService: NotificationService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.formBuilder.group({
      paymentMethod: [null, []],
      temporaryPromoCode: [null, []],
    });
  }

  async ngOnInit() {
    this.bankService
      .getPaymentMethods()
      .pipe(take(1))
      .subscribe((response) => {
        this.paymentMethods = response.data;
        if (this.paymentMethods.length) {
          this.paymentMethodSelected = true;
          const defaultPaymentMethod = this.paymentMethods.find((e) => e.default) || this.paymentMethods[0];
          this.form.patchValue({ paymentMethod: defaultPaymentMethod.id }, { emitEvent: false });
        }
      });
    this.stripe = await loadStripe(this.config.stripeKey, {
      apiVersion: '2020-03-02;',
    });

    this.createOrderIntent();
  }

  openStripeElements(secret: string) {
    this.elements = this.stripe.elements({ appearance: { theme: 'stripe' }, clientSecret: secret });
    const paymentElement = this.elements.create('payment', { terms: { card: 'never' } });
    if (!this.hasExistingPaymentMethod) {
      paymentElement.mount('#payment-element');
    }

    paymentElement.on('change', (event) => {
      this.stripeFormComplete = event.complete;
    });
  }

  ngOnDestroy(): void {
    this.discount = 0;
  }

  onAddPaymentMethodSuccess(paymentMethod: PaymentMethod) {
    this.paymentMethods = [paymentMethod, ...this.paymentMethods];
    this.form.patchValue({ paymentMethod: paymentMethod.id });
    this.form.markAsDirty();
    this.paymentMethodSelected = true;
  }

  createOrderIntent(reloadForPromo = false) {
    this.loading = true;
    let paymentSubscription = this.isImmediatePayment
      ? this.bankService.chargeCardOneTimePurchase(this.paymentIntentConfig)
      : this.bankService.setupCardPayment(this.paymentIntentConfig);

    paymentSubscription.pipe(take(1)).subscribe({
      next: (response) => {
        const { amount, discount } = this.isImmediatePayment ? response.payNow : response.payLater;
        this.price = amount / 100;
        this.discount = discount / 100;
        this.setPromoCode = response.promotionCode?.code;
        this.paymentIntentSecret = response.secret;
        this.applyPromoCodeLoading = false;
        this.loading = false;
        setTimeout(() => {
          if (!this.hasExistingPaymentMethod || reloadForPromo) {
            this.openStripeElements(this.paymentIntentSecret);
          }
        }, 100);
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  async startPayment() {
    if (!this.hasExistingPaymentMethod) {
      if (!this.stripeFormComplete) {
        this.notificationService.notification('error', 'Please fill in your payment information.');
        return;
      }
    } else if (!this.paymentMethodSelected) {
      this.notificationService.notification('error', 'Please select a payment method.');
      return;
    }

    const confirmCallback = async (response) => {
      if (response.error) {
        this.notificationService.notification('error', response.error.message);
        this.payLoading = false;
        return;
      }

      if (this.googleAnalyticsEvent) {
        this.googleAnalyticsService.gaEvent(this.googleAnalyticsEvent);
      }

      this.payLoading = false;
      this.onNext();
    };

    try {
      this.payLoading = true;
      const response = await this.bankService.handleStripeSubmit(
        this.stripe,
        this.elements,
        this.paymentIntentSecret,
        this.hasExistingPaymentMethod,
        this.form.value.paymentMethod,
      );
      await confirmCallback(response);
    } catch (err) {
      this.notificationService.notification('error', this.customErrorMessage || 'Unable to process payment. ');
    } finally {
      this.payLoading = false;
    }
  }

  onNext() {
    this.onNextEvent.emit();
  }

  applyPromoCode() {
    if (!this.form.value.temporaryPromoCode) {
      return;
    }
    this.applyPromoCodeLoading = true;
    this.paymentIntentConfig.promoCode = this.form.value.temporaryPromoCode;
    this.createOrderIntent(true);
  }

  deletePromoCode() {
    this.setPromoCode = '';
    this.discount = 0.0;
    this.form.patchValue({ temporaryPromoCode: '' });
    this.createOrderIntent(true);
  }
}
