/* eslint-disable max-len */
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastrService, Overlay, OverlayContainer } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideEnvironmentNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards/auth.guard';
import { FeatureFlagGuard } from './core/guards/feature-flag.guard';
import {
  LoadingService,
  TestDrivesService,
  BuyerService,
  LoanService,
  SellerListingService,
  PlaidService,
  UsersService,
  BankService,
  UsallianceService,
  InspectionService,
  OnboardingService,
  TitleCheckService,
  OrganizationService,
} from './core/services';
import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';

import { HelperService } from './services/helper.service';

import { HttpInterceptorProviders } from './core/interceptors/interceptor-provider';
import { LogoutGuard } from './core/guards/logout.guard';
import { NavComponent } from './nav/nav.component';
import { WidgetsModule } from './widgets/widgets.module';
import { AirbrakeErrorHandler } from './airbrake-error-handler';

import { PopoverGetFullyVerifiedModule } from './widgets/popover-get-fully-verified/popover-get-fully-verified.module';
import { AuthService } from './core/services/auth.service';
import { ModalFailedPayLaterModule } from './widgets/modal-failed-pay-later/modal-failed-pay-later.module';
import { GarageService } from './core/services/garage.service';
import { NavigationTrackingService } from './core/services/navigation-tracking.service';
import { ScriptLoaderService } from './core/services/script-loader.service';
import { PosthogService } from './core/services/posthog.service';
import { HistoryReportsService } from './core/services/history-reports.service';
import { ProveVerificationModule } from './prove-verification/prove-verification.module';
import { ProgressBarStepsModule } from './widgets/progress-bar-steps/progress-bar-steps.module';
import { HelpCenterModule } from './help-center/help-center.module';
import { AuthorizationService } from './core/services/authorizaton.service';
import { FinancialAccountService } from './core/services/financial-account.service';

@NgModule({
  declarations: [AppComponent, NavComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    WidgetsModule,
    PopoverGetFullyVerifiedModule,
    ModalFailedPayLaterModule,
    ProveVerificationModule,
    HammerModule,
    HelpCenterModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ProgressBarStepsModule,
    AbilityModule,
  ],
  providers: [
    HttpInterceptorProviders,
    AuthGuard,
    FeatureFlagGuard,
    LogoutGuard,
    UsersService,
    BuyerService,
    TestDrivesService,
    SellerListingService,
    AuthService,
    CookieService,
    BankService,
    PlaidService,
    UsallianceService,
    LoanService,
    HelperService,
    ToastrService,
    LoadingService,
    DeviceDetectorService,
    Overlay,
    OverlayContainer,
    GarageService,
    InspectionService,
    NavigationTrackingService,
    OnboardingService,
    ScriptLoaderService,
    PosthogService,
    HistoryReportsService,
    TitleCheckService,
    OrganizationService,
    AuthorizationService,
    FinancialAccountService,
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    { provide: 'locationObject', useValue: location },
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
