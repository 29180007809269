<app-modal #modal [modalConfig]="modalConfig">
  <div class="limit-modal-body text-center">
    <img class="mb-4" src="/assets/chat-denied.svg" alt="chat-denied" />
    @if (isVerified) {
      <div class="limit-header mb-4 mt-2">New chat limit reached</div>
      <div class="body mb-3">
        You've reached the limit for starting new chats. To protect our users from scammers, please contact support to
        discuss your account.
      </div>
      <button class="btn-primary btn mb-3 w-100" routerLink="/help-center" (click)="closeModal()">
        Contact support
      </button>
    } @else {
      <div class="limit-header mb-4 mt-2">Mobile phone verification required</div>
      <div class="body mb-3">
        To reduce fraud and spam, all users must complete this verification step. The process takes less than 10 sec.
      </div>
      <button class="btn-primary btn mb-3 w-100" (click)="closeModal(true)">Get verified</button>
    }
    <a
      class="text-decoration-none learn-link"
      href="https://support.privateauto.com/article/266-chat-safety"
      target="_blank"
      rel="noopener"
      >Learn more</a
    >
  </div>
</app-modal>
