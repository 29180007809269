import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CustomDateParserFormatter } from 'src/app/core/services/ngb-datepicker-custom.service';
import { isValidDate } from 'src/app/core/validators/date';
import { MapLocationModel } from 'src/app/models';
import { BusinessClassification } from 'src/app/models/prove-verification.model';
import STATES from 'src/app/statics/states-hash';
import { PlacesAutocompleteInput } from 'src/app/widgets/places-autocomplete-input/places-autocomplete-input.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-edit-business-information',
  templateUrl: './edit-business-information.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class EditBusinessInformationComponent implements OnInit, OnDestroy {
  @ViewChild('autocompleteInput') autocompleteInput: PlacesAutocompleteInput;
  @Output() onContinue = new EventEmitter();
  @Input() form: FormGroup;
  @Input() showError: boolean;
  @Input() disablePrimaryFields: boolean;
  isEditing = false;
  submitted = false;
  readonly states = STATES;
  isFullAddressVisible = false;
  location: MapLocationModel;
  businessTypes = [
    {
      label: 'Sole proprietorships',
      value: 'soleProprietorship',
    },
    {
      label: 'Unincorporated association',
      value: 'soleProprietorship',
    },
    {
      label: 'Trust',
      value: 'soleProprietorship',
    },
    {
      label: 'Corporation',
      value: 'corporation',
    },
    {
      label: 'Publicly traded corporations',
      value: 'corporation',
    },
    {
      label: 'LLCs',
      value: 'llc',
    },
    {
      label: "Partnerships, LP's, LLP's",
      value: 'partnership',
    },
  ];

  @Input() businessClassifications: BusinessClassification[];

  ngOnInit(): void {
    this.location = {
      formattedAddress: this.form.get('address').value,
    } as any;
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onVerify() {
    this.submitted = true;

    this.validateDate('filingDate');

    if (this.form.invalid) {
      return;
    }
    this.onContinue.emit();
  }

  validateDate(property: string) {
    if (typeof this.form.value[property] === 'string') {
      const parser = new CustomDateParserFormatter();
      const dateValue = parser.parse(this.form.value.filingDate);
      this.form.get(property).setValue(dateValue, { emitEvent: false });
      if (!isValidDate(dateValue)) {
        this.form.get(property).setErrors({ message: 'Invalid date format' }, { emitEvent: false });
      }
    } else if (this.form.value.filingDate && this.form.value.filingDate.year.toString().length !== 4) {
      this.form.get(property).setErrors({ message: 'Year must be 4 digits' }, { emitEvent: false });
    }
  }

  get f() {
    return this.form.controls;
  }

  onAddressValueChange(value: string) {
    this.form.patchValue({ address: value });
  }

  updateLocation(location: MapLocationModel) {
    this.form.patchValue({
      address: location.address,
      extendedAddress: '',
      city: location.city,
      region: location.stateShortname,
      postalCode: location.zipcode?.split('-')[0],
    });
    this.autocompleteInput.searchInput.nativeElement.value = location.address;
  }

  getClassificationOptions() {
    return this.businessClassifications.map((e) => ({ label: e.name, value: e.id }));
  }
}
