import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { NotificationService, UploadService, UsersService } from 'src/app/core/services';
import { Subscription } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IUser } from 'src/app/models';

@Component({
  selector: 'app-modal-set-display-name',
  templateUrl: './modal-set-display-name.component.html',
  styleUrls: ['./modal-set-display-name.component.scss'],
})
export class ModalSetDisplayNameComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();

  modalConfig: ModalConfig = {};
  isMobileView = false;
  imageLoading = false;
  onFileSelect$: Subscription;
  user$: Subscription;
  user: IUser;
  photo: string;
  form: UntypedFormGroup;

  get f() {
    return this.form.controls;
  }

  constructor(
    private readonly uploadService: UploadService,
    private readonly usersService: UsersService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly notificationService: NotificationService,
  ) {
    this.form = this.formBuilder.group({
      nickname: [null],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Bootstrap's md devices below
    this.isMobileView = event.target.innerWidth <= 768;
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Share vehicle modal',
      hideClose: true,
      whiteClose: true,
      backdrop: 'static',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    this.user$ = this.usersService.user.subscribe((user) => {
      this.user = user;
      const { profileImage } = this.user.userDetails;

      this.photo = profileImage || this.photo;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  async onFileSelect(event) {
    const file: File = await this.uploadService.validateImage(event.target.files[0]);
    if (file) {
      this.imageLoading = true;
      const formData = new FormData();
      formData.append('image', file);

      this.onFileSelect$ = this.uploadService
        .uploadImage(formData, 'profile/', 'image')
        .pipe(
          take(1),
          finalize(() => {
            this.imageLoading = false;
          }),
          switchMap(({ imageUrl }: any) => this.usersService.updateProfile({ profileImage: imageUrl })),
          switchMap(() => this.usersService.getCurrentProfile()),
        )
        .subscribe({
          next: () => {
            this.notificationService.notification('success', 'Successfully uploaded your new profile picture!');
          },
        });
    }
  }

  onSave() {
    if (!this.f.nickname.value) {
      return;
    }
    this.usersService
      .updateProfile({ nickname: this.f.nickname.value })
      .pipe(
        switchMap(() => this.usersService.getCurrentProfile()),
        take(1),
      )
      .subscribe((results) => {
        this.notificationService.notification('success', 'Successfully uploaded your new profile picture!');

        this.modalComponent.close();
      });
  }
}
