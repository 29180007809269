import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@AutoUnsubscribe()
@Component({
  selector: 'app-verification-failed',
  templateUrl: './verification-failed.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class VerificationFailedComponent implements OnInit, OnDestroy {
  @Input()
  retryable = false;

  @Output()
  onRetry = new EventEmitter();

  appName: string;
  constructor(@Inject(APP_CONFIG) private readonly config: IAppConfig) {
    this.appName = this.config.appName;
  }
  @Output() onClose = new EventEmitter();

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
