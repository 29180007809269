import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';
import { AirbrakeErrorHandler } from 'src/app/airbrake-error-handler';
import { DealsService, TestDrivesService } from 'src/app/core/services';
import { OfferService } from 'src/app/core/services/offer.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ChannelStatus, IChatListing } from 'src/app/marketing-inbox/marketing-inbox.model';
import { ISellerListingStatuses, ISellerListingDisplayTypes } from 'src/app/models/seller-listing.model';
import { ITestDrive, TestDriveStatus } from 'src/app/models/test-drive.model';
import { IUser, User } from 'src/app/models/user.model';
import { ModalComponent } from '../modal/modal.component';
import { getListingMainImage } from 'src/app/shared/utils-listing';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';
import { PosthogService } from 'src/app/core/services/posthog.service';
import { IOrganization, OrganizationVerificationStatus } from 'src/app/models/organization.model';

interface DisplayListing {
  slug: string;
  name: string;
  image: string;
  price: string;
  mileage: string;
  sellerId: string;
  status: string;
  isPublic: boolean;
  vehicleType: string;
  additionalDetails?: any;
}

interface DisplayUser {
  id: string;
  name: string;
  nickname: string;
  shortName: string;
  // type: string;
  profileImage: string;
  location: string;
  date: Date;
  isVerified: boolean;
  verifications: {
    verified: boolean;
    text: string;
    isBank?: boolean;
  }[];
}

interface DisplayOrganization {
  id: string;
  name: string;
  profileImage: string;
  location: string;
  date: Date;
  isVerified: boolean;
  verifications: {
    verified: boolean;
    text: string;
    isBank?: boolean;
  }[];
}

@AutoUnsubscribe()
@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() user: IUser;
  @Input() organization?: IOrganization;
  @Input() userId: string;
  @Input() chatId: number;
  @Input() listing: IChatListing;
  @Input() hideOffer = false;
  @Input() showChatButtons = false;
  @Input() channelStatus = ChannelStatus.active;

  @Input() reloadOffer: boolean;
  @Input() reloadTestDrive: boolean;
  @Output() reloadOfferChange = new EventEmitter<boolean>();
  @Output() reloadTestDriveChange = new EventEmitter<boolean>();

  currentUserId: string;
  displayUser: DisplayUser;
  displayListing: DisplayListing;
  displayOrganization: DisplayOrganization;
  ChannelStatus = ChannelStatus;

  loadingListing = false;
  loadingTestDrive = false;
  confirmedTestDrive = false;
  loadingUser = false;
  loadingOffer = false;
  isChatBtnDisplayed = true;
  showReportUser = false;
  showLeaveChat = false;
  showArchiveChat = false;
  fullyVerified = false;
  partiallyVerified = false;
  showInfoModal = false;

  offer: {
    price: number;
    createdAt: string;
    deal: string;
  };

  testDrive: ITestDrive;

  getLatestAcceptedOffer$: Subscription;
  getListing$: Subscription;
  getUser$: Subscription;
  getLatestConfirmedTestDrive$: Subscription;
  listingStatuses = ISellerListingStatuses;
  hasPendingDeal = false;
  appName: string;
  isBrowseListingEnabled: boolean;

  get isUserVerified() {
    return this.displayUser?.verifications?.every((status) => status.verified);
  }

  get loading() {
    return this.loadingUser && this.loadingListing && this.loadingOffer;
  }

  constructor(
    private readonly router: Router,
    private readonly usersService: UsersService,
    private readonly offerService: OfferService,
    private readonly testDriveService: TestDrivesService,
    private readonly errorHandler: AirbrakeErrorHandler,
    private readonly dealService: DealsService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly posthogService: PosthogService,
  ) {
    this.appName = this.config.appName;
    this.isBrowseListingEnabled = this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.BrowseListings);
  }

  ngOnInit(): void {
    this.usersService.user.subscribe((user: User) => {
      this.currentUserId = user._id;
    });

    this.isChatBtnDisplayed = !this.router.url.includes('/inbox');
  }

  ngOnChanges(changes: SimpleChanges) {
    const { userId, user, listing, chatId, reloadOffer, reloadTestDrive, organization } = changes;

    if (userId?.currentValue) {
      this.getUser(userId.currentValue);
    }

    if (organization?.currentValue) {
      this.displayOrganization = this.getDisplayOrganization(organization.currentValue);
    } else if (user?.currentValue) {
      this.displayUser = this.getDisplayUser(user.currentValue);
    }

    if (listing?.currentValue) {
      this.displayListing = this.getDisplayListing(listing?.currentValue);
    }

    if ((chatId?.currentValue || (reloadOffer?.currentValue && this.chatId)) && !this.hideOffer) {
      this.getLatestAcceptedOffer();
    }

    if (chatId?.currentValue || (reloadTestDrive?.currentValue && this.chatId)) {
      this.getLatestTestDrive();
    }

    // Reset
    if (chatId?.currentValue && !listing?.currentValue && !userId?.currentValue) {
      this.displayListing = null;
      this.displayUser = null;
    }
  }

  ngOnDestroy() {
    // AutoUnsubscribe
  }

  getUser(id: string) {
    this.displayUser = null;
    this.loadingUser = true;

    this.getUser$ = this.usersService
      .getUser(id)
      .pipe(
        finalize(() => {
          this.loadingUser = false;
        }),
      )
      .subscribe((user: any) => {
        this.loadingUser = false;
        if (!user) {
          return;
        }

        this.displayUser = this.getDisplayUser(user);
      });
  }

  getDisplayUser(user: IUser): DisplayUser {
    const {
      _id,
      createdAt,
      verification: { isEmailVerified, isPhoneVerified, isLicenseVerified, isBankConnected },
      userDetails: { firstName, lastName, nickname, profileImage, city, state, shortName },
    } = user;
    this.partiallyVerified = isEmailVerified && isPhoneVerified && isLicenseVerified;
    const isVerified = isEmailVerified && isPhoneVerified && isLicenseVerified && isBankConnected;
    this.fullyVerified = isVerified;
    const name = firstName && lastName ? `${firstName} ${lastName.charAt(0)}.` : '';

    return {
      id: _id,
      name,
      nickname,
      shortName,
      profileImage,
      location: `${city || 'user city unset'}, ${state || 'user state unset'}`,
      date: createdAt,
      verifications: [
        { verified: isEmailVerified, text: 'Email' },
        { verified: isLicenseVerified, text: 'Phone + ID verified' },
        { verified: isBankConnected, text: `${this.appName} Pay`, isBank: true },
      ],
      isVerified,
    };
  }

  getDisplayOrganization(organization: IOrganization) {
    const { _id, createdAt, name, verificationStatus, city, state, logo } = organization;
    return {
      id: _id,
      name,
      date: createdAt,
      verificationStatus,
      profileImage: logo,
      location: `${city || 'city unset'}, ${state || 'state unset'}`,
      isVerified: organization.verificationStatus === OrganizationVerificationStatus.Verified,
      verifications: [
        {
          verified: organization.verificationStatus === OrganizationVerificationStatus.Verified,
          text: 'Business Verified',
        },
      ],
    };
  }

  getDisplayListing(listing: IChatListing): DisplayListing {
    if (!listing) {
      return null;
    }

    this.dealService.hasCurrentPendingDeal(listing._id).subscribe((results: any) => {
      if (results.data) {
        this.hasPendingDeal = true;
      }
    });

    const {
      CarMake,
      CarModel,
      Price,
      Mileage,
      userId,
      slug,
      status,
      RegistrationYear,
      displayType,
      vehicleType,
      additionalDetails,
    } = listing;
    const isPublic = displayType === ISellerListingDisplayTypes.Public;

    return {
      slug,
      name: `${RegistrationYear} ${CarMake} ${CarModel}`,
      image: getListingMainImage(listing),
      price: `${Price || 0} `,
      mileage: `${Mileage || 0}`,
      sellerId: `${userId}`,
      isPublic,
      status,
      vehicleType,
      additionalDetails,
    };
  }

  getLatestAcceptedOffer() {
    this.loadingOffer = true;
    this.offer = null;

    this.getLatestAcceptedOffer$ = this.offerService
      .getLatestAcceptedOffer(this.chatId)
      .pipe(
        finalize(() => {
          this.loadingOffer = false;
        }),
        filter((resp: any) => resp.data),
        catchError((err) => {
          this.reloadOfferChange.emit(false);
          this.errorHandler.handleError(err);
          return EMPTY;
        }),
      )
      .subscribe((resp: any) => {
        const {
          deal: { _id, createdAt },
          price,
        } = resp.data;

        this.offer = {
          deal: _id,
          createdAt,
          price,
        };

        this.reloadOfferChange.emit(false);
        this.loadingOffer = false;
      });
  }

  getLatestTestDrive() {
    this.loadingTestDrive = true;
    this.testDrive = null;
    this.getLatestConfirmedTestDrive$ = this.testDriveService
      .getTestDrivesByChatId(this.chatId)
      .pipe(
        finalize(() => {
          this.loadingTestDrive = false;
        }),
        catchError((err) => {
          this.errorHandler.handleError(err);
          this.reloadTestDriveChange.emit(false);
          return EMPTY;
        }),
      )
      .subscribe((testDrives: ITestDrive[]) => {
        this.testDrive = testDrives[0];

        if (!this.testDrive) {
          return;
        }

        const now = new Date();
        const scheduleDate = new Date(this.testDrive.scheduleDate);
        this.confirmedTestDrive = this.testDrive.status === TestDriveStatus.Confirmed && scheduleDate > now;

        this.loadingTestDrive = false;
        this.reloadTestDriveChange.emit(false);
      });
  }

  blockUser() {
    this.showReportUser = true;
  }

  removeConversation() {
    this.showLeaveChat = true;
  }

  archiveConversation() {
    this.showArchiveChat = true;
  }

  openInfoModal() {
    this.showInfoModal = true;
  }
}
