<app-modal #modal [modalConfig]="modalConfig">
  <div class="d-flex justify-content-between h2">
    Documents
    <button class="btn-outline-primary mt-3" (click)="documentUpload.click()">
      Upload
      <input type="file" class="d-none" (change)="onDocumentsUpload($event)" multiple="multiple" #documentUpload />
    </button>
  </div>
  <div
    class="document-container"
    [ngClass]="{ 'container d-flex justify-content-center align-items-center': !documents.length }"
  >
    @if (!loading) {
      @if (!documents.length) {
        <div class="text-center">
          <div class="h3 fw-bold">No documents added</div>
          <div>Upload documents to send via chat.</div>
        </div>
      } @else {
        @for (document of documents; track document; let i = $index) {
          <div>
            <div class="document-item pb-2">
              <div class="document-view-item">
                <a
                  class="file-name"
                  (click)="onDocumentViewClick(document)"
                  [ngClass]="{ 'hover-underline-animation': document.viewable }"
                >
                  <img src="assets/file-text.svg" class="image-file d-inline-block me-3" alt="document icon" />
                  <span class="text text-truncate">{{ document.name }}</span>
                </a>
              </div>
              <div class="dots-position">
                <button class="float-end btn btn-gray" (click)="shareDocument(document)">Share</button>
              </div>
            </div>
          </div>
        }
      }
    }
  </div>
</app-modal>

<app-document-viewer [(isOpen)]="showDocumentViewer" [config]="documentViewerConfig"></app-document-viewer>
