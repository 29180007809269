import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Component({
  selector: 'app-modal-view-loan-payoff-details',
  templateUrl: './modal-view-loan-payoff-details.component.html',
  styleUrl: './modal-view-loan-payoff-details.component.scss',
})
export class ModalViewLoanPayoffDetailsComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() loanPayoffInformation;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};
  currentStepIndex: number;

  loanPayoffStatusInfo = [
    {
      step: 'check',
      pendingStepText: 'Check will be printed',
      pendingSubText:
        'Once the deal closes the funds will be available and the check will begin processing for printing',
      finishedStepText: 'Check printed',
      complete: false,
    },
    {
      step: 'mailed',
      pendingStepText: 'Mailed to lien holder',
      finishedSubText: 'The check has been sent to the lien holder via USPS priority mail',
      finishedStepText: 'Mailed to lien holder',
      complete: false,
    },
    {
      step: 'received',
      pendingStepText: 'Payment received',
      finishedSubText: `The lien holder has received and cashed the check to satisfy the lien. Title being mailed to ${this.config.appName} for processing`,
      finishedStepText: 'Payment received',
      complete: false,
    },
  ];

  constructor(@Inject(APP_CONFIG) private readonly config: IAppConfig) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Video viewer modal',
      hideLogo: true,
      headerText: 'Loan payoff service',
      headerBg: '#EAF8FF',

      beforeClose: async (isClosedByUser = true) => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit(isClosedByUser);
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
      this.determineStatus();
    }
  }

  determineStatus() {
    const status = this.loanPayoffInformation?.checkIssuingInfo?.status;

    switch (status) {
      case 1:
      case 2:
      case 3:
        this.loanPayoffStatusInfo[0].complete = false;
        this.loanPayoffStatusInfo[1].complete = false;
        this.loanPayoffStatusInfo[2].complete = false;
        break;
      case 4:
        this.loanPayoffStatusInfo[0].complete = true;
        this.loanPayoffStatusInfo[1].complete = true;
        this.loanPayoffStatusInfo[2].complete = false;
        break;
      case 5:
        this.loanPayoffStatusInfo[0].complete = true;
        this.loanPayoffStatusInfo[1].complete = true;
        this.loanPayoffStatusInfo[2].complete = true;
        break;
      default:
        this.loanPayoffStatusInfo[0].complete = false;
        this.loanPayoffStatusInfo[1].complete = false;
        this.loanPayoffStatusInfo[2].complete = false;
        break;
    }
    this.currentStepIndex = this.loanPayoffStatusInfo.findIndex((step) => !step.complete);
    if (this.currentStepIndex === -1) {
      this.currentStepIndex = this.loanPayoffStatusInfo.length - 1;
    }
  }
}
