import { IDeal } from './deals.models';
import { SignatureRequiredByTypes } from './document.models';

export enum IDocumentTypes {
  Generated = 'generated',
  Uploaded = 'uploaded',
  Shared = 'shared',
  TitleAttachment = 'titleAttachment',
}

export interface IDocumentViewerBase {
  name: string;
  fileType: string;
  type: IDocumentTypes;
}

export interface IDealDocumentBasic extends IDocumentViewerBase {
  type: IDocumentTypes;
  toReview: boolean;
  editable: boolean;
  viewable: boolean;
  temporaryName: string;
  isSignatureRequired?: boolean;
  signatureRequiredby?: SignatureRequiredByTypes;
}

export interface IDealDocumentGenerated extends IDealDocumentBasic {
  _id: string;
  name: string;
  filename: string;
  url: string;
  key: string;
  deal: IDeal;
  documentTemplate: string;
  buyerSignedDate: Date;
  sellerSignedDate: Date;
  isBuyerSignatureRequired: boolean;
  isSellerSignatureRequired: boolean;
  documentType?: string;
}

export interface IDealDocumentUploaded extends IDealDocumentBasic {
  _id: string;
  name: string;
  filename: string;
  key: string;
  url: string;
  deal: IDeal;
  createdBy: Date;
}

export interface IListingDocumentBasic {
  _id: string;
  name: string;
  filename: string;
  key: string;
  url: string;
  listingId?: string;
  sharedDeals: string[];
  sharedChats: string[];
  createdBy: Date;
  dealId?: string;
}

export interface IListingDocument extends IListingDocumentBasic, IDealDocumentBasic {}

export interface IDocumentViewerTitle {
  _id: string;
  type: string;
  name: string;
  key: string;
  fileType: string;
  toReview: boolean;
}

export type IDealDocumentsListItem = IDealDocumentGenerated | IDealDocumentUploaded | IDocumentViewerTitle;
