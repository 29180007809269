import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, signal, SimpleChanges } from '@angular/core';

export interface StepperCircleConfig {
  size: string;
  color: string;
  labelMarginTop: string;
  items: {
    label?: string;
    isCompleted: boolean;
  }[];
}

@Component({
  selector: 'app-stepper-circle',
  templateUrl: './stepper-circle.component.html',
  styleUrl: './stepper-circle.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class StepperCircleComponent implements OnChanges {
  @Input() config: StepperCircleConfig;

  labelPosBottom = signal('-30px');

  ngOnChanges(changes: SimpleChanges): void {
    const { config } = changes;

    if (config?.currentValue) {
      this.initConfig(config.currentValue);
    }
  }

  initConfig(config: StepperCircleConfig) {
    const sizePx = parseInt(config.size, 10);
    this.labelPosBottom.set(`${-sizePx * 2 - 10}px`);
  }
}
