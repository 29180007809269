import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { Clipboard } from '@angular/cdk/clipboard';
import { GoogleAnalyticsService, NotificationService } from 'src/app/core/services';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';
import { ListingCategoryTypes } from 'src/app/models';

export interface ModalShareDealNowListing {
  img: string;
  name: string;
  price: number;
  miles: number | string;
  slug?: string;
  link: string;
  vehicleType?: string;
  hours?: number;
  _id?: string;
}

export enum ShareStyles {
  standard = 'standard',
  dealNowSeller = 'dealNowSeller',
  dealNowBuyer = 'dealNowBuyer',
}

const getMessage = (name, price, miles, url, vehicleType, appName) => `
Hi! Let’s finish a deal on ${appName} on this 
${name} 
$${price || 0} • ${miles || 'N/A'} ${vehicleType === ListingCategoryTypes.Boat ? 'hours' : 'miles'}
${url}
`;

@Component({
  selector: 'app-modal-share-deal-now',
  templateUrl: './modal-share-deal-now.component.html',
  styleUrls: ['./modal-share-deal-now.component.scss'],
})
export class ModalShareDealNowComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Input() listing: ModalShareDealNowListing;
  @Input() headerText = 'Share vehicle';
  @Input() shareStyle: ShareStyles = ShareStyles.standard;
  @Output() onClose = new EventEmitter();
  @Input() offerId: string;

  modalConfig: ModalConfig = {};
  dealNowUrl = '';
  subject = '';
  message = '';
  isMobileView = false;
  externalMessageText = '';
  shareStyles = ShareStyles;
  isCopied = false;
  appName: string;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly notificationService: NotificationService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly googleAnalytics: GoogleAnalyticsService,
  ) {
    this.appName = this.config.appName;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Bootstrap's md devices below
    this.isMobileView = event.target.innerWidth <= 768;
  }

  ngOnInit(): void {
    this.googleAnalytics.gaEvent({
      event: 'Share Listing',
      Login: 'post',
    });

    this.modalConfig = {
      name: 'Share vehicle modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, listing } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
      this.externalMessageText =
        `Hi! ${
          this.shareStyle === ShareStyles.dealNowBuyer ? "I'm interested in buying your" : "Let's finish a deal on this"
        } ${this.listing.name}. I'd like to use a platform called ${this.appName} to do the deal because it's a safe & ` +
        `secure way to transfer the money. Feel free to do your own research on it. If it looks good to you, I can` +
        ` send an invite to the deal!`;
    }

    if (listing?.currentValue) {
      this.setDetails(listing.currentValue);
    }
  }

  setDetails(listing: ModalShareDealNowListing) {
    const { name, price, miles, vehicleType } = listing;
    this.dealNowUrl = `${this.config.websiteUrl}/deal-now/${this.offerId}`;
    this.subject = encodeURIComponent(`Let's Seal the Deal on ${this.appName}!`);
    this.message = encodeURIComponent(getMessage(name, price, miles, this.dealNowUrl, vehicleType, this.appName));
  }

  onCopyLinkClick() {
    this.clipboard.copy(this.dealNowUrl);
    this.notificationService.notification('success', 'Link copied to clipboard.');
    this.isCopied = true;
  }

  onMessagesClick() {
    let url = `sms:///&body=${this.message}`;

    if (navigator.userAgent.match(/Android/i)) {
      url = `sms:///?body=${this.message}`;
    }

    if (navigator.userAgent.match(/iPhone/i)) {
      url = `sms:///&body=${this.message}`;
    }

    window.open(url, '_blank');
  }

  onEmailClick() {
    window.open(`mailto:?subject=${this.subject}&body=${this.message}`, '_blank');
  }

  copyExternalText() {
    this.clipboard.copy(this.externalMessageText);
    this.notificationService.notification('success', 'Copied to clipboard');
  }
}
