import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import { NotificationService, OnboardingService, UsersService, OrganizationService } from 'src/app/core/services';
import {
  BusinessClassification,
  BusinessIdentity,
  ProveBusinessVerificationModel,
} from 'src/app/models/prove-verification.model';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { tap } from 'rxjs';
import { IUser } from 'src/app/models';
import { Router } from '@angular/router';
import { formatTaxId } from 'src/app/shared/utils';
import { OrganizationVerificationStatus } from 'src/app/models/organization.model';

enum VerificationStep {
  VerificationStart = 'verification-start',
  VerificationReview = 'verification-review',
  VerificationEdit = 'verification-edit',
  VerificationResult = 'verification-result',
  VerificationBeneficialOwner = 'verification-beneficial-owners',
}
@AutoUnsubscribe()
@Component({
  selector: 'app-business-verification-modal',
  templateUrl: './business-verification-modal.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class BusinessVerificationModal implements OnInit, OnDestroy {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter();
  @Output() onSkipVerification = new EventEmitter();

  onboardingSession: string;
  verificationStep: VerificationStep;

  businessIdentityForm: FormGroup;

  isManualEntry = false;
  showManualEntryError = false;
  isAutofilled = false;
  isVerificationSucceeded = false;
  initialBusinessIdentityForm;

  businessIdentities: BusinessIdentity[] = [];
  user: IUser;

  businessClassifications: BusinessClassification[];

  constructor(
    private readonly fb: FormBuilder,
    private readonly onboardingService: OnboardingService,
    private readonly notificationService: NotificationService,
    private readonly usersService: UsersService,
    private readonly organizationService: OrganizationService,
    private readonly router: Router
  ) {
    this.businessIdentityForm = this.fb.group({
      currentBusinessId: [null, []],
      businessName: [null, [Validators.required]],
      businessClassification: [null, [Validators.required]],
      licenseNumber: [null, [Validators.required]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      region: [null, [Validators.required]],
      taxId: [null, [Validators.required]],
      filingDate: [null, [Validators.required]],
      registrationType: [null, [Validators.required]],
    });
  }

  modalConfig: ModalConfig = {};
  ngOnInit(): void {
    this.modalConfig = {
      hideHeader: true,
      modalDialogClass: 'prove-verification-modal',
      name: 'Prove verification modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.verificationStep = null;
        this.isManualEntry = false;
        this.showManualEntryError = false;
        this.onboardingService.setVerifyBusinessModalVisibility(false);
        return true;
      },
    };

    this.organizationService.getBusinessClassifications().subscribe((businessClassifications) => {
      this.businessClassifications = businessClassifications;
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  onManualEntry() {
    this.isManualEntry = true;
    this.verificationStep = VerificationStep.VerificationEdit;
    this.isAutofilled = false;
  }

  handlePrefillResponse(response: { success: boolean; data: ProveBusinessVerificationModel }) {
    if (response.success) {
      this.initIdentityForm(response.data);
      this.isAutofilled = true;
      this.verificationStep = VerificationStep.VerificationReview;
      return;
    }
    this.verificationStep = VerificationStep.VerificationEdit;
    this.showManualEntryError = true;
  }
  closeModal() {
    this.modalComponent.close();
  }

  skipVerification() {
    this.modalComponent.close();
    this.onSkipVerification.emit();
  }

  onEditInformation() {
    this.verificationStep = VerificationStep.VerificationEdit;
    this.initBusinessIdentityForm(
      this.businessIdentities.find((e) => e.id === this.businessIdentityForm.value.currentBusinessId)
    );
  }

  onSubmitWithoutChanges() {
    this.onboardingService
      .verifyBusinessNoChanges({
        currentBusinessId: this.businessIdentityForm.value.currentBusinessId,
        businessClassification: this.businessIdentityForm.value.businessClassification,
        licenseNumber: this.businessIdentityForm.value.licenseNumber,
      })
      .pipe(
        tap((response: any) => {
          if (response.verified) {
            this.notificationService.notification('success', 'Business account verified successfully');
            this.usersService.getCurrentProfile().subscribe();
          } else {
            this.notificationService.notification(
              'error',
              'We are not able to verify your account. Please check the details and try again'
            );
          }
          this.handleVerificationResult(response.verified);
        })
      )
      .subscribe({
        error: () => {
          this.notificationService.notification(
            'error',
            'We are not able to verify your account. Please check the details and try again'
          );
          this.verificationStep = VerificationStep.VerificationResult;
        },
      });
  }

  onSubmitIdentityForm() {
    if (this.isFormUnChanged()) {
      this.onSubmitWithoutChanges();
      return;
    }

    const {
      businessName,
      address,
      city,
      postalCode,
      region,
      taxId,
      currentBusinessId,
      filingDate,
      registrationType,
      businessClassification,
      licenseNumber,
    } = this.businessIdentityForm.value;
    let filingFormatted;
    if (filingDate?.year) {
      filingFormatted = [
        filingDate.year,
        filingDate.month.toString().padStart(2, '0'),
        filingDate.day.toString().padStart(2, '0'),
      ].join('-');
    }
    this.onboardingService
      .verifyBusiness({
        currentBusinessId,
        licenseNumber,
        businessAddress: {
          address,
          city,
          postalCode,
          region,
        },
        businessName,
        taxId: formatTaxId(taxId),
        registrationFiling: {
          filingDate: filingFormatted,
          registrationType,
        },
        businessClassification,
        autofilled: this.isAutofilled,
      })
      .pipe(
        tap((response: any) => {
          if (response.verified) {
            this.notificationService.notification(
              'success',
              this.isAutofilled ? 'Business account verified successfully' : 'Application submitted successfully'
            );
            this.usersService.getCurrentProfile().subscribe();
          } else {
            this.notificationService.notification(
              'error',
              'We are not able to verify your account. Please check the details and try again'
            );
          }
          this.handleVerificationResult(response.verified);
        })
      )
      .subscribe({
        error: () => {
          this.notificationService.notification(
            'error',
            'We are not able to verify your account. Please check the details and try again'
          );
        },
      });
  }

  handleVerificationResult(success: boolean) {
    if (this.isAutofilled && success) {
      this.verificationStep = VerificationStep.VerificationBeneficialOwner;
      return;
    }
    this.isVerificationSucceeded = success;
    this.verificationStep = VerificationStep.VerificationResult;
  }

  shouldPromptBeneficialOwnerOnboarding() {
    return (
      Boolean(this.user?.organization.verificationStatus === OrganizationVerificationStatus.Verified) &&
      typeof this.user.organization?.hasBeneficialOwners !== 'boolean'
    );
  }

  setInitialVerificationModalState(user: IUser) {
    this.user = user;
    const proveResult = user.proveBusinessResult;
    if (!proveResult) {
      this.verificationStep = VerificationStep.VerificationStart;
      this.onboardingService.setVerifyBusinessModalVisibility(true);
      return;
    }

    const isVerificationFailed =
      typeof proveResult.businessVerifyResponse?.status === 'number' &&
      (proveResult.businessVerifyResponse?.status !== 0 || proveResult.businessVerifyResponse?.verified === false);
    const isVerificationSuccess =
      typeof proveResult.businessVerifyResponse?.status === 'number' &&
      (proveResult.businessVerifyResponse?.status === 0 || proveResult.businessVerifyResponse?.verified === true);
    const isBusinessIdentityPrefilled = proveResult.isBusinessPrefilled;
    this.businessIdentities = proveResult.businessIdentities ?? [];
    const promptBeneficialOwnerOnboarding = this.shouldPromptBeneficialOwnerOnboarding();
    if (user.organization?.verificationStatus === OrganizationVerificationStatus.Pending) {
      this.isVerificationSucceeded = true;
      this.verificationStep = VerificationStep.VerificationResult;
    } else if (promptBeneficialOwnerOnboarding) {
      this.verificationStep = VerificationStep.VerificationBeneficialOwner;
    } else if (isVerificationFailed) {
      this.isVerificationSucceeded = false;
      this.verificationStep = VerificationStep.VerificationResult;
    } else if (isVerificationSuccess) {
      this.isVerificationSucceeded = true;
      this.verificationStep = VerificationStep.VerificationResult;
    } else if (isBusinessIdentityPrefilled) {
      if (this.hasBusinessIdentities) {
        this.verificationStep = VerificationStep.VerificationReview;
        this.initIdentityForm(proveResult);
        this.isAutofilled = true;
      } else {
        this.verificationStep = VerificationStep.VerificationEdit;
        this.showManualEntryError = true;
        this.isManualEntry = true;
      }
    } else {
      this.verificationStep = VerificationStep.VerificationStart;
    }
    this.onboardingService.setVerifyBusinessModalVisibility(true);
  }

  onBack() {
    if (this.verificationStep === VerificationStep.VerificationEdit) {
      this.verificationStep = VerificationStep.VerificationReview;
    }
  }

  initIdentityForm(proveResult: ProveBusinessVerificationModel) {
    this.businessIdentities = proveResult.businessIdentities;
    if (this.hasBusinessIdentities) {
      this.initBusinessIdentityForm(this.businessIdentities[0]);
    }
  }

  initBusinessIdentityForm(identity: BusinessIdentity) {
    const { address, city, postalCode, region } = identity.businessAddress;
    const getFilingDate = () => {
      if (identity.registrationFiling?.filingDate) {
        const [year, month, day] = identity.registrationFiling?.filingDate?.split('-');
        return {
          year: Number(year),
          month: Number(month),
          day: Number(day),
        };
      }
      return null;
    };
    this.initialBusinessIdentityForm = {
      businessName: identity.businessName,
      address,
      city,
      postalCode,
      region: region,
      taxId: identity.taxId,
      registrationType: identity?.registrationFiling?.registrationType,
      filingDate: getFilingDate(),
    };
    this.businessIdentityForm.patchValue(
      {
        businessName: identity.businessName,
        address,
        city,
        postalCode,
        region,
        taxId: identity.taxId,
        currentBusinessId: identity.id,
        registrationType: identity?.registrationFiling?.registrationType,
        filingDate: getFilingDate(),
      },
      {
        emitEvent: false,
      }
    );
  }

  get hasBusinessIdentities() {
    return this.businessIdentities && this.businessIdentities.length > 0;
  }

  isFormUnChanged() {
    if (!this.initialBusinessIdentityForm) {
      return false;
    }
    return isEqual(
      this.initialBusinessIdentityForm,
      omit(this.businessIdentityForm.value, 'currentBusinessId', 'licenseNumber')
    );
  }

  get selectedBusiness() {
    return this.businessIdentities?.find((b) => b.id === this.businessIdentityForm.value.currentBusinessId);
  }

  handleBeneficialOwnersResponse(hasBeneficialOwners: boolean) {
    this.organizationService
      .setOrganizationBeneficialOwnersPrompt({
        hasBeneficialOwners,
      })
      .subscribe({
        next: () => {
          this.notificationService.notification('success', 'Business information updated successfully');
          this.usersService.getCurrentProfile().subscribe();
          this.closeModal();
          if (hasBeneficialOwners) {
            this.router.navigate(['/business-account/beneficial-owners']);
          }
        },
      });
  }
}
