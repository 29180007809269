<app-modal #modal [modalConfig]="modalConfig">
  <div class="header mb-4">Ask {{ config?.sellerInfo?.userDetails?.firstName }} a question</div>
  <div class="body">
    <form [formGroup]="form">
      <textarea
        type="text"
        class="form-control text-start mt-3"
        formControlName="msg"
        placeholder="Write a message"
      ></textarea>
      <button [disabled]="!form.valid" class="btn btn-primary mt-3" (click)="sendMessage()">Message seller</button>
    </form>
  </div>
</app-modal>
