<app-modal #modal [modalConfig]="modalConfig">
  <div class="pb-2 set-display-name-title">Set your display name:</div>

  <div class="profile-image d-flex align-items-center">
    @if (!imageLoading) {
      <app-profile-photo
        [src]="photo"
        fallbackSize="60"
        size="60"
        [profileName]="user?.userDetails?.nickname"
      ></app-profile-photo>
    }
    @if (imageLoading) {
      <div class="spinner-border me-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    }
    @if (f.nickname.value) {
      <div class="ms-2">
        <div>{{ f.nickname.value }} {{ user?.userDetails.lastName?.charAt(0).toUpperCase() }}</div>
        <a (click)="fileUpload.click()" class="add-a-photo">
          Add a photo
          <input
            type="file"
            accept="image/*,.heic,.heif"
            id="photo"
            class="d-none"
            (change)="onFileSelect($event)"
            #fileUpload
          />
        </a>
      </div>
    }
    @if (!f.nickname.value) {
      <div class="ms-2">
        <div class="blank-line"></div>
      </div>
    }
  </div>

  <div class="py-2 display-name">Display name</div>

  <div>
    <form [formGroup]="form">
      <input class="form-control" formControlName="nickname" placeholder="Create a display name" />
    </form>

    <div class="py-3 public">This name will be public for others to see</div>
    <button class="btn btn-primary w-100" (click)="onSave()">Save</button>
  </div>
</app-modal>
