<div>
  <div class="modal-header">
    <img src="/assets/logo.svg" alt="private-auto" />
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    @if (fundsDenied) {
      <div class="title mb-4">Add funds to {{ appName }} Pay</div>
    }
    @if (fundsDenied) {
      <div class="main-text">
        This seller has requested verified funds to make an offer or schedule a test drive. Please add funds, apply for
        a loan or message the seller for more details.
      </div>
    }
    @if (verificationsDenied) {
      <div class="title mb-4">To proceed, get fully verified</div>
    }
    @if (verificationsDenied) {
      <div class="main-text">
        This seller has requested only to be contacted by fully verified buyers. Please complete the verification
        process to contact this seller.
      </div>
    }
    @if (verificationsDenied) {
      <button routerLink="/account" class="btn-primary w-100 my-2" (click)="activeModal.dismiss()">
        Complete profile
      </button>
    }
    @if (fundsDenied) {
      <button routerLink="/account/money" class="btn-primary w-100 my-2" (click)="activeModal.dismiss()">
        Go to money page
      </button>
    }
    <button class="btn-gray w-100 my-1" (click)="activeModal.dismiss()">I'll do this later</button>
  </div>
</div>
