import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VerificationService } from 'src/app/core/services/verification.service';
import { Subscription, interval } from 'rxjs';
import { PosthogService } from 'src/app/core/services/posthog.service';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';
import { UploadService, UsersService } from '../../core/services';
import { ISellerListingStatuses, ISellerListingWithUser } from '../../models';

interface Countdown {
  days: number;
  minutes: number;
  hours: number;
  seconds: number;
}

@Component({
  selector: 'app-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss'],
})
export class ListingCardComponent implements OnInit {
  @Input() listing: ISellerListingWithUser;
  favIds: string[];
  listingStatuses = ISellerListingStatuses;
  cardPhotoSize = '357x0';
  isMobileView = false;
  isVerified = false;
  today: Date;
  favImage = 'fav-heart.svg';
  isFav: boolean;
  isPartiallyVerified = false;
  isListingAuctionEnabled = false;
  biddingUpcoming = false;
  biddingStarted = false;

  countdown: Countdown;
  subscription: Subscription;
  percentComplete: number = 0;

  checkIntervalSubscription: Subscription;

  externalSourceSite = '';
  constructor(
    private readonly usersService: UsersService,
    private readonly uploadService: UploadService,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly verificationService: VerificationService,
    private readonly postHogService: PosthogService,
  ) {}

  fav() {
    this.usersService.processFav(this.listing._id);
  }

  setupCountdownCheck() {
    // biddingStartAt
    const checkInterval = 1000;
    this.checkIntervalSubscription = interval(checkInterval).subscribe(() => {
      this.today = new Date();
      const biddingEndAtDate = new Date(this.listing.auction?.active?.biddingEndAt);
      const timeDiff = biddingEndAtDate.getTime() - this.today.getTime();
      const days = Math.round(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.round((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.round((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.round((timeDiff % (1000 * 60)) / 1000);
      this.countdown = { days, hours, minutes, seconds };
      if (timeDiff <= 4 * 60 * 1000 && timeDiff >= 0) {
        this.checkIntervalSubscription.unsubscribe();
        this.startCountdown();
      } else {
      }
    });
  }

  startCountdown() {
    const intervalPeriod = 100;
    this.subscription = interval(intervalPeriod)
      .pipe()
      .subscribe(
        () => {
          this.countdown.seconds -= 0.1;
          if (this.countdown.seconds < 0) this.countdown.seconds = 0;
          this.percentComplete = ((60 - this.countdown.seconds) / 60) * 100;
        },
        () => {},
        () => {
          // Perform any action after countdown completes
        },
      );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.checkIntervalSubscription) {
      this.checkIntervalSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.listing.auction?.active?.biddingEndAt) {
      const today = new Date();

      const biddingStartAt = new Date(this.listing.auction?.active?.biddingStartAt);
      const biddingEndAt = new Date(this.listing.auction?.active?.biddingEndAt);

      if (today > biddingStartAt && today < biddingEndAt) {
        this.biddingStarted = true;
        this.setupCountdownCheck();
      } else if (today < biddingStartAt) {
        this.biddingUpcoming = true;
      }
    }

    this.isListingAuctionEnabled = this.postHogService.posthog.isFeatureEnabled(
      PostHogFeatureFlags.ListingAuctionsEnabled,
    );

    this.externalSourceSite = this.listing?.originalSourceInfo?.sourceSite;
    this.isMobileView = this.deviceDetectorService.isMobile();
    const verified = this.verificationService.getVerificationDetails(this.listing?.user);
    this.isVerified = verified?.verification?.isVerified;
    const { isEmailVerified, isPhoneVerified, isLicenseVerified } = verified.verification;
    this.isPartiallyVerified = isEmailVerified && isPhoneVerified && isLicenseVerified;

    this.usersService.favorites.subscribe((favorites) => {
      if (favorites) {
        this.favIds = favorites.map((fav) => fav?._id);
        this.isFav = this.favIds.indexOf(this.listing?._id) !== -1;
        this.favImage = this.isFav ? 'fav-active.svg' : 'fav-heart.svg';
      }
    });

    this.replaceDimentions();
  }

  replaceDimentions() {
    this.listing?.uploadImages.forEach((picture) => {
      picture.images = this.uploadService.makeImageSize(picture.images, this.cardPhotoSize);
    });
  }

  hover($event: MouseEvent) {
    this.favImage = $event.type === 'mouseover' || this.isFav ? 'fav-active.svg' : 'fav-heart.svg';
  }

  getOrdinal(dateInput: string | Date): string {
    const date = new Date(dateInput);
    const day = date.getDate(); // Extracts the day as a number

    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
