<div class="listing-card d-flex flex-column justify-content-between">
  <div class="listing-header round">
    <span class="image-gradient">
      @if (listing?.status === listingStatuses.Sold) {
        <span class="badge-sold float-start">sold</span>
      }
      @if (listing.hasPendingDeal && listing?.status !== listingStatuses.Sold) {
        <div class="badge-pending float-start d-flex align-items-center justify-content-center">Pending</div>
      }
      @if (listing?.status) {
        <span (click)="fav()" (mouseout)="hover($event)" (mouseover)="hover($event)" class="img-card-fav">
          <em>
            <img alt="fav" class="fav" src="/assets/listing/{{ favImage }}" />
          </em>
        </span>
      }
    </span>
  </div>
  <div class="listing-img position-relative">
    <a [routerLink]="['/listing', listing?.slug]">
      <img alt="{{ listing?.CarModel }}" class="w-100" src="{{ listing?.uploadImages[0]?.images }}" />
    </a>
    @if (biddingStarted && isListingAuctionEnabled) {
      <div
        class="auction px-3 d-flex justify-content-start align-items-center text-white"
        [ngStyle]="
          countdown?.hours <= 3 && countdown?.days === 0
            ? {
                background:
                  'linear-gradient(to left, #283134 ' + percentComplete + '%, #1BB889 ' + percentComplete + '%)',
              }
            : {}
        "
        [ngClass]="{ 'auction-dark-bg': countdown?.hours > 3 || countdown?.days > 0 }"
      >
        @if (countdown?.days < 1) {
          <div class="d-flex">
            <img alt="clock" src="assets/timer.svg" />
            <div class="ps-2">
              {{ countdown?.hours | number: '1.0-0' }}h {{ countdown?.minutes | number: '1.0-0' }}m
              {{ countdown?.seconds | number: '1.0-0' }}s
            </div>
          </div>
        }
        @if (countdown?.days >= 1) {
          <div class="d-flex">
            <div class="ps-2">TimeLeft: {{ countdown?.days }} days</div>
          </div>
        }
        <div class="ps-3">Bid: $90,0000</div>
      </div>
    }
    @if (biddingUpcoming && isListingAuctionEnabled) {
      <div class="auction px-3 d-flex justify-content-center align-items-center auction-dark-bg text-white">
        Auction bidding starts {{ listing?.auction?.active?.biddingStartAt | date: 'MMM dd'
        }}{{ getOrdinal(listing?.auction?.active?.biddingStartAt) }}
      </div>
    }
  </div>
  <div [routerLink]="['/listing', listing?.slug]" class="listing-data px-3 py-lg-3 py-2">
    <div [ngClass]="{ 'font-15': listing?.CarMake.length >= 12 }" class="me-1 year-make-model">
      {{ listing?.RegistrationYear }} {{ listing?.CarMake | slice: 0 : 13 }} {{ listing?.CarModel }}
    </div>
    <div class="my-lg-1">{{ listing.Trim | slice: 0 : 12 }} • {{ listing?.Mileage | number }} miles</div>
    <div class="type-miles-location-name">
      {{ listing?.listingLocation ? (listing?.listingLocation | parseAddress) : 'N/A' }}
    </div>
    <div class="d-flex justify-content-between mt-auto px-0">
      @if (!listing.auction?.active?.biddingEndAt) {
        <div class="price pt-lg-2 pt-1">{{ listing?.Price | currency: 'USD' : '$' : '1.0-0' }}</div>
      }
      <div class="d-flex align-items-center">
        @if (
          (listing.auction?.active?.reserve <= 0 || !listing.auction?.active?.reserve) &&
          listing.auction?.active &&
          isListingAuctionEnabled
        ) {
          <div class="auction-badge mt-lg-2 mt-1 d-flex justify-content-center align-items-center">NO RESERVE</div>
        }
        @if (listing?.auction?.active && isListingAuctionEnabled && biddingStarted) {
          <div class="pt-2 ps-2">Ends ({{ listing?.auction?.active?.biddingEndAt | date: 'EEE dd, hh:mm a' }})</div>
        }
      </div>

      <div
        [ngClass]="{
          'wide-12': listing.listingLocation?.city?.length + listing.listingLocation?.state?.length > 14,
          'wide-more': listing.listingLocation?.city?.length + listing.listingLocation?.city?.length > 18,
          'off-site-spacing': externalSourceSite,
        }"
        class="profile-address d-flex"
      >
        @if (!externalSourceSite) {
          <div class="position-relative me-2 profile-pic">
            <app-profile-photo
              [src]="listing?.user?.userDetails?.profileImage"
              [profileName]="listing?.user?.userDetails?.shortName"
              size="35"
            ></app-profile-photo>
            @if (isVerified) {
              <img
                src="assets/fully-verified.svg
          "
                class="profile-icon position-absolute"
                alt="profile verification indicator"
                data-verified-status="fully-verified"
              />
            }
            @if (!isVerified) {
              <img
                [src]="isPartiallyVerified ? 'assets/verified.svg' : 'assets/not-verified.svg'"
                class="profile-icon position-absolute"
                alt="profile verification indicator"
                [attr.data-verified-status]="isPartiallyVerified ? 'partially-verified' : 'not-verified'"
              />
            }
          </div>
        }
        @if (externalSourceSite) {
          <div class="position-relative me-2 profile-pic">
            <div class="off-site-badge mt-lg-2 mt-1 d-flex justify-content-center align-items-center">
              Off-site listing
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
