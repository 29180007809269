import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChatKittyService, DealsService, NotificationService } from 'src/app/core/services';
import { IDeal } from 'src/app/deals/deals.models';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

export interface DuplicateDeal {
  deal: IDeal;
  otherUser: {
    name: string;
  };
}

@Component({
  selector: 'app-modal-duplicate-deal',
  templateUrl: './modal-duplicate-deal.component.html',
})
export class ModalDuplicateDealComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<any>();

  @Output() onDuplicateCheckValid = new EventEmitter<any>();

  @Output() onCreateNewDeal = new EventEmitter<DuplicateDeal>();

  @Output() onViewDeal = new EventEmitter<any>();
  @Output() onDuplicateResult = new EventEmitter<any>();

  duplicateDealData: DuplicateDeal;

  modalConfig: ModalConfig = {};

  constructor(
    private readonly chatKittyService: ChatKittyService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly dealService: DealsService,
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Duplicate Deal Modal',
      trackevent: false,
      hideLogo: false,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  getDuplicateDealDescription() {
    const { duplicateDealData } = this;
    if (!duplicateDealData) {
      return '';
    }
    const { deal, otherUser } = duplicateDealData;
    return `You have already started a deal with ${otherUser.name} on a ${[
      deal.listing.RegistrationYear,
      deal.listing.CarMake,
      deal.listing.CarModel,
    ].join(' ')}`;
  }

  viewDuplicateDeal() {
    this.modalComponent.close();
    this.router.navigate([`/deals`, this.duplicateDealData.deal._id]);
    this.onViewDeal?.emit();
  }

  createNewDeal() {
    this.dealService.cancelDeal(this.duplicateDealData.deal._id).subscribe(() => {
      const message = '{{user}} canceled the deal.';
      this.chatKittyService.sendDealMessage(this.duplicateDealData.deal, message).subscribe();
      this.notificationService.notification('success', 'Successfully canceled the previous deal.');
      this.modalComponent.close();
      this.onCreateNewDeal?.emit(this.duplicateDealData);
    });
  }

  checkForDuplicateDealByListingId(listingId: string) {
    this.dealService.getDuplicateDeal(listingId).subscribe({
      next: (response: any) => {
        if (response.deal) {
          this.duplicateDealData = response;
          this.modalComponent.open();
          this.onDuplicateResult?.emit();
        } else {
          // no dupes found
          this.onDuplicateCheckValid.emit();
        }
      },
      error: () => {
        // no dupes found
        this.onDuplicateCheckValid.emit();
      },
    });
  }

  checkForDuplicateDealByListingDetails(year: number, make: string, model: string, vin?: string) {
    this.dealService.getDuplicateDealByListingDetails(year, make, model, vin).subscribe({
      next: (response: any) => {
        if (response.deal) {
          this.duplicateDealData = response;
          this.modalComponent.open();
          this.onDuplicateResult?.emit();
        } else {
          // no dupes found
          this.onDuplicateCheckValid.emit();
        }
      },
      error: () => {
        // no dupes found
        this.onDuplicateCheckValid.emit();
      },
    });
  }
}
