import { Component, EventEmitter, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService } from 'src/app/core/services';

@AutoUnsubscribe()
@Component({
  selector: 'app-skip-verification-confirmation',
  templateUrl: './skip-verification-confirmation.component.html',
  providers: [],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class SkipVerificationConfirmation implements OnInit, OnDestroy {
  @Output() onVerify = new EventEmitter();
  @Output() onSkipToManualEntry = new EventEmitter();
  @Output() onClose = new EventEmitter();

  submitted = false;

  constructor(private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
