<div style="display: flex; flex-direction: column; gap: 16px">
  <div class="subtitle left">Business account for:</div>
  <form [formGroup]="form" class="d-flex flex-column gap-3">
    <div>
      <div class="w-100">
        <div class="input-label">Business account</div>
        <app-form-dropdown-select
          formControlName="currentBusinessId"
          placeholder="Select business"
          [options]="getBusinessOptions()"
          (selectionChange)="onChangeSelectedBusiness($event)"
          [isInvalid]="submitted && f.currentBusinessId.errors"
        ></app-form-dropdown-select>
      </div>
    </div>
    <div>
      <label for="licenseNumber">Dealer ID (License #)</label>
      <input
        id="licenseNumber"
        type="text"
        formControlName="licenseNumber"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.licenseNumber.errors }"
      />
      @if (submitted && f.licenseNumber.errors) {
      <div class="invalid-feedback">
        <div>Dealer ID is required</div>
      </div>
      }
    </div>
    <div>
      <div class="w-100">
        <div class="input-label">Business classification</div>
        <app-form-dropdown-select
          formControlName="businessClassification"
          placeholder="Select classification"
          [options]="getClassificationOptions()"
          [isInvalid]="submitted && f.businessClassification.errors"
        ></app-form-dropdown-select>
        @if (submitted && f.businessClassification.errors) {
        <div class="invalid-feedback" style="display: block">
          <div>Classification is required</div>
        </div>
        }
      </div>
    </div>
  </form>
  <div class="info-read-only">
    <div class="d-flex flex-column">
      <div class="key">Business name</div>
      <div class="value">{{ selectedBusiness.businessName }}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Business address</div>
      <div class="value">{{ businessFullAddress }}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Filing date</div>
      @if (selectedBusiness.registrationFiling?.filingDate) {
      <div class="value">{{ selectedBusiness.registrationFiling.filingDate | date : 'MMM d, y' }}</div>
      } @else {
      <div class="value">-</div>
      }
    </div>
    <div class="d-flex flex-column" style="flex: 1">
      <div class="key">EIN</div>
      <div class="value">{{ selectedBusiness.taxId }}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Registered address</div>
      <div class="value">{{ registrationFullAddress }}</div>
    </div>
    <div (click)="onEdit.emit()" class="edit-information" role="button">Edit information</div>
  </div>
  <div class="w-full d-flex flex-column align-items-center">
    <button (click)="onVerify()" class="btn btn-primary w-100">Confirm Information</button>
  </div>
</div>
