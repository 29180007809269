import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { DocumentItem } from 'src/app/account/documents/documents.component';
import { Subscription, switchMap } from 'rxjs';
import { DocumentsUsersItem, DocumentsUsersService } from 'src/app/core/services/documents-user.service';
import { Utils } from 'src/app/shared/utils';
import { DocumentViewerConfig } from '../document-viewer/document-viewer.component';
import { NotificationService, UploadService } from 'src/app/core/services';

@Component({
  selector: 'app-modal-chat-docs',
  templateUrl: './modal-chat-docs.component.html',
  styleUrl: './modal-chat-docs.component.scss',
})
export class ModalChatDocsComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() userId: string;
  modalConfig: ModalConfig;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onSelectDocument = new EventEmitter<DocumentItem>();

  documents: DocumentItem[] = [];
  loadDocuments$: Subscription;
  documentViewerConfig: DocumentViewerConfig;
  showDocumentViewer: boolean;
  loading = false;

  constructor(
    private readonly docsService: DocumentsUsersService,
    private readonly notificationService: NotificationService,
    private readonly uploadService: UploadService,
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Chat Docs Modal',
      trackevent: true,
      hideLogo: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
      this.loadDocuments();
    }
  }

  loadDocuments() {
    this.loading = true;
    this.loadDocuments$ = this.docsService.getAllDocs().subscribe((docs) => {
      this.documents = this.formatDocuments(docs);
      this.loading = false;
    });
  }

  formatDocuments(docs: DocumentsUsersItem[]): DocumentItem[] {
    return docs.map((doc) => {
      const temporaryName = doc.name || doc.filename;
      const viewable = Utils.isDocumentViewable(doc.filename);

      return {
        ...doc,
        viewable,
        temporaryName,
        editable: false,
        editing: false,
      };
    });
  }

  onDocumentViewClick(doc: DocumentItem) {
    const { key, name, filename } = doc;
    const fileType = Utils.getFileType(filename);
    this.documentViewerConfig = { name, fileType, key };
    this.showDocumentViewer = true;
  }

  shareDocument(doc: DocumentItem) {
    this.onSelectDocument.emit(doc);
    this.modalComponent.close();
  }

  async onDocumentsUpload(event) {
    const { files } = event.target;
    const allowedFiles = await Utils.filterAllowedFiles(files, this.notificationService, this.uploadService);
    if (!allowedFiles.length) {
      return;
    }

    const dir = `users/${this.userId}/documents/`;

    Utils.onFilesUpload(allowedFiles, dir, this.uploadService)
      .pipe(
        switchMap((res) => {
          const documents = res.files.map(({ filename, key }) => ({
            filename,
            key,
            name: filename,
            sharedDeals: [],
          }));

          return this.docsService.createDocuments({ documents });
        }),
      )
      .subscribe((res) => {
        this.loadDocuments();

        const notifType = res.success ? 'success' : 'error';
        this.notificationService.notification(notifType, res.message);
      });
  }
}
