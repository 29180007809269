<div>
  @if (showError) {
  <div>
    <div class="subtitle left">Verify your business manually</div>
    <div class="header-error">
      We could not locate any businesses in your legal name. Please fill out these details about your business
    </div>
  </div>
  } @else {
  <div class="subtitle left">Your business information</div>
  <div class="description left">All fields are required unless stated otherwise</div>
  }
  <form [formGroup]="form" (submit)="onVerify()" class="d-flex flex-column" style="margin-top: 24px">
    <div class="form-group">
      <label for="firstName">Business name</label>
      <input
        id="businessName"
        type="text"
        formControlName="businessName"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.businessName.errors }"
      />
      @if (submitted && f.businessName.errors) {
      <div class="invalid-feedback">
        <div>Business name is required</div>
      </div>
      }
    </div>
    <div class="form-group">
      <label for="licenseNumber">Dealer ID (License #)</label>
      <input
        id="licenseNumber"
        type="text"
        formControlName="licenseNumber"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.licenseNumber.errors }"
      />
      @if (submitted && f.licenseNumber.errors) {
      <div class="invalid-feedback">
        <div>Dealer ID is required</div>
      </div>
      }
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="birthDate">Filing Date</label>
      <input
        class="form-control"
        name="dp"
        placeholder="MM/DD/YYYY"
        ngbDatepicker
        formControlName="filingDate"
        dateMask
        [ngClass]="{ 'is-invalid': submitted && f.filingDate.errors }"
        inputmode="numeric"
      />
      @if (submitted && f.filingDate.errors) {
      <div class="invalid-feedback">
        <div>{{ f.filingDate.errors.message || 'Filing Date required' }}</div>
      </div>
      }
    </div>
    <div class="form-group">
      <label htmlFor="registration-type">Registration Type</label>
      <app-form-dropdown-select
        formControlName="registrationType"
        placeholder="Select registration type"
        [options]="businessTypes"
        [isInvalid]="submitted && f.registrationType.errors"
      ></app-form-dropdown-select>
      @if (submitted && f.registrationType.errors) {
      <div class="invalid-feedback" style="display: block">
        <div>Registration type is required</div>
      </div>
      }
    </div>
    <div class="form-group">
      <label for="postal">EIN</label>
      <input
        id="taxId"
        type="text"
        class="form-control"
        formControlName="taxId"
        placeholder="00-0000000"
        mask="00-0000000"
        [ngClass]="{ 'is-invalid': submitted && f.taxId.errors }"
      />
      @if (submitted && f.taxId.errors) {
      <div class="invalid-feedback">
        <div>EIN is required</div>
      </div>
      }
    </div>
    <div class="form-group">
      <label htmlFor="business-classification">Business classification</label>
      <app-form-dropdown-select
        formControlName="businessClassification"
        placeholder="Select classification"
        [options]="getClassificationOptions()"
        [isInvalid]="submitted && f.businessClassification.errors"
      ></app-form-dropdown-select>
      @if (submitted && f.businessClassification.errors) {
      <div class="invalid-feedback" style="display: block">
        <div>Classification is required</div>
      </div>
      }
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="firstName">Address Line 1</label>
      <app-places-autocomplete-input
        placeholder="Enter full address"
        elementId="location"
        #autocompleteInput
        (onChangeEvent)="updateLocation($event)"
        [(inputLocation)]="location"
        (inputChanged)="onAddressValueChange($event)"
      ></app-places-autocomplete-input>
      @if (submitted && f.address.errors) {
      <div class="invalid-feedback" style="display: block">
        <div>Address Line 1 is required</div>
      </div>
      }
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="firstName">Address Line 2</label>
      <input
        id="extendedAddress"
        type="text"
        formControlName="extendedAddress"
        class="form-control"
        placeholder="Apt, suite, unit, building, floor, etc."
      />
    </div>
    <div>
      <div class="d-flex" style="gap: 8px">
        <div class="form-group mb-3" style="flex: 1">
          <label class="form-label" for="city">City</label>
          <input
            id="city"
            type="text"
            formControlName="city"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
          />
          @if (submitted && f.city.errors) {
          <div class="invalid-feedback">
            <div>City is required</div>
          </div>
          }
        </div>
        <div class="form-group mb-3" style="flex: 1">
          <label class="form-label" for="region">State</label>
          <select
            formControlName="region"
            class="form-control form-control-select"
            [ngClass]="{ 'is-invalid': submitted && f.region.errors }"
          >
            @for (state of states; track state) {
            <option [value]="state.abbreviation">{{ state.abbreviation }}</option>
            }
          </select>
          @if (submitted && f.region.errors) {
          <div class="invalid-feedback">
            <div>State is required</div>
          </div>
          }
        </div>
      </div>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="postal">Zip code</label>
      <input
        id="postal"
        type="text"
        class="form-control"
        formControlName="postalCode"
        [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }"
      />
      @if (submitted && f.postalCode.errors) {
      <div class="invalid-feedback">
        <div>Zip code is required</div>
      </div>
      }
    </div>
    <button type="submit" class="btn btn-primary w-100">Submit for review</button>
  </form>
</div>
