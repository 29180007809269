import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoanService } from 'src/app/core/services';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

@Component({
  selector: 'app-modal-leave-loan',
  templateUrl: './modal-leave-loan.component.html',
  styleUrls: ['./modal-leave-loan.component.scss'],
})
export class ModalLeaveLoanComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any[]>();
  @Output() saveLoanApplication = new EventEmitter<any>();

  modalConfig: ModalConfig = {};
  text: string;
  isSaveButtonClicked = false;

  constructor(
    private readonly router: Router,
    private readonly loanService: LoanService,
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit(this.isSaveButtonClicked);
        this.isSaveButtonClicked = false;
        return true;
      },
    };
    this.text = 'Do you want to save your loan application to not lose any info you added or changed.';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;
    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  saveLoan() {
    this.isSaveButtonClicked = true;
    this.saveLoanApplication.emit(true);
    this.modalComponent.close();
  }

  noThanks() {
    this.isSaveButtonClicked = true;
    this.loanService.showLeavePageModal.subscribe((value) => {
      this.router.navigate([value.route]);
      this.modalComponent.close();
      this.loanService.setShowLeavePageModal({ show: false, route: null });
    });
  }
}
