import { Directive, HostListener, ElementRef, Input, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[formatCurrencyInput]',
  providers: [CurrencyPipe],
})
export class FormatCurrencyInputDirective implements OnInit {
  @Input('inputControl') inputControl: AbstractControl;

  constructor(
    private readonly element: ElementRef,
    private readonly currencyPipe: CurrencyPipe,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.element.nativeElement.value = this.transform(this.element.nativeElement.value);
    }, 0);

    this.inputControl.valueChanges.subscribe((val) => {
      if (document.activeElement !== this.element.nativeElement) {
        this.element.nativeElement.value = this.transform(val);
      }
    });
  }

  @HostListener('focus')
  OnFocus() {
    this.element.nativeElement.value = this.inputControl.value;
  }

  @HostListener('blur')
  OnBlur() {
    if (this.inputControl.valid) {
      this.element.nativeElement.value = this.transform(this.element.nativeElement.value);
    }
  }

  transform(value: string | number) {
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0');
  }
}
