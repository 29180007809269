import { Component, Input } from '@angular/core';

export enum AlertType {
  warning = 'warning',
  error = 'error',
  info = 'info',
}

@Component({
  selector: 'app-inline-alert',
  templateUrl: './inline-alert.component.html',
  styleUrls: ['./inline-alert.component.scss'],
})
export class InlineAlertComponent {
  @Input() type: AlertType;
  @Input() message: string;

  get alertClass() {
    switch (this.type) {
      case AlertType.warning:
        return 'warning';
      case AlertType.error:
        return 'error';
      case AlertType.info:
        return 'info';
      default:
        return '';
    }
  }
}
