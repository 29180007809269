import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthorizationService } from '../services/authorizaton.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
      // Wait for the ability to be initialized
      await this.authorizationService.waitForAbilityInitialization();

      const requiredPermission = route.data['requiredPermission'];

      if (!requiredPermission) {
        return true;
      }

      const { action, resource } = requiredPermission;

      if (this.authorizationService.can(action, resource)) {
        return true;
      } else {
        this.router.navigate(['/account']);
        return false;
      }
    } catch (error) {
      this.router.navigate(['/account']);
      return false;
    }
  }
}
