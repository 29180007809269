<div class="location-input">
  <input
    [placeholder]="placeholder"
    #searchInput
    class="d-inline-block form-control"
    type="text"
    [id]="elementId"
    [name]="elementId"
    (input)="onInputChange($event)"
  />
</div>
