<!-- address-form.component.html -->
<div class="address-form">
  <div class="mx-0">
    <div class="input-label">Address</div>
    <input
      class="form-control mt-1"
      [ngClass]="{ 'is-invalid': errors.address }"
      placeholder="Address"
      type="text"
      [(ngModel)]="address.address"
      (blur)="onInputBlur()"
      name="address"
      (ngModelChange)="onModelChange('address', $event)"
    />
    @if (errors.address) {
      <div id="address-error" class="invalid-feedback">
        {{ errors.address }}
      </div>
    }
  </div>
  <div class="mx-0">
    <div class="input-label">City</div>
    <input
      class="form-control mt-1"
      [ngClass]="{ 'is-invalid': errors.city }"
      type="text"
      [(ngModel)]="address.city"
      (ngModelChange)="onModelChange('city', $event)"
      (blur)="onInputBlur()"
      name="city"
    />
    @if (errors.city) {
      <div id="city-error" class="invalid-feedback">
        {{ errors.city }}
      </div>
    }
  </div>
  <div class="state-zip">
    <div class="mx-0">
      <div class="input-label">State</div>
      <select
        [ngClass]="{ 'is-invalid': errors.state }"
        class="state form-control form-control-select mt-1"
        [(ngModel)]="address.state"
        (blur)="onInputBlur()"
        (ngModelChange)="onModelChange('state', $event)"
        name="state"
      >
        <option [ngValue]="''">Select State</option>
        @for (option of stateOptions; track option) {
          <option [value]="option">{{ option }}</option>
        }
      </select>
      @if (errors.state) {
        <div id="state-error" class="invalid-feedback">
          {{ errors.state }}
        </div>
      }
    </div>
    <div class="mx-0">
      <div class="input-label">Zip</div>
      <input
        [ngClass]="{ 'is-invalid': errors.zipcode }"
        (ngModelChange)="onModelChange('zipcode', $event)"
        class="form-control mt-1"
        type="text"
        [(ngModel)]="address.zipcode"
        (blur)="onInputBlur()"
        name="zipcode"
      />
      @if (errors.zipcode) {
        <div id="zipcode-error" class="invalid-feedback">
          {{ errors.zipcode }}
        </div>
      }
    </div>
  </div>
</div>
