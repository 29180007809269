<app-modal #modal [modalConfig]="modalConfig">
  @if (config) {
    <div class="d-flex flex-column height-for-view">
      <div class="document-viewer-header d-flex mx-2 mx-md-4 my-4">
        <div class="d-flex col-4 align-content-center">
          <button (click)="onCloseBtnClick()" class="back-btn">
            <img src="assets/chevron-left-white.svg" alt="" />
          </button>
        </div>
        <div class="d-flex col-4 align-items-center justify-content-center text-center">
          <div class="name align-self-center">{{ config.name | titlecase }}</div>
        </div>
        <div class="d-block d-md-flex align-items-center ms-auto" #headerSlotRef>
          <div
            class="d-flex justify-content-end align-items-center mx-3"
            [ngClass]="{ 'h-100': !hasHeaderSlotContent }"
          >
            <button (click)="onDownloadClick()" class="cta-button">
              <img src="assets/listing/download-white.svg" alt="download" />
            </button>
            @if (!isNative) {
              <button (click)="onPrintClick()" class="cta-button ms-4">
                <img src="assets/computer-printer.svg" alt="print" />
              </button>
            }
          </div>
          <ng-content select="[headerButtons]"></ng-content>
        </div>
      </div>
      <div class="content flex-grow-1 text-center" [ngClass]="{ 'desktop-pdf-padding': !isMobileView }">
        @if (config?.fileType === documentViewerFileTypes.Image) {
          <img [src]="url" [alt]="config?.name" />
        }
        <!-- Handle PDFs -->
        @if (config?.fileType === documentViewerFileTypes.Pdf) {
          <!-- Handle s3 keys -->
          <ngx-extended-pdf-viewer
            [src]="url"
            (pagesLoaded)="onPdfInit()"
            useBrowserLocale="true"
            [showToolbar]="false"
            theme="dark"
            zoom="page-width"
            [minZoom]="100"
            [maxZoom]="100"
            backgroundColor="#0c0e0f"
          ></ngx-extended-pdf-viewer>
        }

        <!-- Handle Platoforms PDF-->
        @if (config?.fileType === documentViewerFileTypes.PlatoformsPdf) {
          @if (useObjectForPdf()) {
            <object (load)="onPdfInit()" [data]="url" type="application/pdf" class="pdf-viewer w-100"></object>
          } @else {
            <iframe (load)="onPdfInit()" [src]="url" type="application/pdf" class="pdf-viewer w-100"></iframe>
          }
        }

        <!-- Handle Platoforms Invite -->
        @if (config?.fileType === documentViewerFileTypes.PlatoformsInvite) {
          <iframe (load)="onPdfInit()" [src]="url" frameborder="0" class="pdf-viewer h-100 w-100"></iframe>
        }
      </div>
      <div class="document-viewer-footer-container flex-shrink- w-100">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  }
</app-modal>

<iframe #iframePrintContainer class="d-none" title="print container"></iframe>
