import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { GonativeService, UsersService } from 'src/app/core/services';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

@Component({
  selector: 'app-modal-session-timeout',
  templateUrl: './modal-session-timeout.component.html',
  styleUrls: ['./modal-session-timeout.component.scss'],
})
export class ModalSessionTimeoutComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();

  modalConfig: ModalConfig = {};
  isSignInBtnClicked = false;
  isNativeApp = false;

  constructor(
    private readonly usersService: UsersService,
    private readonly goNativeService: GonativeService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {}

  ngOnInit(): void {
    this.isNativeApp = this.goNativeService.detectIfNativeApp();
    this.modalConfig = {
      name: 'Session Timeour Modal',
      trackevent: false,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();

        if (!this.isSignInBtnClicked) {
          window.location.href = this.isNativeApp ? `${this.config.appUrl}/auth/login` : this.config.websiteUrl;
        }

        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  signIn() {
    this.isSignInBtnClicked = true;
    window.location.href = this.isNativeApp
      ? `${this.config.appUrl}/auth/login`
      : `${this.config.websiteUrl}/?account=login`;
  }
}
