import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { from, interval, of } from 'rxjs';
import { concatMap, delay, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { AirbrakeErrorHandler } from 'src/app/airbrake-error-handler';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { BankService, NotificationService, SellerListingService, UsersService } from 'src/app/core/services';
import { ISellerListing, IUser, PaymentMethod } from 'src/app/models';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';

import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';

import { ActivatedRoute, Router } from '@angular/router';
import { FacebookService } from 'src/app/core/services/facebook.service';
import { formatListing } from 'src/app/shared/utils-listing';

declare let WoolyAnalytics: any;

type ModalPayAndSetLiveListing = Pick<
  ISellerListing,
  | '_id'
  | 'vehicleType'
  | 'RegistrationYear'
  | 'CarMake'
  | 'CarModel'
  | 'Zip'
  | 'Price'
  | 'Mileage'
  | 'mainImg'
  | 'displayType'
  | 'slug'
  | 'originalSource'
>;

enum PaymentOption {
  Now = 'now',
  Later = 'later',
}

@Component({
  selector: 'app-listing-steps-modal-pay-and-set-live',
  templateUrl: './modal-pay-and-set-live.component.html',
  styleUrls: ['./modal-pay-and-set-live.component.scss'],
})
export class ModalPayAndSetLiveComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  private stripe: Stripe;
  private elements: StripeElements;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onSuccessfulPayment = new EventEmitter<boolean>();
  @Output() onActionEvent = new EventEmitter<string>();

  modalConfig: ModalConfig = {};
  showPricingModal = false;

  @Input() listing: ModalPayAndSetLiveListing;
  @Input() headerText = 'Listing Fee';
  @Input() disablePaymentNow = false;
  @Input() disablePaymentLater = false;
  @Input() isListingNotOwnedByUser = false;
  @Input() fromDeal: boolean;

  @Output() onClose = new EventEmitter<boolean>();

  promoCode: string;
  promoCodeUsed: string;
  paymentMethods: PaymentMethod[] = [];
  discount = 0.0;
  payAmount = 99.0;
  closingFee = 0.0;
  closingFeeDiscount = 0.0;
  payData = {
    promoCode: '',
    nameOnCard: '',
    number: '',
    expDate: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    zipCode: '',
    listingId: '',
  };

  shareVehicleListing: any;

  user: IUser;
  orderId: '';

  paymentOption: PaymentOption;

  hasDiscount = false;
  showSucessUi = false;
  listingProccessingUi = false;
  listingPostedSuccess = false;

  applyPromocodeLoading = false;
  payLaterAmount: number;
  payNowAmount: number;
  payLoading = false;
  features2: {
    text: string;
  }[];

  paymentIntentSecret: string;

  form: UntypedFormGroup;

  readonly stripeInputStyles = {
    base: {
      lineHeight: '1.5',
      fontSize: '1.06rem',
      fontFamily: '"Proxima Nova", Helvetica, Arial, sans-serif',
      color: '#495057',
      fontWeight: 300,
    },
  };

  appName: string;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly userService: UsersService,
    private readonly route: ActivatedRoute,
    private readonly bankService: BankService,
    private readonly fb: UntypedFormBuilder,
    private readonly listingService: SellerListingService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly errorHandler: AirbrakeErrorHandler,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly renderer: Renderer2,
    private readonly facebookService: FacebookService,
    private readonly router: Router,
    private readonly sellerListingService: SellerListingService,
    private el: ElementRef,
  ) {
    this.payNowAmount = this.config.initialFeePrice;
    this.payLaterAmount = this.config.payLaterPrice;
    this.appName = this.config.appName;
  }

  get hasExistingPaymentMethod() {
    return this.paymentMethods.length > 0;
  }

  get paymentDescription() {
    if (!this.paymentOption) {
      return '';
    }
    return this.paymentOption === PaymentOption.Now ? 'Listing fee' : 'Pay Later';
  }

  ngOnInit() {
    this.bankService
      .getPaymentMethods()
      .pipe(take(1))
      .subscribe((response) => {
        this.paymentMethods = response.data;
        if (this.paymentMethods.length) {
          const defaultPaymentMethod = this.paymentMethods.find((e) => e.default) || this.paymentMethods[0];
          this.form.patchValue({ paymentMethod: defaultPaymentMethod.id });
        }
      });

    this.userService.user.subscribe((user) => {
      this.user = user;
    });

    this.modalConfig = {
      name: 'Pay & Set Live Modal',
      modalDialogClass: 'scrollable',
      trackevent: true,
      beforeClose: (isClosedByUser = true) => {
        if (this.payLoading) {
          return false;
        }
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit(isClosedByUser);
        return true;
      },
    };

    this.features2 = [
      {
        text: 'Free renewals',
      },
      {
        text: 'Vehicle history report',
      },
      {
        text: 'Vehicle value report',
      },
      {
        text: 'QR code window brochure',
      },
      {
        text: 'Test drive scheduler',
      },
      {
        text: 'Verified buyer preferences',
      },
      {
        text: 'Secure chat',
      },
    ];

    this.form = this.fb.group({
      paymentMethod: [null, []],
      temporaryPromoCode: [null, []],
    });

    this.loadStripe();
  }

  loadStripe() {
    from(
      loadStripe(this.config.stripeKey, {
        betas: ['process_order_beta_1'],
        apiVersion: '2020-03-02; orders_beta=v3',
      }),
    ).subscribe((stripe) => {
      this.stripe = stripe;
    });
  }

  resetPaymentElement() {
    if (this.paymentOption === PaymentOption.Later) {
      this.bankService
        .setupCardPayment(
          {
            listingId: this.listing._id,
          },
          false,
        )
        .pipe(take(1))
        .subscribe((response) => {
          this.orderId = response.id;
          if (!this.hasExistingPaymentMethod) {
            this.openStripeElements(response.secret);
          }
          this.paymentIntentSecret = response.secret;
        });
    } else {
      this.bankService
        .createStripeSession(
          {
            listingId: this.listing._id,
          },
          false,
        )
        .pipe(take(1))
        .subscribe((response: any) => {
          this.orderId = response.id;
          if (!this.hasExistingPaymentMethod) {
            this.openStripeElements(response.secret);
          }
          this.paymentIntentSecret = response.secret;
        });
    }
  }

  onAddPaymentMethodSuccess(paymentMethod: PaymentMethod) {
    this.paymentMethods = [paymentMethod, ...this.paymentMethods];
    this.form.patchValue({ paymentMethod: paymentMethod.id });
    this.form.markAsDirty();
  }

  onViewPricing() {
    this.showPricingModal = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.hasDiscount = false;
      this.modalComponent.open();
      this.setPaymentNow();
      // this.setDefaultPaymentOption();
    }
  }

  setDefaultPaymentOption() {
    this.paymentOption = null;

    if (this.disablePaymentNow) {
      this.setPaymentLater();
      return;
    }

    if (this.disablePaymentLater) {
      this.setPaymentNow();
    }
  }

  addDiscount() {
    this.hasDiscount = true;
  }

  setPaymentNow() {
    if (this.paymentOption === PaymentOption.Now) {
      return;
    }
    this.payAmount = this.payNowAmount;
    this.closingFee = this.payLaterAmount;
    this.paymentOption = PaymentOption.Now;
    this.deletePromoCodeForm();
    this.resetPaymentElement();
  }

  setPaymentLater() {
    if (this.paymentOption === PaymentOption.Later) {
      return;
    }
    this.payAmount = this.payLaterAmount;
    this.paymentOption = PaymentOption.Later;
    this.deletePromoCodeForm();
    this.resetPaymentElement();
  }

  openStripeElements(secret: string) {
    this.elements = this.stripe.elements({ appearance: { theme: 'stripe' }, clientSecret: secret });
    const paymentElement = this.elements.create('payment', { terms: { card: 'never' } });
    paymentElement.mount('#payment-element');
  }

  applyPromoCode() {
    if (!this.form.value.temporaryPromoCode) {
      return;
    }
    this.applyPromocodeLoading = true;
    let promoCodeRequest;
    if (this.paymentOption === PaymentOption.Now) {
      promoCodeRequest = this.bankService
        .createStripeSession({
          listingId: this.listing._id,
          promoCode: this.form.value.temporaryPromoCode,
          update: true,
          orderId: this.orderId,
        })
        .pipe(take(1))
        .toPromise();
    } else {
      promoCodeRequest = this.bankService
        .setupCardPayment({
          listingId: this.listing._id,
          promoCode: this.form.value.temporaryPromoCode,
          update: true,
          orderId: this.orderId,
        })
        .pipe(take(1))
        .toPromise();
    }
    promoCodeRequest
      .then((response: any) => {
        const { payNow, payLater, secret } = response;
        this.payData.promoCode = this.form.value.temporaryPromoCode;
        this.paymentIntentSecret = secret;

        this.discount = payNow.discount / 100;
        this.closingFeeDiscount = payLater.discount / 100;
        const is100Off = this.payAmount - this.discount === 0;
        // re-initialize stripe elements to change secret
        if (this.paymentOption === PaymentOption.Now && is100Off && !this.hasExistingPaymentMethod) {
          this.openStripeElements(secret);
        }
        this.applyPromocodeLoading = false;
      })
      .catch((error) => {
        this.errorHandler.handleError(error);
        this.notificationService.defaultErrorNotification(error);
        this.applyPromocodeLoading = false;
      });
  }

  deletePromoCode() {
    this.deletePromoCodeForm();
    this.resetPaymentElement();
  }

  deletePromoCodeForm() {
    this.payData.promoCode = '';
    this.discount = 0.0;
    this.closingFeeDiscount = 0.0;
    this.form.patchValue({ temporaryPromoCode: '' });
  }

  pay() {
    this.payData.listingId = this.listing._id;
    this.payLoading = true;

    const is100Off = this.payAmount - this.discount === 0;
    const confirmCallback = async (response) => {
      if (response.error) {
        this.notificationService.notification('error', response.error.message);
        this.payLoading = false;
        this.listingProccessingUi = false;
        return;
      }
      this.facebookService.trackCustomEvent('Payment', { paymentType: this.paymentOption });
      this.getListingAfterPayment(response);
    };

    if (this.paymentOption === PaymentOption.Later && this.hasExistingPaymentMethod) {
      return this.stripe
        .confirmCardSetup(this.paymentIntentSecret, {
          payment_method: this.form.value.paymentMethod,
        })
        .then(confirmCallback);
    }
    if (this.paymentOption === PaymentOption.Later) {
      return this.stripe
        .confirmSetup({
          elements: this.elements,
          redirect: 'if_required',
        })
        .then(confirmCallback);
    }
    if (is100Off && this.paymentOption === PaymentOption.Now && this.hasExistingPaymentMethod) {
      return this.stripe
        .confirmCardSetup(this.paymentIntentSecret, {
          payment_method: this.form.value.paymentMethod,
        })
        .then(confirmCallback);
    }
    if (is100Off && this.paymentOption === PaymentOption.Now) {
      return this.stripe
        .confirmSetup({
          elements: this.elements,
          redirect: 'if_required',
        })
        .then(confirmCallback);
    }
    if (this.paymentOption === PaymentOption.Now && this.hasExistingPaymentMethod) {
      return this.stripe
        .confirmCardPayment(this.paymentIntentSecret, {
          payment_method: this.form.value.paymentMethod,
        })
        .then(confirmCallback);
    }
    return this.stripe
      .confirmPayment({
        elements: this.elements,
        redirect: 'if_required',
      })
      .then(confirmCallback);
  }

  pollListingStatus(listingId: string) {
    return interval(2000).pipe(
      // Poll every 2 seconds
      concatMap(() => this.listingService.getListingStepsById(listingId)),
      takeWhile((response) => response.data.status !== 'live', true),
      take(4), // Stop polling after 4 tries
    );
  }

  getListingAfterPayment(result: any) {
    const is100Off = this.payAmount - this.discount === 0;
    this.sellerListingService.setIsListingFresh(true);
    return of('')
      .pipe(
        delay(1000),
        switchMap(() => {
          const reloadListing$ = this.isListingNotOwnedByUser ? of(null) : this.pollListingStatus(this.listing._id);

          return reloadListing$;
        }),
      )
      .subscribe({
        next: (response: { data: any }) => {
          if (!this.isListingNotOwnedByUser) {
            this.listing = formatListing(response.data);
            this.listingService.updateItemInListingsList(this.listing);
          }

          const payTotalPrice = this.payAmount - this.discount;
          this.googleAnalyticsService.trackReditEvent('Purchase', {
            currency: 'USD',
            itemCount: 1,
            transactionId: this.listing._id,
            value: this.payAmount,
          });
          this.googleAnalyticsService.gaEvent({
            event: 'Sell Your Vehicle',
            vehicleType: this.listing.vehicleType,
            year: this.listing.RegistrationYear,
            make: this.listing.CarMake,
            model: this.listing.CarModel,
            listingPrice: payTotalPrice,
            zipCode: this.listing.Zip,
            priceBand: Math.floor(this.listing.Price / 10000),
            milageBand: Math.floor(this.listing.Mileage / 10000),
            Login: 'post',
            step: 'live',
          });

          if (this.payData.promoCode) {
            this.loadTrackDeskScript();
          }
          if (this.listing?.originalSource) {
            this.googleAnalyticsService.gaEvent({
              event: 'CoBranded Sell Vehicle',
              status: 'live',
              coBrandedName: this.listing.originalSource,
            });
          }

          this.googleAnalyticsService.gaEvent({
            event: 'Set Listing Live',
            actionType: 'live',
          });
          this.payLoading = false;

          this.notificationService.notification('success', 'Payment completed successfully!');
          this.onSuccessfulPayment.emit(true);
          this.modalComponent.close(false);
        },
      });
  }

  loadTrackDeskScript() {
    const script = this.renderer.createElement('script');
    script.src = '//cdn.trackdesk.com/tracking.js';
    script.async = true;
    this.renderer.appendChild(this.el.nativeElement, script);

    script.onload = () => {
      (window as any).TrackdeskObject = (window as any).TrackdeskObject || [];
      let trackdesk = ((window as any).trackdesk =
        (window as any).trackdesk ||
        (window as any).TrackdeskObject.f ||
        function () {
          ((window as any).trackdesk.q = (window as any).trackdesk.q || []).push(arguments);
        });

      trackdesk('privateauto', 'conversion', {
        couponCodes: [this.payData.promoCode],
        revenueOriginId: this.config.externalScripts.trackDeskOriginId,
        conversionType: 'sale',
      });
    };
  }

  chooseOption(option) {
    this.onActionEvent.emit(option);
  }

  get displayTerms() {
    return `By clicking pay you authorize ${this.appName} to store your card and process the fee when the final sale is
    complete.`;
  }
}
