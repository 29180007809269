<form [formGroup]="form">
  <div class="summary">
    <div class="checkout-item d-flex justify-content-between">
      <span style="font-weight: 600">{{ paymentElementConfig?.productTitle }}</span>
      <span>{{ price | currency }}</span>
    </div>
    @if (setPromoCode) {
      <div class="discount d-flex justify-content-between top-row">
        <div>
          <span> Discount </span>
          <span class="discount-hash-tag">
            #{{ setPromoCode }}
            <span class="remove-discount" (click)="deletePromoCode()"> X </span>
          </span>
        </div>
        <div class="discount-value">{{ -1 * discount | currency }}</div>
      </div>
    }
    <div class="divider"></div>
    <div class="d-flex justify-content-between total">
      <span>Total</span>
      <span>{{ price - discount | currency }}</span>
    </div>
  </div>
  @if (showDiscountField) {
    @if (!hasDiscount) {
      <div class="mb-4 ms-3">
        <span (click)="hasDiscount = true" class="have-discount">Have a discount code?</span>
      </div>
    }
    @if (!setPromoCode && hasDiscount) {
      <div class="d-flex align-items-center form-group mb-4">
        <label class="form-label" for="promoCode"></label>
        <input
          type="text"
          class="form-control flex-fill me-sm-2"
          id="promoCode"
          placeholder="Discount code"
          formControlName="temporaryPromoCode"
        />
        @if (applyPromoCodeLoading) {
          <div class="spinner-border me-2" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        }
        @if (!applyPromoCodeLoading) {
          <button type="submit" class="btn btn-outline-primary apply-button" (click)="applyPromoCode()">Apply</button>
        }
      </div>
    }
  }
  <h2 class="payment-title">Payment Method</h2>
  @if (hasExistingPaymentMethod) {
    <div class="mb-4">
      <app-payment-method-selector
        name="paymentMethod"
        formControlName="paymentMethod"
        [paymentMethods]="paymentMethods"
        (onAddSuccess)="onAddPaymentMethodSuccess($event)"
      ></app-payment-method-selector>
    </div>
  }
  @if (!hasExistingPaymentMethod) {
    <div class="mb-3" id="payment-element">
      <!--Stripe.js injects the Payment Element-->
    </div>
  }
  @if (!payLoading) {
    <button (click)="startPayment()" class="w-100 btn btn-primary">{{ paymentElementConfig?.buttonText }}</button>
  }
  @if (payLoading) {
    <div class="w-100 text-center">
      <div class="spinner-border me-2 text-center" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  }
</form>
