import { Component, Inject } from '@angular/core';
import { NotificationService, UsersService } from '../../core/services';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Component({
  selector: 'app-give-feedback',
  templateUrl: './give-feedback.component.html',
  styleUrl: './give-feedback.component.scss',
})
export class GiveFeedbackComponent {
  appName: string;
  constructor(
    private readonly notificationService: NotificationService,
    private readonly usersService: UsersService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.appName = this.config.appName;
  }

  submitFeedback() {
    const feedbackTextarea = document.getElementById('feedback-text') as HTMLTextAreaElement;
    if (feedbackTextarea.value === '') {
      this.notificationService.notification('warning', 'Must enter feedback to submit');
      return;
    }

    this.usersService.submitFeedback(feedbackTextarea.value, this.config.isDealNow).subscribe((res) => {
      this.notificationService.notification('success', 'Successfully submitted feedback');
    });
    feedbackTextarea.value = '';
  }
}
