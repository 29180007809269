<app-modal #modal [modalConfig]="modalConfig">
  @if (vehicleInfoSection) {
    <div class="header font-adjustment">Add the vehicle details if you know them</div>
  }
  @if (offerSection) {
    <div class="header font-adjustment">Awesome! Deal started, let’s continue on the deal!</div>
  }

  <div class="body mt-4">
    <form [formGroup]="form" [ngClass]="{ 'd-none': externalListing?._id || !vehicleInfoSection }">
      <div class="mt-2" [ngClass]="{ 'd-none': f.vehicleType.value !== 'Boat' }">
        <div class="input-label">Year</div>
        <input
          mask="0000"
          class="form-control mt-2"
          type="text"
          formControlName="year"
          autocomplete="off"
          #year="ngbTypeahead"
          [ngbTypeahead]="search"
          (focus)="focusYear$.next($any($event).target.value)"
          (click)="clickYear$.next($any($event).target.value)"
          [ngClass]="{ 'form-control-red': f.year.invalid && formInvalid }"
          (selectItem)="onYearSelected($event)"
        />
      </div>
      <ng-container *ngTemplateOutlet="selectedVehicleTypeTemplate"></ng-container>
      <ng-template #autoTemplate #rvTemplate>
        <div class="my-4 w-100">
          <app-options-slide
            [(selected)]="selectedOption"
            [optionItemLight]="true"
            [options]="inputOptions"
          ></app-options-slide>
        </div>

        @if (selectedOption.value === 'vin') {
          <div class="mt-2 pb-1">
            <div class="input-label">VIN</div>
            <input
              class="form-control mt-1"
              [ngClass]="{ 'vin-error': errorOnVinFilledOut }"
              type="text"
              placeholder="Enter VIN"
              formControlName="vin"
              autocomplete="off"
            />
          </div>
        }
        <div [ngClass]="{ 'd-none': selectedOption.value !== 'manualEntry' }">
          <div class="position-relative mt-3 pb-1">
            <div class="input-label">Year</div>
            <input
              mask="0000"
              class="form-control mt-1"
              type="text"
              formControlName="year"
              autocomplete="off"
              #yearField="ngbTypeahead"
              [ngbTypeahead]="search"
              (focus)="focusYear$.next($any($event).target.value)"
              (click)="clickYear$.next($any($event).target.value)"
              [ngClass]="{ 'form-control-red': f.year.invalid && formInvalid }"
              (selectItem)="onYearSelected($event)"
            />
          </div>
          <div class="mt-2 pb-1">
            <div class="input-label">Make</div>
            <input
              class="form-control mt-1"
              [ngClass]="{ 'form-control-red': f.make.invalid && formInvalid }"
              type="text"
              formControlName="make"
              autocomplete="off"
            />
          </div>
          <div class="mt-2">
            <div class="input-label">Model</div>
            <input
              class="form-control mt-1"
              [ngClass]="{ 'form-control-red': f.model.invalid && formInvalid }"
              type="text"
              formControlName="model"
              autocomplete="off"
            />
          </div>
        </div>
      </ng-template>

      <ng-template #boatTemplate>
        <div class="mt-2 pb-1">
          <div class="input-label">Make</div>
          <input
            class="form-control mt-2"
            [ngClass]="{ 'form-control-red': f.make.invalid && formInvalid }"
            type="text"
            formControlName="make"
            autocomplete="off"
          />
        </div>
        <div class="mt-2">
          <div class="input-label">Model</div>
          <input
            class="form-control mt-2"
            [ngClass]="{ 'form-control-red': f.model.invalid && formInvalid }"
            type="text"
            formControlName="model"
            autocomplete="off"
          />
        </div>
      </ng-template>
    </form>

    @if (offerSection) {
      <div>
        @if (!externalListing?._id) {
          <div class="listing-details-header d-flex align-items-center">
            <div class="listing-image">
              <app-listing-image
                *ngIf="offerSection"
                [src]="f.mainImg.value"
                [vehicleType]="f.vehicleType.value"
                [width]="65.75"
                [height]="52"
              ></app-listing-image>
            </div>
            <div class="listing-details">
              <div class="listing-main-text">{{ f.year.value }} {{ f.make.value }} {{ f.model.value }}</div>
              <div class="listing-sub-text">
                @if (f.vin?.value) {
                  <div class="">VIN: {{ f.vin.value }}</div>
                }
                <a class="add-image" (click)="fileUpload.click()">Add image (optional)</a>
                <input
                  type="file"
                  accept="image/*,.heic,.heif,.jfif"
                  class="d-none"
                  (change)="onFileSelect($event)"
                  multiple
                  #fileUpload
                />
              </div>
            </div>
          </div>
        }
        <form [formGroup]="form">
          <div class="mt-3 pb-1">
            <div class="input-label pb-2">Your offer / Purchaser price</div>
            <input
              mask="separator.2"
              thousandSeparator=","
              class="form-control mt-1"
              type="text"
              formControlName="price"
              autocomplete="off"
              [ngClass]="{ 'form-control-red': f.price.invalid && formInvalid && offerSection }"
            />
          </div>
        </form>
        @if (showGetReportBanner && f.vehicleType.value !== 'Boat') {
          <div class="get-vehicle-report-banner d-flex align-items-start">
            <img class="mt-1" src="assets/todo-yellow.svg" alt="get report" />
            <div class="ms-3">
              <div class="description">Get vehicle history/value report?</div>
              <div class="btn-link" (click)="onServiceReportClick()">Available on Service hub</div>
            </div>
          </div>
        }
        @if (showGetReportBanner && f.vehicleType.value === 'Boat') {
          <div class="get-vehicle-report-banner d-flex align-items-start">
            <img class="mt-1" src="assets/todo-yellow.svg" alt="get report" />
            <div class="ms-3">
              <div class="description">Get boat history report?</div>
              <div class="btn-link" (click)="onBoatServiceReportClick()">Purchase report</div>
            </div>
          </div>
        }

        <div class="subject mt-4">
          <div class="description">Subject to:</div>
          <div class="d-flex mt-1">
            <app-checkbox
              class="subject-checkbox me-1"
              [formGroup]="form"
              name="personalInspection"
              label="Personal inspection"
            ></app-checkbox>
          </div>

          <div class="d-flex mt-1">
            <app-checkbox
              class="subject-checkbox me-1"
              [formGroup]="form"
              name="testDrive"
              label="Test drive"
            ></app-checkbox>
          </div>

          <div class="d-flex mt-1">
            <app-checkbox
              class="subject-checkbox me-1"
              [formGroup]="form"
              name="mechanicInspection"
              label="Inspection by certified mechanic"
            ></app-checkbox>
          </div>
          <div class="d-flex mt-1">
            <app-checkbox
              class="subject-checkbox me-1"
              [formGroup]="form"
              name="titleCheck"
              label="Title check"
            ></app-checkbox>
          </div>
        </div>
      </div>
    }

    <button class="btn-primary w-100 mt-4" (click)="onNextClick()">
      {{ offerSection ? 'Next' : externalListing?._id ? 'Next' : 'Continue' }}
    </button>
  </div>
</app-modal>

<app-modal-duplicate-deal
  #duplicateDealModal
  (onDuplicateCheckValid)="onDuplicateDealCheckValid()"
  [(isOpen)]="showDuplicateDealModal"
  (onClose)="closeDuplicateDeal()"
  (onViewDeal)="closeModal()"
  (onDuplicateResult)="closeModal()"
  (onCreateNewDeal)="createNewDeal($event)"
></app-modal-duplicate-deal>
