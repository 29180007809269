import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService } from 'src/app/core/services';

@AutoUnsubscribe()
@Component({
  selector: 'app-prove-mobile-auth',
  templateUrl: './prove-mobile-auth.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class ProveMobileAuthComponent implements OnInit, OnDestroy {
  @Output() onSuccess = new EventEmitter<{ success: boolean; phoneNumber: string }>();

  constructor(private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {
    this.onboardingService.authenticateMobileAuth().subscribe((data) => {
      this.onSuccess.emit(data);
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
