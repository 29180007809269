import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import {
  GonativeService,
  IdentityInformation,
  NotificationService,
  OnboardingService,
  UsersService,
} from 'src/app/core/services';
import { tap } from 'rxjs/operators';
import { ProveVerificationModel } from 'src/app/models/prove-verification.model';
import { formatSSN } from 'src/app/directive/ssn-mask.directive';
import isEqual from 'lodash/isEqual';
import { isMobileWeb } from 'src/app/shared/utils';
import { PosthogService } from 'src/app/core/services/posthog.service';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';
import moment from 'moment';
import { IUser } from 'src/app/models';

enum VerificationStep {
  MobileAuth = 'mobile-auth',
  VerificationStart = 'verification-start',
  SkipVerificationConfirmation = 'skip-verification-confirmation',
  VerificationSent = 'verification-sent',
  VerificationReview = 'verification-review',
  VerificationEdit = 'verification-edit',
  VerificationFailed = 'verification-failed',
}
@AutoUnsubscribe()
@Component({
  selector: 'app-prove-verification-modal',
  templateUrl: './prove-verification-modal.component.html',
  styleUrls: ['./prove-verification-modal.component.scss'],
})
export class ProveVerificationModal implements OnInit, OnDestroy {
  user: IUser;
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter();
  @Output() onSkipVerification = new EventEmitter();

  onboardingSession: string;
  verificationStep: VerificationStep;

  form: FormGroup;

  identityform: FormGroup;

  isMobileAuthSuccess = false;
  isManualEntry = false;
  hasPassedPosessionFailedPrefill = false;
  isAutofilled = false;
  initialIdentityForm;

  constructor(
    private readonly fb: FormBuilder,
    private readonly onboardingService: OnboardingService,
    private readonly notificationService: NotificationService,
    private readonly usersService: UsersService,
    private readonly gonativeService: GonativeService,
    private readonly posthogService: PosthogService,
  ) {
    this.form = this.fb.group({
      birthDate: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
    });
    this.identityform = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      address: [null, [Validators.required]],
      extendedAddress: [null, []],
      city: [null, [Validators.required]],
      postalCode: ['', [Validators.required]],
      region: [null, [Validators.required]],
      ssn: [null],
      birthDate: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
    });
  }

  modalConfig: ModalConfig = {};
  ngOnInit(): void {
    this.modalConfig = {
      hideHeader: true,
      modalDialogClass: 'prove-verification-modal',
      name: 'Prove verification modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.verificationStep = null;
        this.isManualEntry = false;
        this.hasPassedPosessionFailedPrefill = false;
        this.onboardingService.setVerifyModalVisiblity(false);
        return true;
      },
    };
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      if (this.verificationStep === null) {
        const isMobileAuthEnabled =
          this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.ProveMobileAuth) &&
          (isMobileWeb() || this.gonativeService.detectIfNativeApp());
        this.verificationStep = isMobileAuthEnabled ? VerificationStep.MobileAuth : VerificationStep.VerificationStart;
      }

      this.modalComponent?.open();
    }
  }

  onManualEntryConfirmation() {
    this.verificationStep = VerificationStep.SkipVerificationConfirmation;
  }

  onManualEntry() {
    this.isManualEntry = true;
    this.initIdentityFormFromStartStep();
    this.verificationStep = VerificationStep.VerificationEdit;
  }

  get phoneEnding() {
    return this.form.value?.phoneNumber.slice(-4);
  }

  handlePrefillResponse(response: { success: boolean; data: ProveVerificationModel }) {
    if (response.success) {
      this.initIdentityForm(response.data);
      this.isAutofilled = true;
      this.verificationStep = VerificationStep.VerificationReview;
      if (response.data.mobileAuthFinishResponse) {
        this.onboardingSession = response.data.mobileAuthFinishResponse.session;
      }
      return;
    }
    this.initIdentityFormFromStartStep();
    this.verificationStep = VerificationStep.VerificationEdit;
    this.hasPassedPosessionFailedPrefill = true;
    this.isManualEntry = true;
  }

  onVerificationSent(session: string) {
    this.onboardingSession = session;
    this.verificationStep = VerificationStep.VerificationSent;
  }

  onVerficationClicked() {
    this.onboardingService.getIdentity(this.onboardingSession, 'optedOut').subscribe((response) => {
      this.handlePrefillResponse(response);
    });
  }

  onMobileAuthSuccess(response: { success: boolean; phoneNumber: string }) {
    this.isMobileAuthSuccess = response.success;
    if (response.phoneNumber) {
      this.form.patchValue({
        phoneNumber: response.phoneNumber,
      });
    }
    this.verificationStep = VerificationStep.VerificationStart;
  }

  closeModal() {
    this.modalComponent.close();
  }

  skipVerification() {
    this.modalComponent.close();
    this.onSkipVerification.emit();
  }

  onEditInformation() {
    this.verificationStep = VerificationStep.VerificationEdit;
  }

  onSubmitWithoutChanges() {
    this.onboardingService
      .verifyNoChanges({
        session: this.onboardingSession,
        consent: 'optedIn',
      })
      .pipe(
        tap((response: any) => {
          if (response.verified) {
            this.notificationService.notification('success', 'Account successfully verified');
            this.closeModal();
            this.usersService.getCurrentProfile().subscribe();
          } else {
            this.notificationService.notification(
              'error',
              'We are not able to verify your account. Please check the details and try again',
            );
            this.verificationStep = VerificationStep.VerificationFailed;
          }
        }),
      )
      .subscribe({
        error: () => {
          this.notificationService.notification(
            'error',
            'We are not able to verify your account. Please check the details and try again',
          );
          this.verificationStep = VerificationStep.VerificationFailed;
        },
      });
  }

  onSubmitIdentityForm() {
    if (this.isFormUnChanged()) {
      this.onSubmitWithoutChanges();
      return;
    }
    const {
      email,
      birthDate,
      firstName,
      lastName,
      address,
      extendedAddress,
      city,
      region,
      postalCode,
      ssn,
      phoneNumber,
    } = this.identityform.value;
    const dob = [
      birthDate.year,
      birthDate.month.toString().padStart(2, '0'),
      birthDate.day.toString().padStart(2, '0'),
    ].join('-');
    const payload: IdentityInformation = {
      firstName,
      lastName,
      address: {
        address,
        extendedAddress,
        city,
        region,
        postalCode,
      },
      email,
      birthDate: dob,
      ssn,
      phoneNumber,
    };

    if (this.isManualEntry && !this.hasPassedPosessionFailedPrefill) {
      this.usersService
        .updateProfile({
          email,
          firstName,
          lastName,
          dateOfBirth: moment(dob, 'YYYY-MM-DD').utcOffset(0, true).toDate(),
          phoneNumber: `+1${phoneNumber}`,
          address1: address,
          address2: extendedAddress,
          city,
          state: region,
          zipcode: postalCode,
          country: 'US',
        })
        .pipe(
          tap(() => {
            this.notificationService.notification(
              'success',
              'Profile complete. Please verify your device to complete verification',
            );
            this.skipVerification();
            this.usersService.getCurrentProfile().subscribe();
          }),
        )
        .subscribe({
          error: () => {
            this.notificationService.notification(
              'error',
              'We are not able to update your profile. Please check the details and try again',
            );
          },
        });
      return;
    }
    this.onboardingService
      .verify({
        ...payload,
        ssn: payload.ssn?.replace(/-/g, '') ?? '',
        session: this.onboardingSession,
        consent: this.isManualEntry ? 'optedOut' : 'optedIn',
        autofilled: this.isAutofilled,
      })
      .pipe(
        tap((response: any) => {
          if (response.verified) {
            this.notificationService.notification('success', 'Account successfully verified');
            this.closeModal();
            this.usersService.getCurrentProfile().subscribe();
          } else {
            this.notificationService.notification(
              'error',
              'We are not able to verify your account. Please check the details and try again',
            );
            this.verificationStep = VerificationStep.VerificationFailed;
          }
        }),
      )
      .subscribe({
        error: () => {
          this.notificationService.notification(
            'error',
            'We are not able to verify your account. Please check the details and try again',
          );
        },
      });
  }

  setInitialVerificationModalState(user: IUser) {
    this.user = user;
    const proveResult: ProveVerificationModel = user.proveResult;
    const handleStart = () => {
      if (
        this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.ProveMobileAuth) &&
        (isMobileWeb() || this.gonativeService.detectIfNativeApp())
      ) {
        this.verificationStep = VerificationStep.MobileAuth;
      } else {
        this.verificationStep = VerificationStep.VerificationStart;
      }
      this.onboardingService.setVerifyModalVisiblity(true);
    };
    if (!proveResult) {
      handleStart();
      return;
    }
    const isMobileAuthStarted = typeof proveResult.mobileAuthRedirectUrlResponse?.status === 'number';
    this.isMobileAuthSuccess = Boolean(
      proveResult.mobileAuthFinishResponse?.status === 0 && proveResult.mobileAuthFinishResponse?.phoneNumber,
    );
    const isVerificationLinkClicked = proveResult.linkResultResponse?.linkClicked;
    const isIdentityPrefilled = Boolean(proveResult.identity?.firstName);
    const isVerificationFailed =
      typeof proveResult.verifyResponse?.status === 'number' &&
      (proveResult.verifyResponse?.status !== 0 || proveResult.verifyResponse?.verified === false);
    const isIdentityFailed =
      isVerificationLinkClicked &&
      typeof proveResult.identityResponse?.status === 'number' &&
      proveResult.identityResponse?.status !== 0;
    const isTrustScoreFailed =
      typeof proveResult.trustScoreResponse?.trustScore === 'number' &&
      proveResult.trustScoreResponse?.trustScore < 630;
    const hasManuallyVerified = Boolean(user.userDetails?.dateOfBirth);
    if (isVerificationFailed) {
      // show error
      this.verificationStep = VerificationStep.VerificationFailed;
    } else if (isIdentityFailed || isTrustScoreFailed) {
      if (hasManuallyVerified) {
        this.verificationStep = VerificationStep.VerificationFailed;
      } else {
        // show identity form
        this.verificationStep = VerificationStep.VerificationEdit;
        this.hasPassedPosessionFailedPrefill = true;
        this.isManualEntry = true;
      }
    } else if (isIdentityPrefilled) {
      // show identity form filled
      this.initIdentityForm(proveResult);
      this.isAutofilled = true;
      this.verificationStep = VerificationStep.VerificationReview;
      this.onboardingSession = proveResult.linkResultResponse.session;
    } else if (this.isMobileAuthSuccess) {
      // show verification start with phone number prefilled and hidden on form
      this.initStartWithMobileAuth(proveResult.mobileAuthFinishResponse);
      this.verificationStep = VerificationStep.VerificationStart;
    } else if (isMobileAuthStarted) {
      this.verificationStep = VerificationStep.VerificationStart;
    } else {
      handleStart();
    }
    this.onboardingService.setVerifyModalVisiblity(true);
  }

  onBack() {
    if (this.verificationStep === VerificationStep.VerificationEdit) {
      this.verificationStep = VerificationStep.VerificationReview;
    }
    if (this.verificationStep === VerificationStep.SkipVerificationConfirmation) {
      this.verificationStep = VerificationStep.VerificationStart;
    }
  }

  initStartWithMobileAuth(params: { phoneNumber: string; session: string }) {
    this.onboardingSession = params.session;
    this.form.patchValue({
      phoneNumber: params.phoneNumber,
    });
  }

  initIdentityFormFromStartStep() {
    const { phoneNumber, birthDate } = this.form.value;
    if (phoneNumber) {
      this.identityform.patchValue({
        phoneNumber,
        ...(birthDate && {
          birthDate,
        }),
      });
    }
  }

  initIdentityForm(proveResult: ProveVerificationModel) {
    const identity = proveResult.identity;
    const address = identity.addresses[0];
    const [year, month, day] = identity.dob.split('-');

    this.initialIdentityForm = {
      firstName: identity.firstName,
      lastName: identity.lastName,
      ssn: formatSSN(identity.ssn),
      address: address.address,
      extendedAddress: address.extendedAddress,
      city: address.city,
      region: address.region,
      postalCode: address.postalCode,
      phoneNumber: proveResult.linkResultResponse?.phoneNumber || proveResult.mobileAuthFinishResponse?.phoneNumber,
      birthDate: {
        year: Number(year),
        month: Number(month),
        day: Number(day),
      },
    };
    this.identityform.patchValue({
      firstName: identity.firstName,
      lastName: identity.lastName,
      ssn: formatSSN(identity.ssn),
      address: address.address,
      extendedAddress: address.extendedAddress,
      city: address.city,
      region: address.region,
      postalCode: address.postalCode,
      phoneNumber: proveResult.linkResultResponse?.phoneNumber || proveResult.mobileAuthFinishResponse?.phoneNumber,
      birthDate: {
        year: Number(year),
        month: Number(month),
        day: Number(day),
      },
    });
    if (!identity.ssn) {
      const ssnControl = this.identityform.get('ssn');
      ssnControl.clearValidators(); // Remove all validators from ssn
      ssnControl.updateValueAndValidity(); // Update the control's validity status
    }
  }

  goToStartVerification() {
    this.verificationStep = VerificationStep.VerificationStart;
  }

  isFormUnChanged() {
    if (!this.initialIdentityForm) {
      return false;
    }
    return isEqual(this.initialIdentityForm, this.identityform.value);
  }

  getStepTitle() {
    if (this.verificationStep === VerificationStep.SkipVerificationConfirmation) {
      return 'Get Verified';
    }
    return 'Quick identity verification';
  }

  onRetry() {
    this.onboardingService.retryVerfication().subscribe(() => {
      const user = this.user;
      delete user.proveResult;
      user.verificationRetriesCount = (user.verificationRetriesCount || 0) + 1;
      this.setInitialVerificationModalState(user);
    });
  }

  isRetryable() {
    const RETRIES_ALLOWED = 1;
    if (!this.user) {
      return false;
    }
    const { verificationRetriesCount, proveResult } = this.user;
    const retries = verificationRetriesCount ? Number(verificationRetriesCount) : 0;
    return retries < RETRIES_ALLOWED;
  }
}
