import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { Auth, Hub } from 'aws-amplify';
import { AirbrakeErrorHandler } from 'src/app/airbrake-error-handler';
import { jwtDecode } from 'jwt-decode';
import { NotificationService } from './notification.service';
import { LoadingService } from './loading.service';

interface TokenCredentials {
  idTokenPayload: Record<string, any>;
  accessToken: string;
  idToken: string;
  refreshToken: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly cookieService: CookieService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly loadingService: LoadingService,
    private readonly airbrakeErrorHandler: AirbrakeErrorHandler,
  ) {}

  getUserCredentials(params, provider: string) {
    return this.http.get(`${this.config.apiUrl}/auth/redirect/${provider}`, {
      params,
    });
  }

  handleProviderCallback(params) {
    window.focus();
    if (params.error) {
      this.airbrakeErrorHandler.handleError(params.error);

      this.loadingService.setLoading(false);
      this.notificationService.notification('error', 'Failed to login');
      return;
    }
    this.cookieService.deleteAll('/', this.config.appDomain);
    this.getUserCredentials(params, params.type).subscribe({
      error: () => {
        this.notificationService.notification('error', 'Failed to login');
        this.loadingService.setLoading(false);
      },
      next: ({ cognitoUser, credentials }: any) => {
        this.setStorageAuth({
          idToken: credentials.idToken,
          accessToken: credentials.accessToken,
          refreshToken: credentials.refreshToken,
          idTokenPayload: cognitoUser,
        });
        this.notificationService.notification('success', 'Login successful');
      },
    });
  }

  exchangeCodeForToken(code: string) {
    return this.http.get<TokenCredentials>(`${this.config.apiUrl}/auth/token`, {
      params: {
        code,
      },
    });
  }

  setStorageAuth(data: TokenCredentials) {
    try {
      const { idToken, accessToken, refreshToken, idTokenPayload } = data;
      const accessTokenData = jwtDecode(accessToken);
      const calculateClockDrift = (iatAccessToken: number, iatIdToken: number) => {
        const now = Math.floor(Date.now() / 1000);
        const iat = Math.min(iatAccessToken, iatIdToken);
        return now - iat;
      };
      const clientId = this.config.amplifyWebClientId;
      const usernameKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
      const lastUserName = localStorage.getItem(usernameKey);
      // Clear existing session data if present
      if (lastUserName) {
        localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastUserName}.idToken`);
        localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastUserName}.accessToken`);
        localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastUserName}.refreshToken`);
        localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastUserName}.clockDrift`);
      }
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`,
        idTokenPayload['cognito:username'],
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${clientId}.${idTokenPayload['cognito:username']}.idToken`,
        idToken,
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${clientId}.${idTokenPayload['cognito:username']}.accessToken`,
        accessToken,
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${clientId}.${idTokenPayload['cognito:username']}.refreshToken`,
        refreshToken,
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${clientId}.${idTokenPayload['cognito:username']}.clockDrift`,
        `${calculateClockDrift(accessTokenData.iat, idTokenPayload.iat)}`,
      );
      Hub.dispatch('auth', {
        event: 'signIn',
        data: idTokenPayload,
      });
    } catch (err) {
      console.log('err: ', err);
    }
  }
}
