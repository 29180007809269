<app-modal #modal [modalConfig]="modalConfig">
  @if (!showPaymentElement) {
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img alt="title transfer icon" src="assets/icons/title-transfer-lg-icon.svg" />
      <div class="py-4 transfer-how">Sell a car with a loan without the hassle</div>
      <div class="items">
        <div class="pt-3">
          <img alt="check mark" src="assets/verified.svg" class="pe-2" />Know your exact payoff amount
        </div>
        <div class="pt-3">
          <img alt="check mark" src="assets/verified.svg" class="pe-2" />Connect directly with your lien holder
        </div>
        <div class="pt-3">
          <img alt="check mark" src="assets/verified.svg" class="pe-2" />Payoff funds submitted on your behalf
        </div>
        <div class="pt-3">
          <img alt="check mark" src="assets/verified.svg" class="pe-2" />Status notifications along the way
        </div>
      </div>
      <button class="btn btn-primary modalConfig mt-4" (click)="getService()">Get this service</button>
    </div>
  }
  @if (showPaymentElement) {
    <div>
      <div class="header pb-3">Purchase loan payoff</div>
      @if (selectedVehicle) {
        <div class="vehicle pb-3">
          <app-listing-image [src]="selectedVehicle?.mainImg" [width]="80" [height]="64"></app-listing-image>
          <div class="">
            <p class="">
              {{ selectedVehicle?.RegistrationYear }} {{ selectedVehicle?.CarMake }} {{ selectedVehicle?.CarModel }}
            </p>
            <p class="">{{ selectedVehicle.vinNumber }}</p>
          </div>
        </div>
      }
      <app-payment-element
        [paymentIntentConfig]="paymentIntentConfig"
        [googleAnalyticsEvent]="googleAnalyticsEvent"
        [paymentElementConfig]="paymentElementConfig"
        [isImmediatePayment]="true"
        (onNextEvent)="onPaymentSuccess()"
      ></app-payment-element>
    </div>
  }
</app-modal>
