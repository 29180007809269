import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export function isValidDate(date: NgbDateStruct) {
  return moment(
    `${date.month.toString().padStart(2, '0')}/${date.day.toString().padStart(2, '0')}/${date.year}`,
    'MM/DD/YYYY',
    true,
  ).isValid();
}
