<div class="verification-failed">
  @if (retryable) {
  <div class="d-flex justify-content-center w-100">
    <svg-icon src="/assets/prove-verification-failed.svg"></svg-icon>
  </div>
  <div class="heading-1 mt-3">Identity verification denied</div>
  <div class="description-1 mt-3">
    We couldn't verify if it's really you. Be sure to use your personal cell phone and address. Business phone and
    address will not work
  </div>
  <div class="description-1 mt-3 d-flex align-items-center justify-content-center gap-2">
    <div style="height: 15px; width: 15px">
      <img style="height: 15px; width: 15px" src="/assets/prove-verification-failed.svg" />
    </div>
    You have one more chance to retry
  </div>
  <div class="accept-terms">
    <button (click)="onRetry.emit()" class="btn w-100 btn-outline-primary">Retry verification</button>
  </div>
  } @else {
  <div class="heading-1">Verification Attempt Failed</div>
  <div class="description-1 mt-2 mb-3">We are not able to verify your identity</div>
  <div class="d-flex justify-content-center w-100">
    <svg-icon src="/assets/prove-verification-failed.svg"></svg-icon>
  </div>
  <div class="description-1 mt-3">
    You can still list or purchase cars, but {{ appName }} Pay will be unavailable unless you’re approved.
  </div>
  <div class="accept-terms">
    For questions or concerns you can contact
    <a class="terms" href="mailto: support@privateauto.com">support&#64;privateauto.com</a>
  </div>
  }
</div>
