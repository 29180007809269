export enum DocumentTypes {
  POA = 'poa',
}

export interface ITitleTransferOrder {
  id: string;
  status: TitleTransferStatuses;
  dealId: string;
  packetId?: string;
  payment: string;
  appSource?: string | null;
  organizationId?: string;
  signatureUrl?: string;
  docs: {
    documentType: DocumentTypes;
    document: string;
    signedDate?: string | Date;
    originalUrl?: string;
  }[];
  createdAt: string | Date;
  updatedAt: string | Date;
}

export enum TitleTransferStatuses {
  Pending = 'pending',
  SignatureRequested = 'signatureRequested',
  Success = 'success',
}
