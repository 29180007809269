<div class="beneficial-owners-verification">
  <form [formGroup]="form">
    <h2 style="font: 22px; font-weight: bold" class="mb-4">
      Is there anyone else that is a beneficial owners of {{ businessName }}?
    </h2>

    <div class="d-flex flex-column gap-3 mb-4">
      <app-chip-select-input [options]="chipOptions" formControlName="hasBeneficialOwners">
        <p class="text-muted mb-4">
          To meet regulations, we need to know anyone who owns 25% or more of the organization. This includes investors,
          venture capitalists, managing directors, and individual owners.
        </p>
      </app-chip-select-input>
    </div>

    @if (!form.value.hasBeneficialOwners) {
      <div class="d-flex align-items-start mb-4">
        <app-checkbox
          [formGroup]="form"
          name="certifyInformation"
          [label]="
            'I hereby certify that the information provided for ' +
            businessName +
            ' and Beneficial Owners is complete and correct.'
          "
        >
        </app-checkbox>
      </div>
    }

    <button class="btn btn-primary w-100" (click)="onContinue()" [disabled]="isDisabled()">Next</button>
  </form>
</div>
