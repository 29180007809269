<div class="start-business-verification">
  <svg-icon src="/assets/verification/business-card.svg"></svg-icon>
  @if (isSuccess) {
    <h2 class="subtitle">Thank you. Your business has been submitted for review</h2>
    <p class="text-center">
      Our support staff will notify you once your manual review has been reviewed. If you have any questions, you can
      contact <a class="terms" href="mailto: support@dealnow.com">support&#64;dealnow.com</a>
    </p>
  } @else {
    <svg-icon src="/assets/prove-verification-failed.svg"></svg-icon>
    <h2 class="subtitle">We are not able to verify your business information</h2>
    <p class="text-center">
      The information provided did not match a registered business from our verification provider. If you have any
      questions, you can contact <a class="terms" href="mailto: support@dealnow.com">support&#64;dealnow.com</a>
    </p>
  }
  <button (click)="onContinue.emit()" class="btn btn-primary w-100">Got it</button>
</div>
