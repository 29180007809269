import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { delay, of, switchMap } from 'rxjs';
import { OnboardingService } from 'src/app/core/services';
import { ProveBusinessVerificationModel } from 'src/app/models/prove-verification.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-start-business-verification',
  templateUrl: './start-business-verification.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class StartBusinessVerificationComponent implements OnInit, OnDestroy {
  @Output() onPrefillresponse = new EventEmitter<{ success: boolean; data: ProveBusinessVerificationModel }>();
  constructor(private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {
    of(null)
      .pipe(
        delay(2000), // 2 seconds delay
        switchMap(() => this.onboardingService.prefillBusinessVerification()),
      )
      .subscribe((response) => {
        this.onPrefillresponse.emit(response);
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
